import { PodcastResource } from 'api/resource'

class PodcastEpisodesApi extends PodcastResource {
  constructor() {
    super('podcast-episodes')
  }

  async getStreamingUrl(id) {
    const data = await this.get({ url: `/${this.resource}/${id}/stream-url` })
    return data
  }
}

export default PodcastEpisodesApi
