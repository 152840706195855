import React from 'react'
import { THEME } from 'utils'

export default function SnapchatIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg
      viewBox='0 0 40 40'
      className='svgIcon socialIcon iconSnapchat'
      style={{ width, height, fill: color }}
      aria-labelledby='snapchat-icon'
    >
      <title id='snapchat-icon'>Snapchat Icon</title>
      <path d='M9.38 6.678c-.966 2.277-.584 6.356-.43 9.208-1.042.608-2.381-.459-3.14-.459-.788 0-1.73.546-1.877 1.359-.106.586.143 1.44 1.933 2.183.691.288 2.338.627 2.72 1.572C9.12 21.87 5.832 28 .67 28.894c-.404.07-.692.449-.67.879.09 1.651 3.608 2.299 5.168 2.553.159.227.288 1.185.492 1.915.092.327.328.719.937.719.793 0 2.111-.644 4.406-.244 2.25.394 4.364 3.752 8.424 3.752 3.773 0 6.025-3.373 8.19-3.752 1.254-.219 2.33-.15 3.534.098.83.171 1.573.266 1.81-.591.207-.74.334-1.68.49-1.902 1.545-.253 5.079-.898 5.167-2.55.023-.43-.265-.808-.67-.879-5.075-.88-8.462-6.992-7.913-8.352.38-.944 2.014-1.28 2.72-1.572 1.31-.544 1.966-1.213 1.951-1.987-.018-.991-1.15-1.582-1.984-1.582-.848 0-2.066 1.057-3.053.484.155-2.876.535-6.936-.43-9.211C27.415 2.364 23.352.186 19.29.186c-4.036 0-8.07 2.148-9.91 6.492z' />
    </svg>
  )
}
