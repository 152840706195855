import React, { useState } from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import { THEME } from 'utils'
import { siteHeaderEvents } from 'lib/ga'
import { useInterval, useStore } from 'hooks'
import StoriesStore from 'stores/stories'
import ConfigStore from 'stores/config'
import { track } from 'lib/whoami'

import TimeDependent from 'components/TimeDependent'

const handleAlertBannerCDPEvent = (item) => {
  if (!item) return
  track('ContentClicked', {
    content: {
      src: item?.href,
      type: 'Link',
      componentName: 'AlertBanner'
    }
  })
}

export const AlertBanner = observer(({ items }) => {
  const [slide, setSlide] = useState(0)
  useInterval(() => {
    setSlide((prevSlide) => (prevSlide + 1) % items.length)
  }, 8000)

  return (
    <>
      <div className='alertBanners'>
        {items.map(({ type, data: item }, index) => (
          <div
            className={`alertBanner ${index === slide ? 'alertBanner--visible' : 'alertBanner--hidden'}`}
            key={index}
          >
            {type === 'live' ? (
              <>
                <span>
                  <strong style={{ marginRight: '8px' }}>Live Event</strong>
                  {item.title}
                </span>
                <Link href='/live/[id]/[slug]' as={`/live/${item.id}/${item.slug}`} legacyBehavior>
                  <a
                    onClick={(e) => {
                      siteHeaderEvents.navLink(`Alert Banner - ${item.title}`)
                      handleAlertBannerCDPEvent(e.currentTarget)
                    }}
                  >
                    <TimeDependent
                      date={item.post_type_meta.live.datetime}
                      renderBefore={() => {
                        if (item.post_type_meta.live.is_live) {
                          return 'Watch Now'
                        } else {
                          return 'Starting Soon'
                        }
                      }}
                      renderAfter={() => 'Watch Now'}
                    />
                  </a>
                </Link>
              </>
            ) : (
              <>
                {item.text}
                <a
                  href={item.cta_url}
                  target={item.external ? '_blank' : ''}
                  rel={item.external ? 'noopener noreferrer' : ''}
                  onClick={(e) => {
                    siteHeaderEvents.navLink(`Alert Banner - ${item.text}`, item.external)
                    handleAlertBannerCDPEvent(e.currentTarget)
                  }}
                >
                  {item.cta_label}
                </a>
              </>
            )}
          </div>
        ))}
      </div>
      <style jsx>{`
        .alertBanners {
          background: ${THEME.COLORS.PRIMARY};
        }
        .alertBanner {
          background: ${THEME.COLORS.PRIMARY};
          font-size: 14px;
          color: #fff;
          text-align: center;
          transition: opacity 1s ease;
          height: 0;
          padding: 0;
          visibility: hidden;
          opacity: 0;
        }
        .alertBanner--visible {
          opacity: 1;
          visibility: visible;
          height: auto;
          padding: 12px 8px;
        }
        .alertBanner a {
          color: #fff;
          font-weight: 600;
          text-decoration: underline;
          white-space: nowrap;
          margin-left: 8px;
        }
      `}</style>
    </>
  )
})

const AlertBannerContainer = observer(() => {
  const storiesStore = useStore(StoriesStore)
  const configStore = useStore(ConfigStore)

  const items = storiesStore.liveItems.map((item) => ({
    type: 'live',
    data: item
  }))

  const alert = configStore.current?.alert_banner
  const hasAlert = Boolean(alert && Object.keys(alert).length)
  if (alert && hasAlert) {
    items.push({ type: 'alert', data: alert })
  }

  if (items.length === 0) {
    return null
  }

  return <AlertBanner items={items} />
})

export default AlertBannerContainer
