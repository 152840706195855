import { applyFastlyParams } from './image'

export default function imageLoader({ src, width, quality = '85,75' }) {
  const url = applyFastlyParams(src, {
    width: width.toString(),
    auto: 'webp',
    quality: quality.toString(),
    format: 'pjpg'
  })
  return url
}
