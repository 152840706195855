import classNames from 'classnames'
import { useDetectAdBlock } from 'hooks/detect-adblock'
import { useRouteChangeComplete } from 'hooks/route-change'
import React, { useEffect } from 'react'
import useResizeObserver from 'use-resize-observer'
import { isServer } from 'utils'
import { AdSkeleton } from 'components/AdSkeleton'
import { withHTL } from 'hooks/hashtag-labs'

function useSlotDimensionsClassName(slotName) {
  switch (slotName) {
    case 'sidebar-top':
    case 'sidebar-middle':
    case 'sidebar-bottom':
    case 'in-article':
      return 'max-w-[300px] min-h-[250px]'
    case 'leaderboard-top':
      return 'max-w-[320px] min-h-[50px] md:max-w-[728px] md:min-h-[90px]'
    case 'leaderboard-in-feed':
      return 'max-w-[300px] min-h-[50px] md:max-w-[728px] md:min-h-[90px]'
    default:
      return 'max-w-[320px] min-h-[50px]'
  }
}

function useSkeletonProps(slotName) {
  switch (slotName) {
    case 'sidebar-top':
    case 'sidebar-middle':
    case 'sidebar-bottom':
    case 'leaderboard-top':
      return {
        background: '#fff',
        image: true,
        text: true
      }
    case 'in-article':
    case 'leaderboard-in-feed':
      return {
        background: '#f1f1f1',
        image: true,
        text: true
      }
    default:
      return {
        background: '#f1f1f1',
        image: false,
        text: false
      }
  }
}

const HashtagLabsAd = ({ id, adSettings = { slot: 'content' }, setAdRendered = () => {} }) => {
  const { ref: adRef, width: adWidth } = useResizeObserver()
  const adBlockDetected = useDetectAdBlock()
  const slotClassName = useSlotDimensionsClassName(id)
  const skeletonProps = useSkeletonProps(id)
  const adSlotClassName = `htlad-${id}`

  useRouteChangeComplete(() => {
    if (isServer) return

    setTimeout(() => {
      withHTL(() => {
        window.htlbid.reloadSlot(id)
      })
    }, 0)
  })

  useEffect(() => {
    setAdRendered(adWidth > 2)
  }, [adWidth])

  return (
    <div className='relative w-full flex items-center justify-center'>
      <div className={classNames('w-full h-full z-10 relative', slotClassName)}>
        <div
          ref={adRef}
          className={classNames('htlAd', adSlotClassName, { 'fixed left-0 z-50': id === 'fixed-bottom' })}
          data-unit={`/Barstool-Sports-Web/${adSettings?.slot}`}
          data-targeting={adSettings?.['data-targeting']}
        />
        {!adBlockDetected && <AdSkeleton slotName={id} {...skeletonProps} />}
      </div>
    </div>
  )
}

export default HashtagLabsAd
