import CryptoJS from 'crypto-js'

export function isBarstoolSportsEmail(email: string): boolean {
  if (!email) return false
  return email.includes('@barstoolsports.com')
}

export function hashBarstoolUserId(userId: string): string {
  return CryptoJS.MD5(userId).toString()
}
