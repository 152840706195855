import { UnionResource } from 'api/resource'

class StoriesApi extends UnionResource {
  constructor() {
    super('stories')
  }
  async find(params) {
    const result = await this.get({ url: '/stories/latest', params })
    return result
  }
  async findTrending(params) {
    const result = await this.get({ url: '/stories/trending', params })
    return result
  }
  async findFavorites(url, params) {
    const result = await this.get({ url, params })
    return result
  }
  async findPlaylists(params) {
    const result = await this.get({ url: '/playlists', params })
    return result
  }
  async findPlaylistById(id) {
    const result = await this.get({ url: `/playlists/${id}` })
    return result
  }
  async findVideoSource(id) {
    const result = await this.get({ url: `/stories/${id}/video-source`, params: { parse_manifest: true } })
    return result
  }
  async findLive() {
    const result = await this.get({ url: '/stories/live', params: { sort: 'viewers' } })
    return result
  }
  async findCategories() {
    const result = await this.get({ url: '/stories/categories' })
    return result
  }
  async findViewerCount(id) {
    const result = await this.get({ url: `/stories/id/${id}/presence` })
    return result.count || 1
  }
  async postViewerCount(id, data) {
    const result = await this.post({ url: `/stories/id/${id}/presence`, data })
    return result.count || 1
  }
  async findByTag(tag, params) {
    const result = await this.get({ url: `/stories/tag/${tag}`, params })
    return result
  }
  async random(params) {
    const result = await this.get({ url: `/stories/random`, params })
    return result
  }
  async findPreviewById(id) {
    const result = await this.get({ url: `/stories/id/${id}/preview` })
    return result
  }
  async findBySlug(slug) {
    const result = await this.get({ url: `/stories/slug/${slug}` })
    return result
  }
  async search({ query, author, type, page, limit }) {
    console.log('API SEARCH', { query, author, type, page, limit })
    const result = await this.get({ url: `/stories/search`, params: { query, author, type, page, limit } })
    return result
  }
}

export default StoriesApi
