import React from 'react'
import NotFoundPage from './404'
import ServerErrorPage from './_error'

export const ErrorProvider = ({ error, children }) => {
  if (error) {
    return error.status === 404 ? (
      <NotFoundPage />
    ) : (
      <ServerErrorPage statusCode={error.status} errorMessage={error.message} />
    )
  } else {
    return children
  }
}
