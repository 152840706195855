import { UnionResource } from 'api/resource'

class AuthApi extends UnionResource {
  constructor() {
    super('auth')
  }
  async emailCheck(email) {
    const result = await this.post({ url: `/${this.resource}/email-check`, data: { email, source: 'web' } })
    return result
  }
  async signup(data) {
    const result = await this.post({ url: `/${this.resource}/signup`, data })
    return result
  }
  async login(data) {
    const result = await this.post({ url: `/${this.resource}/login`, data })
    return result
  }

  async forgotPassword({ email }) {
    const result = await this.post({
      url: `/${this.resource}/forgot-password`,
      data: { email }
    })
    return result
  }

  async resetPassword({ token, password }) {
    const result = await this.post({
      url: `/${this.resource}/reset-password`,
      data: { password },
      headers: { Authorization: `Bearer ${token}` }
    })
    return result
  }

  async spotimAuthorize(token, codeA) {
    const result = await this.post({
      url: `/${this.resource}/spotim`,
      data: { code_a: codeA },
      headers: { Authorization: `Bearer ${token}` }
    })
    return result
  }
}

export default AuthApi
