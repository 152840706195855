const SHOWS = {
  '21st & Prime': {
    backgroundColor: '#8C1D1D',
    logo: 'https://chumley.barstoolsports.com/union/2020/10/07/0-8.26a4e62e.jpeg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: '21st & Prime',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/21_PRIME.ffcc9238.png',
    about:
      'Deion Sanders, alongside his co-host, Jamie Dukes, will invite some of the most polarizing and magnetic personalities, athletes, politicians, entertainers, and everyday folks to discuss the hardest hitting issues and the biggest headlines in the world today. They explore provocative opinions and challenging questions across politics, family, music, and fashion with heated debate and hilarious banter—nothing is off limits. Welcome to 21st & Prime.',
    talent: ['Deion Sanders', 'Jamie Dukes'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Commerce & Licensing'
    ]
  },
  '51 Strokes': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2020/10/06/0-3.878dfc0b.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: '51 Strokes',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/51_STROKES.b06ed10c.png',
    about:
      "51 Strokes is a masterclass in following your dreams. It's a teaching tape on pursuing your passion. Caleb Pressley is a terrible golfer who one day wants to go pro and he’s about to manifest that destiny. Listen in as he consults the greatest golf minds in order to fill his brain with facts about the game he has haphazardly dedicated the rest of his life to. He might be a 51 handicap golfer now, but when it’s all said and done he will be a Masters Champion and a national hero.",
    talent: ['Caleb'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Commerce & Licensing'
    ]
  },
  'A New Untold Story': {
    backgroundColor: '#6B4224',
    logo: 'https://chumley.barstoolsports.com/union/2020/11/16/BLOG-SHOW-PAGE-300x225ANUS.ccfc3551.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'A New Untold Story',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/anus-1.e7a3a017.jpg',
    about:
      "A New Untold Story is a coming of age podcast about nothing in particular, hosted by Nick Turani and KB. The stories are not topical or timely, but the humor is. It's going to be a satirical look at podcasting, and the way news and media is delivered.",
    talent: ['Nick Turani', 'KB'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Custom Content']
  },
  'Answer the Internet': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2019/10/29/da4e570b37cd.png',
    name: 'Answer the Internet',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/ATI.c810c8ea.png',
    about:
      'Over the past 7 years, KFC Radio has collected the internet’s weirdest and dumbest questions. On Answer the Internet, celebrities visiting Barstool are put in the hot seat, with no choice but to answer a range of would-you-rather type questions.',
    talent: ['KFC', 'Feitelberg'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Segment', 'Branded Social', 'Commerce & Licensing']
  },
  'Back Again with Troopz': {
    backgroundColor: '#1D1D1D',
    logo: 'https://chumley.barstoolsports.com/union/2020/09/01/0-2.67ecf7f9.jpeg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Back Again with Troopz',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/TROOPZ.28e0a8e1.png',
    about:
      'Welcome to your dose of soccer (or on the other side of The Pond, “football”) news. Barstool’s Soccer brand, Back Again with Troopz, will not only have in-depth Arsenal match previews and reviews, but will also cover other major Premier League and Championship League games with the biggest news from the soccer world. Get ready for Troopz to make “football” big in the U.S.',
    talent: ['Troopz', 'Zah'],
    showType: 'Podcast Show / Livestreams',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential'
    ]
  },
  'Barstool Abroad': {
    name: 'Barstool Abroad',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/ABROAD.be158a10.png',
    about:
      'Donnie (“The Wonton Don”), Barstool’s Foreign Correspondent, explores the world’s most interesting cultures. Past explorations include Zimbabwe, with upcoming trips planned to Tokyo for the Olympics and India for the Cricket World Cup.',
    talent: ['The Wonton Don'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Segment', 'Branded Social', 'Custom Content']
  },
  'Barstool Bench Mob': {
    backgroundColor: '#0F1F4F',
    logo: 'https://chumley.barstoolsports.com/union/2020/11/11/BLOG-SHOW-PAGE-300x225BENCH-MOB.48b4c563.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Barstool Bench Mob',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/BENCH_MOB.169f2d72.png?width=2000&height=2000',
    about:
      'The college basketball podcast you’ve been waiting for!  On “Barstool Bench Mob,” Marty Mush, Rico Bosco, and Jake Marsh bring their expertise to the airwaves of Barstool Sports. When two hardcore gamblers and college hoops lovers join forces with a Big J, nobody knows the exact direction this show will take. Interviews with guests ranging from coaches, ex-players, broadcasters, and everyone else in between will make this a one-of-a-kind show leading up to the Big Dance in March.',
    talent: ['Marty Mush', 'Rico Bosco', 'Jake Marsh'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Custom Content']
  },
  'Barstool College Football Show': {
    backgroundColor: '#141E30',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/08/23/1-1.png',
    name: 'Barstool College Football Show',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/CFBS.ce30b3ea.png',
    about:
      'The Barstool College Football Show is everything that ESPN’s College Game day is not - unfiltered, and extremely biased. Each Saturday during the season, hosts Kayce Smith, Dave Portnoy, Dan Katz, and Brandon Walker not only deliver game previews, gambling picks and top 10 rankings, they also ride the rollercoaster of emotions that is college football.',
    talent: ['Big Cat', 'Deion Sanders', 'Dave Portnoy', 'Kayce Smith'],
    showType: ['Studio Show'],
    integration: ['Studio Sponsorship', 'Branded Segment', 'Branded Social', 'Experiential']
  },
  'Barstool Gone Viral': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/07/11/viral-01.png',
    name: 'Barstool Gone Viral',
    img: 'https://chumley.barstoolsports.com/union/2021/08/20/barstoolgoneviral2.db3c6c09.jpg',
    about: 'Barstool personalities giving their take on funny moments from the world wide web',
    showType: 'Snapchat Discover Show',
    talent: ['All Barstool Talent'],
    integration: ['Brand Moment', 'Segment Integration', 'Takeover']
  },
  'Barstool Outdoors': {
    backgroundColor: '#2e2e2e',
    logo: 'https://chumley.barstoolsports.com/union/2021/04/04/BLOG_300x225_OUTDOORS.08e7e5ab.jpg?crop=300%3A225%2Csmart',
    name: 'Barstool Outdoors',
    img: 'https://chumley.barstoolsports.com/union/2021/08/17/syd-wells-.jpg.6fce5b53.webp?width=500&height=500',
    about:
      'Long-time thrill seeker and adventurer Sydnie Wells is the new face of Barstool Outdoors. Through Barstool Outdoors, Sydnie will give viewers a first-hand look at the wildest experiences she can find. From bowfishing in Florida to chasing huge, mature whitetails in Illinois, there’s nothing Sydnie won’t do.',
    talent: ['Sydnie Wells'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Social']
  },
  'Barstool Plays': {
    name: 'Barstool Plays',
    img: 'https://chumley.barstoolsports.com/union/2021/08/19/barstoolplays.be84e035.jpeg?crop=0.76%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    about:
      'Similar to Lowering the Bar, but with host MRags, Barstool Play will challenge Barstool personalities to take on a new video game challenge each week. The bloggers will compete in retro games like Super Mario and the hottest new games like Fall Guys.',
    talent: ['MRags', 'Various Barstool Talent'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Segment', 'Branded Social']
  },
  'Barstool Shopping Network': {
    logo: 'https://chumley.barstoolsports.com/union/2021/05/27/0-3.15b3307d.png',
    backgroundColor: '#3e71b1',
    name: 'Barstool Shopping Network',
    img: 'https://chumley.barstoolsports.com/union/2021/08/17/BSN.8a435e48.png?width=500&height=500',
    about:
      '​​Introducing the Barstool Shopping Network with Nick & KB. The BSN. It does not stand for Big Sexy Nick or Big Strong Noogies. We have a plethora of amazing products to shove down your throats. So go max out your credit card or steal someone else’s and take advantage of all the BSN has to offer!',
    talent: ['Nick Turani', 'KB'],
    showType: 'Video Show',
    integration: ['Branded Social', 'Presenting Sponsorship', 'Segment Sponsorship']
  },
  'Barstool Sports Advisors': {
    backgroundColor: '#343434',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2018/09/07/bssa-logo.png',
    name: 'Barstool Sports Advisors',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/BSA.6fa24856.png',
    about:
      'Barstool has a larger digital audience and social following than any company involved in the sports betting space. And Barstool Sports Advisors is your one-stop shop for all sports betting hits and misses. With a weekly long-form episode, and shorter mid-week episodes during Football season, El Pres and Big Cat put in their bets for the week this outrageous, 90s-style gambling show.',
    talent: ['Dave Portnoy', 'Big Cat'],
    showType: ['Studio Show'],
    integration: ['Studio Sponsorship', 'Branded Segment', 'Branded Social']
  },
  'Because We Got High': {
    backgroundColor: '#642EE8',
    logo: 'https://chumley.barstoolsports.com/union/2021/01/05/BLOG-SHOW-PAGE-300x225BWGH.ede334df.jpg?crop=300%3A225%2Csmart',
    name: 'Because We Got High',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/BECAUSE-WE-GOT-HIGH-1.39892425.jpg',
    about:
      'Do you ever sit around with your friends and catch yourself talking about the meaning of life? Ria, Kelly, and Brianna are diving deep into every topic that surrounds daily life, from A to Z. They are doing research, expanding minds, and breaking down life, all while having tons of fun and laughing along the way. It’s not about anything—it’s about everything.',
    talent: ['Ria', 'Kelly Keegs', 'Brianna Chickenfry'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Commerce & Licensing']
  },
  BFFs: {
    backgroundColor: '#8b8b8b',
    logo: 'https://chumley.barstoolsports.com/union/2021/08/16/0.500722c4.jpg?crop=300%3A225%2Csmart',
    name: 'BFFs',
    img: 'https://chumley.barstoolsports.com/union/2021/08/17/maxresdefault.cfbe01b3.jpg?width=500&height=500',
    about:
      'Dave Portnoy and TikTok stars Josh Richards and Brianna Chickenfry team up to bring you BFFs. Dropping weekly, the three talk about everything from trends on TikTok, business ventures in LA to running a media empire in NYC. Join the trio as they interview some of social media’s hottest stars and see generations collide in the most entertaining of ways.',
    talent: ['Dave Portnoy', 'Josh Richards', 'Brianna Chickenfry'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Commerce & Licensing'
    ]
  },
  'Boozin Burgers': {
    backgroundColor: '#343434',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2018/08/09/BoozinBurgers300x225.jpg',
    name: 'Boozin Burgers',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/BOOZIN_BURGERS.067ae61f.png',
    about:
      'Glen Medoro tests every signature burger in NYC, giving his take on the atmosphere in each restaurant and rating each burger on the menu.',
    talent: ['Glenny Balls'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Social']
  },
  "Bussin' with the Boys": {
    backgroundColor: '#701B03',
    logo: 'https://chumley.barstoolsports.com/union/2020/01/31/845e2b64.png?crop=0.99%2C0.98%2Cx0.00%2Cy0.01',
    name: "Bussin' with the Boys",
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/BWTB.3227b097.png',
    about:
      'Bussin’ with the Boys is the only podcast hosted by active NFL players. Always high energy and never censored, Taylor Lewan and Will Compton bring you into their world with outrageous and exclusive stories from the perspective of current NFL players.',
    talent: ['Will Compton', 'Taylor Lewan'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Call Him Papi': {
    logo: 'https://chumley.barstoolsports.com/union/2021/07/16/desktop-logo.ea39fb36.JPG?crop=0.99%2C0.98%2Cx0.00%2Cy0.00%2Csafe',
    backgroundColor: '#000000',
    name: 'Call Him Papi',
    img: 'https://chumley.barstoolsports.com/union/2021/04/28/callhimpapi-2.97e11afa.png?width=2000&height=2000',
    about:
      'One of baseball’s all-time greats, David Ortiz, sits down with Barstool’s biggest Boston fan and host of Starting 9, Jared Carrabis, to chat with some of the world’s biggest legends. Each conversation will transcend the sports and entertainment landscape and bring fans a whole new side to some of their stars. From money to fame and fortune, ‘Call Him Papi’ gives fans an intimate and humorous conversation with high profile guests that they won’t find anywhere else.',
    talent: ['Jared Carrabis', 'David Ortiz'],
    showType: 'Podcast Show',
    integration: ['Presenting Sponsorship', 'Branded Segment', 'Branded Social', 'Custom Content']
  },
  'Cause I Said So': {
    backgroundColor: '#f0a9e8',
    logo: 'https://chumley.barstoolsports.com/union/2021/11/01/0.f93f57c5.png',
    name: "'Cause I Said So",
    img: 'https://chumley.barstoolsports.com/union/2021/11/05/IMG_9625.1016f1e0.jpg',
    about:
      "Cause I Said So’ is the story of Barstool's first mother-daughter duo, Alex and Kim. From generational differences to mother-daughter conflicts, this show will make you laugh, cry, and appreciate the priceless relationship that a mother and daughter share.",
    talent: ['Alex Bennett', 'Kim Bennett'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Chicks in the Office': {
    backgroundColor: '#C687DA',
    logo: 'https://chumley.barstoolsports.com/union/2020/10/14/CITO-DELIVERABLES_BLOG-LOGO.5e2b7637.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Chicks in the Office',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/CITO.289aeb51.png',
    about:
      'On Chicks in the Office, Ria and Fran discuss the biggest celebrity stories in pop culture, analyze celebrity relationships and break-ups, review TV show recaps, and answer fan submitted questions with special celebrity guest interviews. Ranked a Top 5 E! News Pop Podcast, Chicks in the Office keeps you informed on the hard hitting pop culture news you didn’t know you needed.',
    talent: ['Ria', 'Fran'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Cutting Stems': {
    name: 'Cutting Stems',
    img: 'https://chumley.barstoolsports.com/union/2021/08/17/Week3YT.9c7f2fb5.png.de898cf4.webp?width=500&height=500',
    about:
      'After every Bachelor/ette show, Ria, Frank, Trent, and Kelly go live to recap the episode with their own theories of what will happen next. Join them as they give their hottest takes on the contestants and theories of what to expect the following week to Bachelor nation.',
    talent: ['Ria', 'Fran', 'Kelly Keegs', 'Trent'],
    showType: 'Live Stream Studio Show',
    integration: ['Studio Sponsorship', 'Product Integration', 'Branded Social']
  },
  'Daily Nine': {
    backgroundColor: '#343434',
    name: 'Daily Nine',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/DAILY_NINE.20cbbd2b.png',
    about:
      'Riggs makes it a point to get out there and play nine on a day-to-day basis. Be part of Riggs’ routine as he continues to work on his golf game shot after shot.',
    talent: ['Riggs'],
    showType: 'Social Series',
    integration: ['Show Sponsorship']
  },
  'Dave Portnoy Show with Eddie & Co.': {
    backgroundColor: '#050A19',
    logo: 'https://chumley.barstoolsports.com/union/2020/09/22/DPSEDCo_600x460.7382fdae.jpg?crop=0.99%2C0.98%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Dave Portnoy Show with Eddie & Co.',
    img: 'https://chumley.barstoolsports.com/union/2021/02/08/dps_square.5996d8e8.jpg',
    about:
      'Barstool Sports founder Dave Portnoy is back in the podcasting game. Dave and Eddie, from Barstool Chicago, discuss the inner workings of Barstool, reflecting on office drama and Barstool operations.',
    talent: ['Dave Portnoy', 'Eddie'],
    showType: 'Podcast Show',
    integration: ['Show Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Davey Day Trader': {
    backgroundColor: '#9C9C9C',
    logo: 'https://chumley.barstoolsports.com/union/2020/04/09/b239da0a.jpeg?crop=0.90%2C0.89%2Cx0.04%2Cy0.05%2Csafe',
    name: 'Davey Day Trader',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/DDTG.0ef236da.png',
    about:
      'Dave’s new profession is day trading, and you can catch all the action on Davey Day Trader. When the market is low, Dave is jumping to buy. Watch as Dave plays the stock market live from his home.',
    talent: ['Dave Portnoy'],
    showType: 'Livestreams',
    integration: ['Show Sponsorship', 'Host Read', 'Branded Social']
  },
  'Die Trying': {
    logo: 'https://chumley.barstoolsports.com/union/2021/06/10/0.54b238b8.jpeg?crop=300%3A225%2Csmart',
    backgroundColor: '#f5a623',
    name: 'Die Trying',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/DIE_TRYING.e96e1970.png',
    about:
      'Chef Donny shadows those in the world’s most dangerous jobs—from skyscraper window washers to shark tank cleaners—and he is always accompanied by hilarious commentary from Rone.',
    talent: ['Chef Donny', 'Rone'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Segment', 'Branded Social']
  },
  'Dog Walk Snake Draft': {
    name: 'Dog Walk Snake Draft',
    img: 'https://chumley.barstoolsports.com/union/2021/08/19/snake-draft.02c7e642.jpeg?width=500&height=500',
    about:
      'This is the special Monday edition of The Dog Walk podcast where Eddie, Chief, Carl, and White Sox Dave are joined by a guest to “snake draft” a specific theme or topic. The winner is decided by a (often controversial) Twitter poll after the audience has listened to the episode.',
    talent: ['Carl', 'Eddie', 'Chief', 'White Sox Dave', 'Various Barstool Talent'],
    showType: 'Podcast Show',
    integration: ['Show Sponsorship', 'Branded Segment', 'Host Social', 'Branded Social']
  },
  'Everybody is Awful': {
    backgroundColor: '#141E30',
    logo: 'https://chumley.barstoolsports.com/union/2020/01/15/ef860bc2.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00',
    name: 'Everybody is Awful',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/EIA.6935e899.png',
    about:
      'Comedian Jim Florentine is no stranger to making people laugh. The co-host of ‘That Metal Show’ and Special Ed from Comedy Central’s ‘Crank Yankers’ has made his mark on the comedy scene not long after his ‘Terrorizing Telemarketers’ prank CD exploded shortly after it’s release. Jim’s hilarious, unapologetic deadpan style has him as one of the top touring stand-up comedians in the country.',
    talent: ['Jim Florentine'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read']
  },
  'Bants Sports Ooz': {
    backgroundColor: '#050608',
    logo: 'https://chumley.barstoolsports.com/union/2021/10/26/0.922386fb.png?crop=600%3A450%2Csmart',
    name: 'Bants Sports Ooz',
    img: '#',
    about:
      'BANTS SPORTS OOZ (a play on Sky Sports News) is your one-stop shop for the world famous, YouTube sensation, half-man half-amazing, the one and only Expressions. Alongside a wide range of personalities ranging from Manchester United fans to Arsenal supporters, the voice of Tottenham Hotspur (Expressions) takes you through the weekend action in the Premier League. They may not have trophies, but he’s got just about.',
    talent: ['Expressionz Oozing'],
    showType: 'Podcast Show',
    integration: ['Show Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Fantasy Football Factory': {
    backgroundColor: '#050608',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/08/23/11.jpeg',
    name: 'Fantasy Football Factory',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/FFF.4a62c0fd.png',
    about:
      "Fantasy Football Factory is a weekly podcast featuring Steven Cheah and Ben Mintz, and a rotating cast of Barstool bloggers. Each week, the hosts review the past week’s fantasy results and preview the upcoming week's action, breaking down matchups, discussing analytics, providing stats, and analyzing the daily fantasy sports (DFS) picks.",
    talent: ['Steven Cheah', 'Ben Mintz'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential'
    ]
  },
  'Feed Willie Series': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2020/11/06/unnamed-4.3ac54374.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Feed Willie Series',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/FEED_WILLIE.58596245.png',
    about:
      'Chef Donny and Willie will co-host Barstool’s first, ever Cooking Competition show from Willie’s own backyard. Inspired by Top Chef, there will be one challenge per episode with a panel of expert judges voting for the best dish. Barstool personalities will face off against one another other, with a little help from professional chefs in their ears. Stay on the lookout for special judge appearances and battles between PFT and Large, Caleb and Rone, and more. Let it burn.',
    talent: ['Chef Donny', 'Willie Colon'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Segment', 'Branded Social']
  },
  'Fixing Frankie': {
    backgroundColor: '#343434',
    name: 'Fixing Frankie',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/FIXING_FRANKIE.150eeb2f.png',
    about:
      'Frankie hasn’t quite nailed his game yet. Barstool is looking to take Stoolies for the ride as he works on his technique with special guests guiding him along the way.',
    talent: ['Frankie'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Host Read', 'Branded Social']
  },
  'Fore Man Scramble': {
    backgroundColor: '#343434',
    name: 'Fore Man Scramble',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/FORE_MAN.cf3e1a2b.png',
    about:
      'Watch live as the Fore Play crew take on professional golfers, celebrities and friends in a four-vs-one scramble at some of the most famous golf courses in the country.',
    talent: ['Riggs', 'Trent', 'Frankie'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Host Read', 'Branded Social']
  },
  'Fore Play': {
    backgroundColor: '#0A5C34',
    logo: 'https://media.barstoolsports.com/brands/fore-play/logo.v1.jpg',
    name: 'Fore Play',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/FORE_PLAY.a90d4c5e.png',
    about:
      "Fore Play is a weekly podcast by common golfers, for common golfers. Trent, Riggs, Frankie, Lurch, and their wide variety of guests openly discuss major tournaments, pro gossip, and just about everything golf—just like normal folks sitting at a bar watching coverage, venting about the game's difficulties, and weighing in on pro gossip. Your classic golf addicts, the Fore Play crew brings a young, unique voice to the rapidly-evolving game.",
    talent: ['Riggs', 'Trent', 'Frankie'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Friday Night Pints': {
    backgroundColor: '#343434',
    logo: 'https://chumley.barstoolsports.com/union/2021/07/23/0.649a54df.jpg?crop=300%3A225%2Csmart',
    name: 'Friday Night Pints',
    img: 'https://chumley.barstoolsports.com/union/2021/08/18/fnp.4de14074.jpg?canvas=550,550&bg-color=000000',
    about:
      'Kevin and Feitelberg recap the week at Barstool with a rotating cast of talent at the Barstool Bar every friday night.',
    talent: ['KFC', 'Feitelberg'],
    showType: 'Live Stream Studio Show',
    integration: ['Presenting Sponsorship', 'Product Integration']
  },
  'Going Deep': {
    backgroundColor: '#4B732A',
    logo: 'https://chumley.barstoolsports.com/union/2020/02/19/2607bb55.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00',
    name: 'Going Deep',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/GOING_DEEP.1d37abf0.png',
    about:
      'Going Deep, hosted by Super Bowl Champion Willie Colon and Barstool’s Senior NFL Analyst Steven Cheah, discusses stories from the NFL on and off the field. Going Deep interviews past and present NFL players to get to know the men underneath the helmets, delivering the real stories that you won’t see on TV.',
    talent: ['Willie Colon', 'Steven Cheah'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Gametime Livestreams': {
    backgroundColor: '#0c1623',
    logo: 'https://chumley.barstoolsports.com/union/2021/07/30/0.e2458ffc.jpg?crop=300%3A225%2Csmart',
    name: 'Gametime Livestreams',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/HOOLIGANZ.3199c98d.png',
    about:
      'Barstool’s first ever coveted gaming team, the Barstool Hooliganz, was the #1 stream globally during its first month on Twitch. They stream 8-12 hours live each day playing a mix of games, from Call of Duty to Mario Kart. The Team Hooliganz streamers will take their talents to various tournaments throughout the year. What they lack in game playing skill, they make up for in entertaining and viral content.',
    talent: ['Smitty', 'MRags'],
    showType: 'Livestreams',
    integration: ['Stream Sponsorship', 'Branded Social', 'Custom Content', 'Experiential', 'Commerce & Licensing']
  },
  'KFC Radio': {
    backgroundColor: '#BCCECE',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2018/06/21/KFC-Desktop.jpg',
    name: 'KFC Radio',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/KFC_RADIO.e3836519.png',
    about:
      'Hosted by KFC and Feitelberg, KFC Radio is the quintessential bar conversation brought to listeners in podcast form. The original Barstool podcast that launched our entire network, KFC Radio covers widely ranging topics, such as current pop culture stories, adolescent / “dadolescent” segments, and depressing tales of Feitelberg’s childhood. Stoolies contribute voicemails with embarrassing personal stories and bizarre hypothetical questions, keeping the conversation with the ‘’Beta Boys’’ and their celebrity guests lively and entertaining.',
    talent: ['KFC', 'Feitelberg'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Lights, Camera, Barstool': {
    backgroundColor: '#603912',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2018/05/16/LCB-Desktop.jpg',
    name: 'Lights, Camera, Barstool',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/LCB.74456db9.png',
    about:
      'A movie podcast for the common man by the “Big Time Movie Boys” Jeff Lowe, KenJac, and Trillballins. On Lights, Camera, Barstool, the guys review movies, debate movies, and pitch some movie ideas of their own. If it’s in the pictures, it’s on the podcast!',
    talent: ['Jeff D. Lowe', 'KenJac', 'Trillballins'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Custom Content']
  },
  'Longtime Toolies': {
    backgroundColor: '#792415',
    logo: 'https://chumley.barstoolsports.com/union/2020/08/25/LongTime-Toolies-Logo.2d62545c.jpg?crop=0.75%2C0.99%2Cx0.13%2Cy0.00%2Csafe',
    name: 'Longtime Toolies',
    img: 'https://chumley.barstoolsports.com/union/2021/02/08/toolies_square.c6b46451.jpg',
    about:
      'Uncle Chaps, from Zero Blog Thirty, is always whipping up ways to bring home improvement to Stoolies—hence, Toolies.',
    talent: ['Uncle Chaps'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Social']
  },
  'Lowering the Bar': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/10/25/1-1.png',
    name: 'Lowering The Bar',
    img: 'https://chumley.barstoolsports.com/union/2021/02/08/LTB_2000.c3f5fab4.jpg',
    about:
      'The Barstool Bar isn’t just for drinking—watch the reactions of Barstool personalities when they taste outrageous food, sip absurd drinks, and compete in ridiculous tasks.',
    talent: ['Vibbs', 'All Barstool Talent'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Segment', 'Branded Social']
  },
  Macrodosing: {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2021/02/19/0-4.85f36d36.jpg?crop=0.99%2C0.88%2Cx0.00%2Cy0.06%2Csafe',
    name: 'Macrodosing',
    img: 'https://chumley.barstoolsports.com/union/2021/04/28/macrodosing.bbb32783.png?width=500&height=500&crop=smart&bg-color=#000000',
    about:
      'Arian Foster, former NFL running back and current musical artist, and PFT Commenter, host of Barstool’s #1 Sports Franchise Pardon My Take, explore conspiracies, conundrums, and the dark corners of the deep web. Turn on. Tune in. Drop out.',
    talent: ['PFT Commenter', 'Arian Foster'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Custom Content']
  },
  'Mean Girl': {
    logo: 'https://chumley.barstoolsports.com/union/2022/01/31/MeanGirl_1920x1080.9901acb2.png',
    backgroundColor: '#ff70e5',
    name: 'Mean Girl',
    img: 'https://chumley.barstoolsports.com/union/2022/04/22/Screen-Shot-2022-04-22-at-12.06.05-PM.330f4773.png',
    about:
      'Alex Bennett and Jordyn Woodruff are the new Mean Girls at Barstool Sports. This podcast is dedicated to them sharing their stories of their "freshman year" at the company, their new life in New York City, and becoming friends and co-workers. What a world.',
    talent: ['Jordyn Woodruff', 'Alex Bennett'],
    showType: 'Podcast',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Meta Man': {
    name: 'Meta Man',
    img: 'https://chumley.barstoolsports.com/union/2021/08/17/Rd-9oIig.61ecb5ae.jpeg?width=500&height=500&crop=smart',
    about:
      'Each week, Mrags (aka “Meta Man”) will be breaking down the biggest stories, updates, and drama in the gaming world. In this quick-hitting one minute video, fans will learn everything they need to know to stay on top of all things gaming. ',
    talent: ['MRags'],
    showType: 'Social Series',
    integration: ['Show Sponsorship']
  },
  'Million Dollaz Worth of Game': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2020/04/21/51797bf6.png?crop=0.99%2C0.88%2Cx0.00%2Cy0.06%2Csafe',
    name: 'Million Dollaz Worth of Game',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/MDWG.ff3e9ce3.png',
    about:
      'Hosted by legendary Philadelphia rapper/actor, Gillie Da King, and social media influencer and disruptor, Wallo267, MILLION DOLLAZ WORTH OF GAME is nothing like your traditional show. Wallo and Gillie bring in their celebrity friends as guest hosts to talk about the music industry, sports, fame, and lessons they have learned along the way. Million Dollaz Worth of Game aims to bring authenticity, inspiration, and humor to young listeners everywhere.',
    talent: ['Gillie Da King', 'Wallo267'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'MLB The Show': {
    name: 'MLB The Show',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/MLBTHESHOW_01.1a0a933e.png',
    about:
      'Barstool’s growth on Twitch is rolling over into our baseball franchises. The Starting 9 crew will take to Twitch to stream their eSports skills and engage with fans in real-time. Former MLB players and friends or fans of the show will join Dallas and Jared as they live stream their gameplay.',
    talent: ['Dallas Braden', 'Jared Carrabis'],
    showType: 'Livestreams',
    integration: ['Stream Sponsorship', 'Branded Segment', 'Custom Content']
  },
  'Morning Wood': {
    backgroundColor: '#0D90E1',
    logo: 'https://chumley.barstoolsports.com/union/2020/08/04/unnamed-1.48b31fb0.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Morning Wood',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/MORNING_WOOD.ca7d5e4b.png',
    about:
      'Each morning during the season, Jared, Dallas and a rotating guest will chat the best, and worst moments of games from the night before. The daily show will be a short-form video series to bring quick baseball news for fans to start their day.',
    talent: ['Dallas Braden', 'Jared Carrabis'],
    showType: 'Social Series',
    integration: ['Show Sponsorship']
  },
  "My Mom's Basement": {
    backgroundColor: '#14A17F',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/12/11/0-4.png',
    name: "My Mom's Basement",
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/MMB.97866ee7.png',
    about:
      'If you’ve ever wondered what Robbie Fox and his buddies have discussed in his mom’s basement throughout the years, My Mom’s Basement is your chance to listen in on the conversation. Joined by guests from within and beyond the Barstool Sports bubble, Robbie touches on nerd culture, mixed martial arts, professional wrestling, music, movies, and more.',
    talent: ['Robbie Fox'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Custom Content']
  },
  'NHL20 Livestreams': {
    backgroundColor: '#343434',
    name: 'NHL20 Livestreams',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/NHL20-TWITCH-STREAMS.0c00c9b6.jpg',
    about:
      'Paul Bissonnette, Ryan Whitney, Mike Grinnell and other friends of Spittin’ Chiclets go live on Twitch to play NHL20',
    talent: ['Ryan Whitney', 'Paul Bissonnette', 'Mike Grinnell'],
    showType: 'Livestreams',
    integration: ['Stream Sponsorship', 'Branded Social', 'Custom Content']
  },
  oBSessed: {
    backgroundColor: '#ebb3f2',
    logo: 'https://chumley.barstoolsports.com/union/2022/03/07/ObsessedLogo_1920x1080.07505db1.png',
    name: 'oBSessed',
    img: 'https://chumley.barstoolsports.com/union/2022/04/22/Jordyn-6.jpg.4a337918.webp',
    about:
      'New talent hire at Barstool Sports from Minnesota, Jordyn Woodruff is obsessed with all things pop culture, TikToker Tea, and entertainment news. Every day, and longer weekend recap on Mondays, Jordyn will break down the top pop culture news, so you don’t have to.',
    talent: ['Jordyn Woodruff'],
    showType: 'Social Series',
    integration: ['Branded Social', 'Presenting Sponsorship']
  },
  'One Bite': {
    backgroundColor: '#A13631',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2017/12/18/f3xrYUp.png',
    name: 'One Bite',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/ONE_BITE.48b73ff2.png',
    about:
      'Dave’s daily man-on-the-street style ‘One Bite’ pizza reviews have garnered a cult following and have featured celebrity guests including Ashley Benson, Bryce Harper, Guy Fieri, and T-Pain. Dave’s mission is to hit every pizza shop in New York until all have been reviewed, One Bite at a time.',
    talent: ['Dave Portnoy'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Social', 'Commerce & Licensing']
  },
  'One Minute Man': {
    logo: 'https://chumley.barstoolsports.com/union/2021/04/07/0-5.30c315a1.jpg?crop=300%3A225%2Csmart',
    backgroundColor: '#c0c0c0',
    name: 'One Minute Man',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/OMM-1.0f21dfe3.jpg',
    about:
      'Kevin Clancy, host of KFC Radio, always has an opinion. He takes his rants to a short-form social video, covering topics about pop-culture to daily news stories.',
    talent: ['KFC'],
    showType: 'Social Series',
    integration: ['Show Sponsorship']
  },
  'Out & About': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2020/09/28/0.f8045bd5.jpeg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Out & About',
    img: 'https://chumley.barstoolsports.com/union/2021/02/10/Out-About-2-1.6e04f33e.jpg',
    about:
      'Join Barstool Sports’ Pat McAuliffe for irreverent conversations on gay culture, trending topics, and everything in between. Barstool is excited to bring its first LGBTQ podcast to the world—driven by one of the most entertaining talents we have on board. A new guest will be joining Pat weekly to keep the conversation relevant, entertaining and interesting on Out & About—the only LGBTQ podcast of its kind.',
    talent: ['Pat', 'Kate', 'Joey X'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Custom Content']
  },
  'Pardon My Take': {
    backgroundColor: '#343434',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2017/07/06/pmt.jpg',
    name: 'Pardon My Take',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/PMT.04d5c41c.png',
    about:
      'Big Cat and PFT Commenter deliver the loudest sports takes in the history of takes—a podcast by guys, for guys. Featuring unconventional interviews with sports personalities and segments including Mount Rushmore, PR 101 for troubled athletes/celebrities, Hot Seat/Cool Throne, and more, Pardon My Take is the #1 sports podcast.',
    talent: ['Big Cat', 'PFT Commenter', 'Hank'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'PGA2K Streams': {
    name: 'PGA2K Streams',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/pga2k-1.6136c3a0.jpg',
    about:
      'Fore Play started streaming PGA2k game play to engage with their fans from an eSports perspective. Since they began playing, they have created the #1 PGA2K online society and continue to bring fans along for the fun. Watch as Fore Play plays their fans’ created PGA2K courses live.',
    talent: ['Riggs', 'Trent', 'Frankie'],
    showType: 'Livestreams',
    integration: ['Stream Sponsorship', 'Branded Social', 'Custom Content']
  },
  "Pick 'Em": {
    backgroundColor: '#1C212B',
    logo: 'https://chumley.barstoolsports.com/union/2020/09/22/600x460-1.e8fcd548.jpg?crop=0.99%2C0.98%2Cx0.00%2Cy0.02%2Csafe',
    name: "Pick 'Em",
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/PICK_EM.e6cb7fa8.png',
    about:
      'If you’re looking for winners, you’ve found the right place. Hosts Big Cat, El Pres and Rico Bosco give you all their losing picks with extreme confidence. Pick ‘Em is weekly talk about bad beats and close misses. Rule Number 1 of gambling, always bet the over. Rule Number 2, if a mascot dies the week of a big game, it’s an automatic mortal lock.',
    talent: ['Big Cat', 'Dave Portnoy', 'Rico Bosco'],
    showType: 'Podcast Show',
    integration: ['Show Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Picks Central': {
    backgroundColor: '#141E30',
    logo: 'https://chumley.barstoolsports.com/union/2020/02/04/0f331b7a.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00',
    name: 'Picks Central',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/Picks-Central-1.d7636d44.jpg',
    about:
      'If you’re looking for winners, you’ve found the right place. Fade hosts Big Cat, El Pres, and a rotating cast of degenerates to give you all their losing picks with extreme confidence. Pick Em is a weekly podcast about bad beats, close misses, and all those times you’ve spent staring at the ceiling Sunday night thinking about how you’ll pay your bookie. Rule #1 of gambling: always bet the over. Rule #2: if a mascot dies the week of a big game, it’s an automatic mortal lock.',
    talent: ['Brandon Walker'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Plan Bri Uncut': {
    backgroundColor: '#17395F',
    logo: 'https://chumley.barstoolsports.com/union/2020/07/10/unnamed.87b080fd.jpg?crop=0.91%2C0.99%2Cx0.04%2Cy0.00%2Csafe',
    name: 'PlanBri Uncut',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/PLAN_BRI.756e23cb.png',
    about:
      'PlanBri Uncut features Barstool’s own Brianna Chickenfry—the Dr. Phil of college advice—giving the rundown of what it’s actually like to navigate life in one’s early 20s. She’s not here to sugarcoat anything, sharing her insane drunken memories all while giving tips on relationships, friendships, partying, and much more. Joined each week by a guest (usually from TikTok, where Brianna herself rose to fame), Brianna is always unfiltered and authentic, sending cheers to the trainwreck in all of us.',
    talent: ['Brianna Chickenfry'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Commerce & Licensing']
  },
  Podfathers: {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/12/19/podfathers-logo.jpg',
    name: 'Podfathers',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/PODFATHERS.7ee39e12.png',
    about:
      'The Podfathers is a podcast from Clem, Uncle Chaps, and Large breaking down the good, the bad, and the ugly of fatherhood. Other books, websites, and podcasts have tried to explain what life as a new dad is like from a man’s perspective—but they have all failed. Through their own personal experiences and brand of humor, the Podfathers help parents everywhere relate to the wild and the crazy ride that is raising children.',
    talent: ['Clem', 'Uncle Chaps', 'Large'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Custom Content']
  },
  'Prime Picks': {
    backgroundColor: '#8C1D1D',
    name: 'Prime Picks',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/PRIME-PICKS-1.9d1f7902.jpg',
    about:
      'Deion sets his sights on who should be the most watched players of the week, while Jamie gives his odds for the sports gamblers. Prime and Jamie are excited to share their weekly locks with Barstool fans.',
    talent: ['Deion Sanders', 'Jamie Dukes'],
    showType: 'Social Series',
    integration: ['Show Sponsorship']
  },
  "Rasslin'": {
    backgroundColor: '#FAE1C8',
    logo: 'https://chumley.barstoolsports.com/union/2020/11/28/unnamed-3.88a56754.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: "Rasslin'",
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/Rasslin-1.0d862ada.jpg',
    about:
      'Professional wrestling is the greatest spectacle in the world—and now Barstool Sports is back in the ring with a podcast featuring a crazed wrestling fan. For his entire life, host Brandon Walker has been two things: an idiot and a wrestling fanatic. From the Attitude Era to the heyday of Hulkmania all the way through the Undisputed Era, he’s sharing his love for wrestling. This is entertainment. This is Rasslin’. This is Barstool.',
    talent: ['Brandon Walker'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Custom Content']
  },
  'Red Line Radio': {
    backgroundColor: '#777777',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2018/08/08/red-line-radio-logo.png',
    name: ['Red Line Radio'],
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/RLR-2.999e1733.jpg',
    about:
      'The unofficial mayors of Chicago have gone from blogging to podcasting. On Red Line Radio, White Sox Dave and Barstool Carl bring you insightful, engaging, and humorous weekly baseball interviews, conversation, laughs with a slight (huge) Chicago bias.',
    talent: ['White Sox Dave', 'Carl', 'Eddie', 'Chief'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Riggs VS': {
    backgroundColor: '#343434',
    name: 'Riggs VS',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/RIGGS_VS.c82070bb.png',
    about:
      'Riggs is taking his competitive spirit and natural charisma to some of the most breathtaking holes in the country. Follow his golf game as he competes against courses with special guests.',
    talent: ['Riggs'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Host Read', 'Branded Social']
  },
  'Road Warriors': {
    backgroundColor: '#343434',
    name: 'Road Warriors',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/ROAD_WARRIORS.eca89f4d.png',
    about:
      'Paul Bissonnette of Barstool’s Spittin’ Chiclets spends the day with NHL legends to give a deeper look inside their everyday lives and uncover a few things you may not know about them. Past guests include Sean Avery & Terry Ryan',
    talent: ['Ryan Whitney', 'Paul Bissonnette', 'Rear Admiral'],
    showType: 'Behind the Scenes Series',
    integration: ['Show Sponsorship', 'Host Read', 'Branded Social']
  },
  'Rough n Rowdy': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2020/04/28/00389820.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Rough N Rowdy',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/RNR.7f348920.png',
    about:
      'Rough n’ Rowdy is Barstool’s own amateur boxing franchise. Each event brings the most unpredictable action packed entertainment with laugh-out-loud commentary from Dave “El Pres” Portnoy and Dan “Big Cat” Katz.',
    talent: ['All Barstool Talent'],
    showType: 'Pay Per View / Live Event',
    integration: ['Experiential', 'Ring Sponsorship', 'Custom Content', 'Custom Event Integration']
  },
  'Starting 9': {
    backgroundColor: '#8C1D1D',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2018/07/02/S9-Desktop.png',
    name: 'Starting 9',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/STARTING_9.4f47b196.png',
    about:
      'Starting 9 is a podcast whose hosts cut through the noise and talk straight about America’s greatest pastime. Do you care about play-by-play baseball coverage? We don’t either. Hosted by MLB great Dallas Braden and blogger Jared, Starting 9 makes baseball podcasts fun again.',
    talent: ['Dallas Braden', 'Jared Carrabis'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Sandbagger Invitationals': {
    name: 'Sandbagger Invitationals',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/SANDBAGGERS.a725ab47.png',
    about:
      'Paul Bissonnette and Ryan Whitney of Barstool’s Spittin’ Chiclets play former and current NHL players in 18 holes of golf. Stakes are high and the chirping is excessive.',
    talent: ['Ryan Whitney', 'Paul Bissonnette'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Host Read', 'Branded Social']
  },
  'Section 10': {
    backgroundColor: '#1A5B47',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2018/07/02/S10-Desktop.png',
    name: 'Section 10',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/SECTION_10.4251403b.png',
    about:
      'Section 10 is the best Red Sox podcast in the world. Jared Carrabis is joined by fellow superfans Steve Parrault and Coley Mick to give their best unfiltered takes on Boston’s Boys of Summer. They are regularly joined by such illustrious greats as Johnny Damon, Keith Foulke, and numerous other Red Sox players.',
    talent: ['Jared Carrabis', 'Steve Parrault', 'Coley Mick'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential'
    ]
  },
  'Son of a Boy Dad': {
    backgroundColor: '#417505',
    logo: 'https://chumley.barstoolsports.com/union/2021/06/15/0-9.a30e6dc1.png?crop=300%3A225%2Csmart',
    name: 'Son of a Boy Dad',
    img: 'https://chumley.barstoolsports.com/union/2021/08/17/SonofABoyDad.dd79eaee.jpg?width=500&height=500',
    about:
      'Lil Sasquatch recently dropped out of college and is rudderless in this big mean world. To help fill in the gaps on the education he missed, he leans on his producer Rone to help him craft a healthy worldview and teach him what it means to be a man.',
    talent: ['Rone', 'Lil Sasquatch'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Custom Content']
  },
  'Spikes Up': {
    name: 'Spikes Up',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/SPIKES_UP_01.80bfaeaf.png',
    about:
      'MLB great Dallas Braden and baseball blogger Jared Carrabis give you a behind the scenes look at the interviews, game reactions, and baseball drama that ensues while creating content for the Starting 9 podcast once a month.',
    talent: ['Dallas Braden', 'Jared Carrabis'],
    showType: 'Behind the Scenes Series',
    integration: ['Show Sponsorship', 'Host Read']
  },
  "Spittin' Chiclets": {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2020/06/15/52d0736c.png?crop=300%3A225%2Csmart',
    name: "Spittin' Chiclets",
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/CHICLETS.120884ff.png',
    about:
      'Spittin’ Chiclets is the #1 global hockey podcast and the single most influential brand in hockey. Hosts Ryan Whitney (retired NHL defenseman), Paul “BizNasty” Bissonnette (retired NHL left wing and current analyst for the Coyotes), and Rear Admiral (die-hard Bruins fan) discuss all things hockey, pop culture, and more.',
    talent: ['Ryan Whitney', 'Paul Bissonnette', 'Rear Admiral'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Sportsbook House': {
    name: 'Sportsbook House',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/SPORTSBOOK_HOUSE.68d1e712.png',
    about:
      'The official homebase for Barstool’s sports betting content & go-to destination for livestreams 24/7. As the Barstool Sportsbook rolls out across the country, Barstool’s all-star sports betting personalities will live together to place their first bets in new Sportsbook cities. Our personalities will spend weeks & weekends live streaming sports games from the house highlighting all the side commentary and emotion that our fans love to tune-in to witness.',
    talent: ['Dave Portnoy', 'Big Cat', 'Brandon Walker', 'Marty Mush'],
    showType: 'Livestreams',
    integration: [
      'House/Studio Sponsorship',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Stool Scenes': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/12/11/0-1.png',
    name: 'Stool Scenes',
    img: 'https://chumley.barstoolsports.com/union/2021/02/08/stool_scenes_2000.228b4e9d.jpg',
    about:
      'Barstool’s own reality show provides a behind-the-scenes look at the inner-workings of our office. From coverage of important company meetings with the CEO to passionate arguments between bloggers, Stool scenes has become a fan favorite.',
    talent: ['All Barstool Talent'],
    showType: 'Behind the Scenes Series',
    integration: ['Show Sponsorship', 'Host Read']
  },
  'Stool Streams': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2020/08/12/unnamed.c2f78a71.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Stool Streams',
    img: 'https://chumley.barstoolsports.com/union/2021/02/08/stool_streams_2000.e2c09685.jpg',
    about:
      'Barstool Sports has launched its own in-house sports league, streaming live competitions with some of the company’s most popular yet uncoordinated members of Barstool’s media personalities. With daily matches, fans can get in on the action with our free-to-play app, PlayBarstool. By placing bets on who will win, predicting over/unders, and locking in various prop bets, viewers have the chance to win real cash prizes.',
    talent: ['All Barstool Talent'],
    showType: 'Studio Show / Livestreams',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Branded Social']
  },
  'Sundae Conversations': {
    logo: 'https://chumley.barstoolsports.com/union/2021/07/19/0.b94dee29.png?crop=0.73%2C0.99%2Cx0.11%2Cy0.00%2Csafe',
    backgroundColor: '#88cef1',
    name: 'Sundae Conversations',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/SUNDAE-CONVERSATIONS-1.31431c2b.jpg',
    about:
      'SUNDAE CONVERSATION is Barstool’s most unique interview show to date. Caleb Pressley sits down with top athletes and celebrities, asking questions they wouldn’t hear anywhere else. To add a comedic layer, and in honor of Sunday interviews, Barstool’s GlennyBalls joins the interview to eat ice cream sundaes and listen intently. In these seriously funny video series, Caleb doesn’t just stick to sports, but asks each guest hard-hitting questions about life.',
    talent: ['Caleb'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Segment']
  },
  'The Case': {
    backgroundColor: '#666666',
    logo: 'https://chumley.barstoolsports.com/union/2021/04/05/unnamed.a6d3489e.png?crop=300%3A225%2Csmart',
    name: 'The Case',
    img: 'https://chumley.barstoolsports.com/union/2021/04/29/the-case.49a06554.png',
    about:
      'It’s been 31 years since 16-year-old, Jennifer Fay, from Brockton, in the south shore of Massachusetts, went missing. Kirk Minihane, a Bostonian himself, is set on finding out more about what happened to Jennifer and uncover the truths behind one of Boston’s most notoriously known disappearances.',
    talent: ['Kirk Minihane'],
    showType: 'Podcast Show (Limited Series)',
    integration: ['Presenting Sponsorship', 'Branded Segment', 'Branded Social', 'Custom Content']
  },
  'The College Cook': {
    name: 'The College Cook',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/COLLEGE_COOK.601c8f8a.png',
    about:
      'CHOPPED Finalist, Chef Donny, teaches even the most unskilled cooks how to utilize what they have in their kitchen to whip up a delicious meal.',
    talent: ['Chef Donny'],
    showType: 'Reality Series',
    integration: ['Show Sponsorship', 'Branded Social']
  },
  'The Corp': {
    backgroundColor: '#010001',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/08/23/CORP-BLOG-1.png',
    name: 'The Corp',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/THE_CORP.b8a99a97.png',
    about:
      'Alex “A-Rod” Rodriguez and Dan “Big Cat” Katz host The Corp, interviewing industry leaders, sports legends, and entrepreneurs on what makes them successful in their respective professions. The Corp features the best of business, humor, and personal stories from those who have lived out the American dream.',
    talent: ['ARod', 'Big Cat'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'The Dime Package': {
    backgroundColor: '#CAE7D3',
    logo: 'https://chumley.barstoolsports.com/union/2020/09/28/DimePackage_300x225.054e550f.jpg?crop=0.90%2C0.90%2Cx0.06%2Cy0.00%2Csafe',
    name: 'The Dime Package',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/THE_DIME_PACKAGE.b8203b6f.png',
    about:
      'Each week Kayce Smith and Fran Mariano bring their football takes to the world. Created for the female fan who wants to hear all the drama, both on and off the field, they’ll cover everything from game recaps to players’ personal relationships and everything in between. And as NFC East rivals, Kayce and Fran are not afraid to go head-to-head over the Cowboys and Eagles. Segments will include their “picks of the week”, a fantasy standout, and their favorite team rant.',
    talent: ['Kayce Smith', 'Fran'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'The Dog Walk': {
    backgroundColor: '#D9D9DA',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/01/07/CDW_Feed_280x210.png',
    name: 'The Dog Walk',
    img: 'https://chumley.barstoolsports.com/union/2021/02/10/dog-walk-2.23552eab.jpg',
    about:
      'Welcome to The Dog Walk hosted by Eddie from Barstool Sports. This is a quick hitting 15-20 minute show that touches on all walks of life. From conspiracies to learning about different jobs or the everyday banters it’s a perfect fit for the middle of your day.',
    talent: ['Eddie'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read']
  },
  'The Dozen': {
    backgroundColor: '#0B031B',
    logo: 'https://chumley.barstoolsports.com/union/2020/05/18/784adc04.jpg?crop=0.71%2C0.95%2Cx0.13%2Cy0.05%2Csafe',
    name: 'The Dozen',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/THE-DOZEN-1.5c61a6ee.jpg',
    about:
      "'The Dozen' is Barstool's newest twice-a-week trivia show where employees team up to take on trivia 'experts' Brandon Walker & PFT Commenter.",
    talent: ['Jeff D. Lowe', 'All Barstool Talent'],
    showType: 'Studio Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Branded Social']
  },
  'The Group Chat': {
    name: 'The Group Chat',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/thegroupchat.13a85c4e.jpg',
    about:
      'Fran and Ria, hosts of Chicks in the Office, take their daily pop-culture news show to Snapchat Discover. As unfiltered as text chains with your best friends, Ria and Fran hold nothing back as they share their takes on the daily headlines from celebrity gossip, reality television, and entertainment.',
    talent: ['Ria', 'Fran'],
    showType: 'Snap Discover Show',
    integration: ['Brand Moment', 'Custom Segment', 'Takeover']
  },
  'The Kevin Clancy Show': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2021/01/06/BLOG-SHOW-PAGE-300x225KCS.86aa4ad7.jpg?crop=300%3A225%2Csmart',
    name: 'The Kevin Clancy Show',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/KEVIN-CLANCY-SHOW.c7b06971.jpg',
    about:
      'There’s always been a difference between KFC Barstool and Kevin Clancy, and this podcast puts that on display. With solo segments ranting about unique topics to sit down interviews with the guests that matter most, the KEVIN CLANCY SHOW is the unfiltered access to a guy who’s lived his entire professional life on the internet (Barstool Sports, KFC radio, One Minute Man) and his entire adult life in the doghouse. From conspiracy theories and controversial news to pop culture nonsense and nostalgic trips down memory lane, I’m just here killing time until the aliens arrive',
    talent: ['KFC'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'The Kirk Minihane Show': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/08/30/kirk.jpg',
    name: 'Kirk Minihane',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/KIRK_MINIHANE.62078b21.png',
    about:
      'The Kirk Minihane show is a daily podcast from America’s most talented on-air personality, featuring Minihane’s raw, uncensored commentary on sports, politics, and culture—and anything else that matters. A former prisoner of corporate America, Minihane’s talents have been unleashed on an unsuspecting world. No one is safe. There is simply no other show like this one.',
    talent: ['Kirk Minihane'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'The Pro Football Football Show': {
    backgroundColor: '#110E0F',
    logo: 'https://chumley.barstoolsports.com/union/2020/08/31/BLOG-1.6f62903c.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'The Pro Football Football Show',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/PFF_SHOW.5d0f7488.png',
    about:
      ' Dave, Dan and Kayce are joined by NFL legend, Deion Sanders, for Barstool’s Sunday morning NFL kick off show. The revamped, Barstool-version of a pregame show will give Stoolies everything they need to know before kick off, but with more entertainment than ever before. Get ready for some unrivaled insight, commentary and of course laughs.',
    talent: ['Big Cat', 'Deion Sanders', 'Dave Portnoy', 'Kayce Smith'],
    showType: 'Studio Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Branded Social']
  },
  'The Rundown': {
    backgroundColor: '#05233F',
    logo: 'https://deadrabbit.barstoolsports.net/wp-content/uploads/2018/01/03/rd.jpg',
    name: 'The Rundown',
    img: 'https://chumley.barstoolsports.com/union/2021/02/08/rundown_2000.c0d3a2aa.jpg',
    about:
      "Barstool's daily flagship show, Barstool Rundown, is an unfiltered take on the internet's most relevant and viral topics—from current events and entertainment to sports and culture. Big Cat, Dave, KFC, and a rotating cast of Barstool personalities weigh-in with their opinions in a hilarious and unfiltered format.",
    talent: ['Big Cat', 'Dave Portnoy', 'KFC'],
    showType: 'Studio Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'The Short Porch': {
    backgroundColor: '#4E71C4',
    logo: 'https://media.barstoolsports.com/brands/the-short-porch/logo.v1.jpg',
    name: 'The Short Porch',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/THE_SHORT_PORCH.ab70ce1a.png',
    about:
      'Short Porch is the best podcast for the best franchise in sports. Hosts Hubbs and Tommy Smokes are two lifelong Yankee fans who eat, sleep, and breathe Yankee baseball. Airing Mondays and Thursdays during the regular season and once a week in the off-season, The Short Porch covers both major and outside-the-box stories from the Yankees Universe as the Yanks chase their 28th World Series championship.',
    talent: ['Hubbs', 'Tommy Smokes'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential'
    ]
  },
  'The Yak': {
    backgroundColor: '#000000',
    logo: 'https://chumley.barstoolsports.com/union/2021/03/30/unnamed.43a76719.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'The Yak',
    img: 'https://chumley.barstoolsports.com/union/2021/08/17/maxresdefault.60bd3291.jpg?crop=0.56%2C0.99%2Cx0.21%2Cy0.00%2Csafe',
    about:
      'The Yak features a few guys shooting the breeze and analyzing the ever-changing sports world based on their gut and analytics. Hosted by Big Cat, Caleb, Rone, KB, Nick and Brandon Walker, this show will be taking you through your lunch, so come yak with us every weekday.',
    talent: ['Big Cat', 'Caleb', 'Rone', 'KB', 'Nick Turani', 'Brandon Walker'],
    showType: 'YouTube Live Stream / Podcast Show',
    integration: ['Presenting Sponsorship', 'Product Integration', 'Host Read']
  },
  'This League': {
    backgroundColor: '#AF6D29',
    logo: 'https://chumley.barstoolsports.com/union/2021/01/05/BLOG-SHOW-PAGE-300x225This-League.abbcf365.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'This League',
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/ThisLeague-1.0d229422.jpg',
    about:
      'If you live for the drama of the NBA, you’re going to love THIS LEAGUE. Stay up to date with your host Trysta Krick, who humorously breaks down the storylines, rivalries, and drama that make the NBA the world’s greatest sports soap opera. From spicy takes to hilarious interviews, catch episodes every Monday and Friday so you can keep up on all the fun.',
    talent: ['Trysta'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Tiko Ten': {
    backgroundColor: '#f548f1',
    logo: 'https://chumley.barstoolsports.com/union/2021/11/01/0.e8759464.png?crop=600%3A450%2Csmart',
    name: 'Tiko Ten',
    img: 'https://chumley.barstoolsports.com/union/2022/04/22/61Qj7hP3nQL._SL500_.de57d238.jpg',
    about:
      'Tiko Ten covers the top 10 topics of the week in sports, music and culture brought to you by the one and only Tiko Texas. Tiko Texas, a Houston-born rapper, has had long history with Barstool and is now an official Barstool talent. Often joined by her twin brother & loyal friend, Patric, these two are a wild duo covering the hottest news while giving their entertaining takes to fans.',
    talent: ['Tiko Texas'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'Token CEO': {
    backgroundColor: '#F08EC5',
    logo: 'https://chumley.barstoolsports.com/union/2020/03/30/3cdae336.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe',
    name: 'Token CEO',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/TOKEN_CEO.d767b027.png',
    about:
      'Erika Nardini beat out 74 men to become the CEO of Barstool Sports. Often referred to as the Token CEO, Erika breaks down her own stereotype and discusses topics that she is uniquely positioned to answer. Through candid conversations and interviews, unfiltered opinions, and brutally honest self-reflection, Erika will prove that you can always be yourself and still be successful. Token CEO is raw, witty, fun, and insightful—just like its host.',
    talent: ['Erika Nardini'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Experiential',
      'Commerce & Licensing'
    ]
  },
  'Twisted History': {
    backgroundColor: '#1D1E1E',
    logo: 'https://chumley.barstoolsports.com/union/2020/01/30/338a3dd9.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00',
    name: 'Twisted History',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/TWISTED_HISTORY.2570796a.png',
    about:
      'Twisted History is a petulant and irreverent look at the darker side of history, hosted by two guys (Large & Vibbs) who had to look up the words “petulant” and “irreverent”. Go inside the mind of a Japanese Kamikaze, and ask yourself if you would die for a greater cause than your own? Look into the dark world of Hollywood directors, such as the Roman Polanski story and his cowardly escape from justice. The series aims to entertain by shedding light onto some of the weirder skeletons that reside within the closets of History, Sports, Music, Politics and Celebrity.',
    talent: ['Large', 'Vibbs'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Custom Content']
  },
  'Unnecessary Roughness': {
    backgroundColor: '#141E30',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/08/23/1-3.png',
    name: 'Unnecessary Roughness',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/UNNECESSARY_ROUGHNESS.09067b94.png',
    about:
      'Unnecessary Roughness is the only podcast in the country that delivers real, raw, unfiltered takes on college football. Hosts Brandon Walker and Kayce Smith pull no punches when discussing the national landscape of college football. Marty Mush, Jack Mac, and other special guests regularly join in on the madness.',
    talent: ['Brandon Walker', 'Kayce Smith'],
    showType: 'Podcast Show',
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social', 'Custom Content']
  },
  'Walk the Line': {
    backgroundColor: '#636B7F',
    logo: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/09/11/WTL-BRIAN2.png',
    name: 'Walk the Line',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/WTL.933562b0.png',
    about:
      'Walk the Line, hosted by Brandon Walker, explores Brandon’s daily gambling picks. Brandon first made a splash with Barstool Sports before he was technically an employee as an innocent party in Dave’s crusade against MyBookie.',
    showType: 'Studio Show',
    talent: ['Brandon Walker', 'Marty Mush'],
    integration: ['Studio Sponsorship', 'Branded Segment', 'Host Read', 'Branded Social']
  },
  'We Gotta Believe': {
    backgroundColor: '#315A88',
    logo: 'https://media.barstoolsports.com/brands/we-gotta-believe/logo.v1.jpg',
    name: 'We Gotta Believe',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/WE_GOTTA_BELIEVE.07cbecd5.png',
    about:
      'You don’t choose the Mets life—the Mets life chooses you. We Gotta Believe is hosted by the yin and the yang of Mets fandom—KFC (the battered pessimist) and Clem (the eternal optimist). Together, they talk through the ups and downs of riding the New York Mets roller coaster.',
    talent: ['KFC', 'Clem'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential'
    ]
  },
  'Zero Blog Thirty': {
    backgroundColor: '#323E38',
    logo: 'https://chumley.barstoolsports.com/union/2020/03/03/539b9e0d.jpg?crop=0.97%2C0.97%2Cx0.02%2Cy0.03%2Csafe',
    name: 'Zero Blog Thirty',
    img: 'https://chumley.barstoolsports.com/union/2021/02/02/ZBT.c22f64cb.png',
    about:
      'Three veterans with outgoing personalities host Zero Blog Thirty, the most unique military podcast you’ll ever hear. Kate, Uncle Chaps, and Captain Cons engage in colorful conversations that focus on war stories, politics, pop culture, and more.',
    talent: ['Uncle Chaps', 'Kate', 'Captain Cons'],
    showType: 'Podcast Show',
    integration: [
      'Studio Sponsorship',
      'Branded Segment',
      'Host Read',
      'Branded Social',
      'Custom Content',
      'Experiential',
      'Commerce & Licensing'
    ]
  }
}

export const verticalShowInfo = {
  'barstool-baseball': {
    title: 'Barstool Baseball',
    videoID: false,
    data: ['The Short Porch', 'We Gotta Believe', 'Red Line Radio'].map((show) => SHOWS[show])
  },
  'barstool-basketball': {
    title: 'Barstool Basketball',
    videoID: false,
    data: ['Barstool Bench Mob'].map((show) => SHOWS[show])
  },
  'barstool-business-news': {
    title: 'Barstool Business & News',
    videoID: 'vnnQdUynhsNLdHbYGzhGNH',
    data: ['Token CEO', 'Davey Day Trader', 'The Corp'].map((show) => SHOWS[show])
  },
  'barstool-cities': {
    title: 'Barstool Cities',
    videoID: false,
    data: [
      'Red Line Radio',
      'The Dog Walk',
      "Bussin' with the Boys",
      'Million Dollaz Worth of Game',
      'We Gotta Believe',
      'The Short Porch',
      'The Kirk Minihane Show'
    ].map((show) => SHOWS[show])
  },
  'barstool-combat': {
    title: 'Barstool Combat',
    videoID: 'a6v8W6uMcZxLtx46zpw9Ha',
    data: ['Rough n Rowdy', "My Mom's Basement", "Rasslin'"].map((show) => SHOWS[show])
  },
  'barstool-female-lifestyle': {
    title: 'Barstool Female Lifestyle',
    videoID: false,
    data: [
      'Chicks in the Office',
      'The Group Chat',
      'Plan Bri Uncut',
      'Cutting Stems',
      'Mean Girl',
      'oBSessed',
      'Cause I Said So',
      'Tiko Ten'
    ].map((show) => SHOWS[show])
  },
  'barstool-food': {
    title: 'Barstool Food',
    data: ['One Bite', 'Boozin Burgers', 'The College Cook'].map((show) => SHOWS[show])
  },
  'barstool-football': {
    title: 'Barstool Football',
    videoID: '3wStEfVRUrN5mYc3rSAEir',
    data: [
      'Pardon My Take',
      "Bussin' with the Boys",
      'Fantasy Football Factory',
      'Unnecessary Roughness',
      'The Dime Package',
      'The Pro Football Football Show',
      'Barstool College Football Show'
    ].map((show) => SHOWS[show])
  },
  'barstool-gaming': {
    title: 'Barstool Gaming',
    videoID: '84uyo1iCGomHCEA1pH343M',
    data: [
      'Gametime Livestreams',
      'PGA2K Streams',
      'NHL20 Livestreams',
      'MLB The Show',
      'Meta Man',
      'Barstool Plays'
    ].map((show) => SHOWS[show])
  },
  'barstool-golf': {
    title: 'Barstool Golf',
    videoID: '7f1S8kYFwgYSGLyr4jhu9V',
    data: [
      'Fore Play',
      'Fore Man Scramble',
      'Riggs VS',
      'Daily Nine',
      'Fixing Frankie',
      '51 Strokes',
      'Sandbagger Invitationals'
    ].map((show) => SHOWS[show])
  },
  'barstool-hockey': {
    title: 'Barstool Hockey',
    videoID: 'pw2q1KeyQUi5ypD4krYnRT',
    data: ["Spittin' Chiclets", 'Road Warriors', 'Sandbagger Invitationals', 'NHL20 Livestreams'].map(
      (show) => SHOWS[show]
    )
  },
  'barstool-lifestyle-entertainment': {
    title: 'Barstool Lifestyle & Entertainment',
    videoID: 'tvnTEAdPEv9vdWgL7eXozm',
    data: [
      'Million Dollaz Worth of Game',
      'Chicks in the Office',
      'KFC Radio',
      'Answer the Internet',
      'One Minute Man',
      'The Kevin Clancy Show',
      'Lights, Camera, Barstool',
      'The Kirk Minihane Show',
      'The Case',
      'Podfathers',
      'Twisted History',
      'Everybody is Awful',
      'The Group Chat',
      'The Dog Walk',
      'A New Untold Story',
      'Sundae Conversations',
      'Out & About',
      'Macrodosing',
      'Son of a Boy Dad',
      'BFFs',
      'Barstool Shopping Network',
      'Bants Sports Ooz'
    ].map((show) => SHOWS[show])
  },
  'barstool-military': {
    title: 'Barstool Military',
    videoID: false,
    data: ['Zero Blog Thirty'].map((show) => SHOWS[show])
  },
  'barstool-soccer': {
    title: 'Barstool Soccer',
    videoID: false,
    data: ['Back Again with Troopz'].map((show) => SHOWS[show])
  },
  'barstool-sportsbook': {
    title: 'Barstool Sportsbook',
    videoID: '5SkCaDZJkn39yohwd3iW8R',
    data: ['Barstool Sports Advisors', 'Walk the Line', 'Picks Central', "Pick 'Em", 'Sportsbook House'].map(
      (show) => SHOWS[show]
    )
  },
  'barstool-travel-outdoors': {
    title: 'Barstool Travel & Outdoors',
    videoID: 'cbJeSVsuSXuCJE1AnSpkKY',
    data: ['Barstool Outdoors', 'Barstool Abroad', 'Die Trying'].map((show) => SHOWS[show])
  },
  'behind-barstool': {
    title: 'Behind Barstool',
    videoID: '6j9E9ykzzKJsMWfFDPW6ji',
    data: [
      'The Dozen',
      'The Rundown',
      'Lowering the Bar',
      'Stool Scenes',
      'Stool Streams',
      'Longtime Toolies',
      'The Yak',
      'Friday Night Pints',
      'Barstool Gone Viral',
      'Dog Walk Snake Draft',
      'Barstool Shopping Network'
    ].map((show) => SHOWS[show])
  },
  'el-presidente': {
    title: 'El Presidente',
    videoID: 'sHXfZ7JxuQZZguEP71YJJm',
    data: [
      'One Bite',
      'The Rundown',
      "Pick 'Em",
      'Barstool Sports Advisors',
      'Davey Day Trader',
      'Dave Portnoy Show with Eddie & Co.',
      'BFFs'
    ].map((show) => SHOWS[show])
  }
}

export const verticalTalentPhotos = Object.freeze({
  'All Barstool Talent': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/07/07/Screen-Shot-2017-07-07-at-3.06.47-PM.png?width=120&height=120'
  },
  'Alex Bennett': {
    link: 'https://www.barstoolsports.com/bio/55078315/alex-bennett',
    img: 'https://chumley.barstoolsports.com/union/2021/08/06/Screen-Shot-2021-08-06-at-12.24.56-AM.71e1f0ac.png?crop=320%3A320%2Csmart&width=120&height=120'
  },
  ARod: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/12/05/arod-avatar.jpg?width=120&height=120'
  },
  'Ben Mintz': {
    img: 'https://chumley.barstoolsports.com/union/2020/11/10/0.2c3fe073.jpeg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63604/ben-mintz'
  },
  'Big Cat': {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/08/bigcat.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/45/big-cat'
  },
  'Brandon Walker': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/04/16/bwalker.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63454/brandon-walker'
  },
  'Brianna Chickenfry': {
    img: 'https://chumley.barstoolsports.com/union/2021/02/04/brianna-100.6161b9ff.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63540/brianna-chickenfry'
  },
  Caleb: {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/12/05/Screen-Shot-2016-12-05-at-9.39.42-AM.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/34534/caleb-pressley'
  },
  'Captain Cons': {
    img: 'https://chumley.barstoolsports.com/union/2020/08/24/unnamed-1-2.7b89a9f3.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63447/captain-cons'
  },
  Carl: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/06/07/images.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/14658/carl'
  },
  'Chef Donny': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/07/19/IMG_7358.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63397/chef-donny'
  },
  Chief: {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2017/04/07/chief-1.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/13266/chief'
  },
  Clem: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/08/16/clem.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/10177/clem'
  },
  'Coley Mick': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/07/07/fFbWZwb3.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/39858/coley-mick'
  },
  'Dallas Braden': {
    img: 'https://chumley.barstoolsports.com/union/2020/09/23/Dallas-Braden-1.79ee9594.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63431/dallas-braden'
  },
  'Dave Portnoy': {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/24/lingerietackle-150x150.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/54/el-presidente'
  },
  'Deion Sanders': {
    img: 'https://chumley.barstoolsports.com/union/2020/09/09/Deion-cut.b4c48a95.png?crop=0.99%2C0.99%2Cx0.00%2Cy0.00%2Csafe&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63580/deion-sanders'
  },
  Eddie: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/07/07/HJvc3WPz_400x400.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/14050/eddie'
  },
  'Erika Nardini': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/09/27/nardini-headshot.jpg?width=120&height=120'
  },
  'Expressions Oozing': {
    img: 'https://chumley.barstoolsports.com/union/2021/10/19/IMG_9685.05d11453.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/21228382/expressions-oozing'
  },
  Feitelberg: {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/22/Screen-Shot-2016-09-21-at-9.21.22-PM.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/40/feitelberg'
  },
  Fran: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/10/23/fran-avatar.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63429/fran'
  },
  Frankie: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/07/07/aL4k47a_400x400.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/62009/frankie-borrelli'
  },
  'Gillie Da King': {
    img: 'https://chumley.barstoolsports.com/union/2020/01/06/57886befc2e1.jpg?crop=0.90%2C0.90%2Cx0.05%2Cy0.07&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63514/gillie-da-king'
  },
  'Glenny Balls': {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/11/01/ii_158211bdd92e321c.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/61920/glenny-balls'
  },
  Hank: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/04/30/UG2A5091.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/39/handsome-hank'
  },
  Hubbs: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/06/16/Screen-Shot-2017-06-16-at-1.11.27-PM.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/32267/hubbs'
  },
  'Jake Marsh': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/07/10/pmtsportsbiz.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63479/jake-marsh'
  },
  'Jamie Dukes': {
    img: 'https://chumley.barstoolsports.com/union/2020/09/22/Jamie-Dukes.8dcebbf1.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63581/jamie-dukes'
  },
  'Jared Carrabis': {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/22/14344968_10207555188978054_7604750778172210500_n-150x150.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/13983/carrabis'
  },
  'Jeff D. Lowe': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/05/15/DbV_6aeU8AA-6ti-copy.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63387/jeff-d-lowe'
  },
  'Jeff Nadu': {
    img: 'https://chumley.barstoolsports.com/union/2020/10/14/Screen-Shot-2020-10-14-at-3.37.54-PM.a60683c9.png?crop=0.89%2C0.99%2Cx0.07%2Cy0.00%2Csafe&width=120&height=120'
  },
  'Jim Florentine': {
    img: 'https://chumley.barstoolsports.com/union/2020/05/15/9f5b9d60.png?crop=0.90%2C0.90%2Cx0.04%2Cy0.00%2Csafe&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63544/jim-florentine'
  },
  'Jordyn Woodruff': {
    img: 'https://chumley.barstoolsports.com/union/2021/10/05/unnamed.5d5e2d87.jpg?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/12911953/jordyn-woodruff'
  },
  'Josh Richards': {
    img: 'https://chumley.barstoolsports.com/union/2021/08/20/maxresdefault.cfbe01b3.192a9a59.jpg?width=120&height=120'
  },
  Kate: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/04/13/27655416_10103232464786682_8841592804021634356_n.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63379/kate'
  },
  'Kayce Smith': {
    img: 'https://chumley.barstoolsports.com/union/2021/02/04/kayce-100.6e0e78bf.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63376/kayce-smith'
  },
  KB: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/11/02/kb_400x400.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63432/kb'
  },
  'Kelly Keegs': {
    img: 'https://chumley.barstoolsports.com/union/2021/02/04/kelly-100.c9f52e4a.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63608/kelly-keegs'
  },
  KenJac: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/05/23/Screenshot_20180521-131637.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/21222/kenjac'
  },
  KFC: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/09/28/kfcface.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/44/kfc'
  },
  'Kim Bennett': {
    img: 'https://chumley.barstoolsports.com/union/2021/08/06/Screen-Shot-2021-08-06-at-12.25.07-AM.ff2edf10.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/86448277/kim'
  },
  'Kirk Minihane': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/06/06/minihane.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63460/kirk-minihane'
  },
  Large: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/06/01/IMG_00141.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63390/large'
  },
  'Lil Sasquatch': {
    img: 'https://chumley.barstoolsports.com/union/2020/06/08/f030a3f4.png?crop=0.90%2C0.90%2Cx0.05%2Cy0.00%2Csafe&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63548/lil-sasquatch'
  },
  'Marty Mush': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/09/26/Screen-Shot-2018-09-25-at-9.52.58-PM.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63410/marty-mush'
  },
  MRags: {
    img: 'https://chumley.barstoolsports.com/union/2021/04/19/0.06860c63.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/30371040/mrags'
  },
  'Nick Turani': {
    img: 'https://chumley.barstoolsports.com/union/2020/02/03/72638ac4.jpg?crop=0.99%2C0.99%2Cx0.00%2Cy0.00&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63525/nick'
  },
  Pat: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/06/21/Screen-Shot-2017-06-21-at-10.35.17-AM.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63334/pat'
  },
  'Paul Bissonnette': {
    img: 'https://chumley.barstoolsports.com/union/2021/02/09/NRBKn_Nc_400x400-1.ea4627a6.jpg'
  },
  'PFT Commenter': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/06/11/BQaaNqOD_400x400.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/48135/pft-commenter'
  },
  'Rear Admiral': {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/26/IMG_2340.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/13400/rear-admiral'
  },
  Ria: {
    img: 'https://chumley.barstoolsports.com/union/2020/10/09/Ria-2.6f9acc94.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/62311/ria'
  },
  'Rico Bosco': {
    img: 'https://chumley.barstoolsports.com/union/2020/09/29/Rico-Weather-1.0546e108.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/41523/rico-bosco'
  },
  Riggs: {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/22/CmDgInjUsAA1MLQ.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/50484/riggs'
  },
  'Robbie Fox': {
    img: 'https://chumley.barstoolsports.com/union/2020/02/18/dc60fcb4.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/62543/robbie-fox'
  },
  Rone: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/07/07/Screen-Shot-2017-07-07-at-4.17.39-PM.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/56229/rone'
  },
  'Ryan Whitney': {
    img: 'https://chumley.barstoolsports.com/union/2021/02/04/whitney-100.bad22bea.jpg?width=120&height=120'
  },
  Smitty: {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/22/hOimvn7Q_400x400.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/11404/smitty'
  },
  'Steve Parrault': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/07/07/Screen-Shot-2017-07-07-at-3.06.47-PM.png?width=120&height=120'
  },
  'Steven Cheah': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/02/06/cheah.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/62857/steven-cheah'
  },
  'Taylor Lewan': {
    img: 'https://chumley.barstoolsports.com/union/2020/09/10/Taylor-Lewan.57e55c0e.png?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63524/taylor-lewan'
  },
  'The Wonton Don': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2017/07/11/sOlNC8C-150x150.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/62720/the-wonton-don'
  },
  'Tiko Texas': {
    img: 'https://chumley.barstoolsports.com/union/2021/10/21/0.a69911f7.jpg?crop=320%3A320%2Csmart&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/9389013/tiko-texas'
  },
  'Tommy Smokes': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/01/03/picmarmad.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63377/tommy-smokes'
  },
  Trent: {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/22/Screen-Shot-2016-09-22-at-3.06.38-PM.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/50/trent'
  },
  Trillballins: {
    img: 'https://chumley.barstoolsports.com/union/2021/02/04/hoXVVme7.5354b27f.jpg?width=120&height=120'
  },
  Troopz: {
    img: 'https://chumley.barstoolsports.com/union/2020/09/03/TrJzfQ4n.0490bce8.png?crop=0.90%2C0.90%2Cx0.05%2Cy0.00%2Csafe&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63582/troopz'
  },
  Trysta: {
    img: 'https://chumley.barstoolsports.com/union/2019/11/13/ee804c92c063.jpeg?crop=0.90%2C0.87%2Cx0.06%2Cy0.05&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63509/trysta-krick'
  },
  'Uncle Chaps': {
    img: 'https://cdn.barstoolsports.com/wp-content/uploads/2016/09/22/iaMxHp9y_400x400.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/57564/chaps'
  },
  Vibbs: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/11/01/Screen-Shot-2018-11-01-at-10.37.54-AM.png?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63344/vibbs'
  },
  Wallo267: {
    img: 'https://chumley.barstoolsports.com/union/2020/01/06/76bf9a2ce61b.jpg?crop=0.90%2C0.90%2Cx0.00%2Cy0.03&width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63515/wallo267'
  },
  'White Sox Dave': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/12/17/wsd.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/13231/white-sox-dave'
  },
  'Will Compton': {
    img: 'https://chumley.barstoolsports.com/union/2021/02/04/compton-100.1f0b1308.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63523/will-compton'
  },
  'Willie Colon': {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2019/01/18/willie.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63445/willie-colon'
  },
  Zah: {
    img: 'https://chumley.barstoolsports.com/wp-content/uploads/2018/04/12/IMG_5930-150x150.jpg?width=120&height=120',
    link: 'https://www.barstoolsports.com/bio/63343/zah'
  }
})

export const caseStudyInfo = {
  'co-branded-new-amsterdam-vodka-pink-whitney': {
    title: 'NEW AMSTERDAM VODKA & BARSTOOL SPORTS',
    pageCopy: [
      [
        'The partnership objective began as a simple awareness campaign to promote New Amsterdam Vodka’s association with hockey. The campaign then took on an entirely new meaning with the creation of the “Pink Whitney” drink, which took on a life of its own.'
      ],
      [
        'What began as a podcast read turned into a nation-wide sensation. One day during an ad read for New Amsterdam Vodka, Ryan Whitney, co-host of Barstool Sports’ podcast Spittin’ Chiclets, admitted his love for pink lemonade and vodka. This news took the Internet by storm. Fans were shocked by and obsessed with Whitney’s love for this drink combination, and #PinkWhitney almost immediately started trending on Twitter.',
        'To capitalize on this unprecedented buzz, New Amsterdam Vodka and Barstool produced a co-branded pink lemonade flavored vodka––from first read to retail shelves in just 9 months.'
      ]
    ],
    videoSource: {
      duration: 75,
      sources: ['https://stream.mux.com/00f2ExINRwrMhEoAbbOWMHRlyHA005Z2cq.m3u8'],
      poster_sources: [{ src: 'https://image.mux.com/00f2ExINRwrMhEoAbbOWMHRlyHA005Z2cq/thumbnail.jpg?time=7.59' }],
      poster: 'https://image.mux.com/00f2ExINRwrMhEoAbbOWMHRlyHA005Z2cq/thumbnail.jpg?time=7.59'
    }
  },
  'league-nascar': {
    title: 'NASCAR & BARSTOOL SPORTS',
    pageCopy: [
      ['To promote awareness of, viewership of, and attendance at NASCAR events'],
      [
        'In less than one year, Barstool and NASCAR have developed a relationship that evolved from a simple trial run to a fully entrenched partnership. In 2019, we introduced the Barstool audience to NASCAR with a presence at the Daytona 500. This partnership grew to a season-long commitment—and ultimately a Barstool-branded racecar and a NASCAR podcast hosted by Dave Portnoy and Clint Bowyer.'
      ]
    ],
    videoSource: {
      duration: 84,
      sources: ['https://stream.mux.com/tiA52dog9cIoeY802D8zMZq79RfiRq01sH.m3u8'],
      poster_sources: [{ src: 'https://image.mux.com/tiA52dog9cIoeY802D8zMZq79RfiRq01sH/thumbnail.jpg' }],
      poster: 'https://image.mux.com/tiA52dog9cIoeY802D8zMZq79RfiRq01sH/thumbnail.jpg'
    }
  },
  'merch-peter-millar-vineyard-vines': {
    title: 'Peter Millar / Vineyard Vines & Barstool Sports',
    pageCopy: [
      ['Peter Millar collaborated with Barstool Sports to create the Barstool Golf line.'],
      [
        'Barstool Sports executes successful merchandise collaborations that involve product development, sponsored content, and other targeted strategies to help drive awareness and sales of the new Barstool Golf line. The performance of the Barstool Golf line was so impressive that the partnership grew to include two more Barstool franchises: The Corp and Spittin’ Chiclets.'
      ]
    ],
    imgSource: 'https://chumley.barstoolsports.com/union/2021/02/02/barstool_golf.34c7dd5a.jpg'
  },
  'retail-macys': {
    title: "Macy's & Barstool Sports",
    pageCopy: [
      [
        'Macy’s partnered with Barstool’s Chicks in the Office to engage female millennials, who historically found the brand irrelevant.'
      ],
      [
        'A 360 partnership with Chicks in the Office. From podcasts to Snapchat, TikTok, and Instagram, hosts Ria and Fran have built brand love—and a new customer base—among their fans.'
      ],
      [
        '"Working with Chicks in the Office has been such an amazing experience for Macy’s. Being new to the podcasting space, Macy’s looked to align with partners that could tell our story to a new, younger audience. Ria and Fran’s authenticity and love for Macy’s products has already shown strong results. Their desire to weave messaging into their show and throughout their social extensions has really been extraordinary."',
        '- Kristy Carruba, Director Of Audio Planning & Strategy, Macy’s'
      ]
    ],
    videoSource: {
      duration: 16,
      sources: ['https://stream.mux.com/hDP9MNy4D927GBXolta5mlbilBLrYePK.m3u8'],
      poster_sources: [{ src: 'https://image.mux.com/hDP9MNy4D927GBXolta5mlbilBLrYePK/thumbnail.jpg' }],
      poster: 'https://image.mux.com/hDP9MNy4D927GBXolta5mlbilBLrYePK/thumbnail.jpg'
    }
  },
  'frozen-food-devour': {
    title: 'DEVOUR & Barstool Sports',
    pageCopy: [
      [
        'DEVOUR is still a relatively new brand that seeks to grow its household penetration, driving awareness and purchase consideration for its key target demographic, “Trevor.” Trevor is a 21-year-old male who considers himself an expert in the latest trends and technology. He likes to be regarded as “ahead of the game” in utilizing apps, sports sites, and social media as a means to refuel his knowledge. Barstool is the perfect place to reach Trevor.'
      ],
      [
        'By using every facet of what Barstool has to offer, DEVOUR has been able to engage Trevor everywhere he is consuming media. Whether it is a key sports tentpole such as the Super Bowl, a custom and ownable eSports tournament on Twitch, Barstool’s gambling content podcasting, Barstool’s numerous social media accounts, our video series, or our high impact media—DEVOUR’s partnership with Barstool has grown over time to truly embrace the far reach and diversity of our entire portfolio.'
      ]
    ],
    videoSource: {
      duration: 44,
      sources: ['https://stream.mux.com/hzQYbRGQXTYbBKgo3meDSGf02o5gQamLc.m3u8'],
      poster_sources: [{ src: 'https://image.mux.com/hzQYbRGQXTYbBKgo3meDSGf02o5gQamLc/thumbnail.jpg' }],
      poster: 'https://image.mux.com/hzQYbRGQXTYbBKgo3meDSGf02o5gQamLc/thumbnail.jpg'
    }
  }
}
