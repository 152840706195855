import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import { track } from 'lib/whoami'
import capitalize from 'lodash/capitalize'
import { storyTypeCDPMap } from '../../utils/stories'
import { siteHeaderEvents } from 'lib/ga'
import { THEME, storyLink, playlistLink, truncateString, applyFastlyParams } from 'utils'
import Thumbnail from 'components/Thumbnail'

const SubnavFeed = ({ items, feed, options = [], stackedFeedAndButtons = false, type = 'stories' }) => {
  if (!items || !items.length) return null
  const CHARACTER_COUNT = 55

  const handleSubNavLinkCDPEvent = (feed, option) => {
    if (!feed) return
    const src = `https://barstoolsports.com${option?.href ? option.href : `/${feed}`}`
    const componentName = `SubnavFeed View All ${
      option?.title ? `${capitalize(feed)} ${capitalize(option?.title)}` : capitalize(feed)
    } Link`
    track('ContentClicked', {
      content: {
        src,
        type: 'Link',
        componentName
      }
    })
  }
  return (
    <>
      <div className='dropdownMenu'>
        <div className='container'>
          <div className='row'>
            {items.slice(0, 4).map((item, index) => {
              const handleClick = () => {
                siteHeaderEvents.navLink(`${feed.toUpperCase()} - Block ${index + 1}`)
                track('ContentClicked', {
                  content: {
                    src: item.url,
                    barstoolBrandID: item?.brand_id ? String(item?.brand_id) : undefined,
                    barstoolTalentID: String(item?.author?.id),
                    barstoolPodcastID: item?.post_type_meta?.podcast?.podcast?.id
                      ? String(item?.post_type_meta?.podcast?.podcast?.id)
                      : undefined,
                    barstoolPostID: String(item?.id),
                    imageSrc: item?.thumbnail?.raw,
                    type: storyTypeCDPMap[item?.type],
                    componentName: 'SubnavFeed dropdownItem'
                  }
                })
              }
              if (type === 'playlists') {
                const imageSrc = applyFastlyParams(item.thumbnail.raw, { crop: '16:9' })
                return (
                  <div className='col col--dropdownItem' key={item.id}>
                    <Link
                      href={playlistLink(item).href}
                      as={playlistLink(item).as}
                      key={item.id}
                      prefetch={false}
                      legacyBehavior
                    >
                      <a itemProp='url' className='dropdownItem' onClick={handleClick}>
                        <Thumbnail src={imageSrc} alt={item.title} aspectRatio='16:9,smart' />
                        <span className='dropdownItem__title' itemProp='name'>
                          {truncateString(item.title, CHARACTER_COUNT)}
                        </span>
                      </a>
                    </Link>
                  </div>
                )
              } else {
                return (
                  <div className='col col--dropdownItem' key={item.id}>
                    <Link key={item.id} {...storyLink(item)} prefetch={false} legacyBehavior>
                      <a itemProp='url' className='dropdownItem' onClick={handleClick}>
                        <Thumbnail src={item.thumbnail.desktop} alt={item.title} />
                        <span className='dropdownItem__title' itemProp='name'>
                          {truncateString(item.title, CHARACTER_COUNT)}
                        </span>
                      </a>
                    </Link>
                  </div>
                )
              }
            })}
          </div>
          {stackedFeedAndButtons && <br />}
          <div className='viewAll'>
            {options.map((option) => (
              <div className='dropdown__navItem' key={option.title}>
                <Link href={option.href} prefetch={false} legacyBehavior>
                  <a
                    itemProp='url'
                    onClick={() => {
                      siteHeaderEvents.navLink(`${feed.toUpperCase()} - ${capitalize(options.title)}`)
                      handleSubNavLinkCDPEvent(feed, option)
                    }}
                  >
                    <span className='dropdown__navItemTitle' itemProp='name'>
                      {option.title}
                    </span>
                  </a>
                </Link>
              </div>
            ))}
            <Link href={`/${feed}`} prefetch={false} legacyBehavior>
              <a
                className='btn btn--secondary'
                onClick={() => {
                  siteHeaderEvents.navLink(`${feed.toUpperCase()} - All`)
                  handleSubNavLinkCDPEvent(feed)
                }}
              >
                View All
              </a>
            </Link>
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 100%;
          margin: 0 auto;
          padding: 0;
        }
        .col--dropdownItem {
          min-width: 50%;
          width: 50%;
          max-width: 50%;
          flex-basis: 50%;
          margin-bottom: 15px;
        }
        .dropdownMenu {
          width: 100%;
          position: absolute;
          z-index: 15;
          top: 100%;
          left: 0;
          background: #f1f1f1;
          padding: 30px;
          border-top: 1px solid ${THEME.COLORS.LIGHTERGRAY};
          border-bottom: 1px solid ${THEME.COLORS.LIGHTERGRAY};
        }
        .row {
          padding: 0 2.5rem;
        }
        .dropdownItem {
          display: block;
          minheight: 170px;
        }
        .dropdownItem__title {
          margin-top: 5px;
          color: ${THEME.COLORS.TEXTCOLOR};
          font-size: 14px;
        }
        .dropdown__navItem {
          margin: 0 0 1rem 0;
        }
        .dropdown__navItemTitle {
          color: ${THEME.COLORS.TEXTCOLOR};
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .viewAll {
          margin-top: 15px;
          text-align: center;
          display: flex;
          font-size: 12px;
          flex-direction: column;
          align-content: top;
        }
        .viewAll .btn {
          width: 100%;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .dropdownMenu {
            padding: 25px;
          }
          .col--dropdownItem {
            min-width: 25%;
            max-width: 25%;
            flex-basis: 25%;
            margin-bottom: 0;
          }
          .container {
            display: flex;
            flex-direction: ${stackedFeedAndButtons ? 'column' : 'row'};
            text-align: center;
            justify-content: center;
            margin: 0 auto;
            width: 100%;
            max-width: 1200px;
            align-items: top;
          }
          .viewAll {
            order: ${stackedFeedAndButtons ? 1 : 0};
            font-size: 14px;
            width: 15%;
            text-align: ${stackedFeedAndButtons ? 'center' : 'left'};
            margin: ${stackedFeedAndButtons ? 'auto' : 0};
            padding: 0;
            align-items: top;
          }
          .viewAll img {
            max-width: 20px;
          }
          .row {
            order: ${stackedFeedAndButtons ? 0 : 1};
            width: 85%;
            margin: ${stackedFeedAndButtons ? 'auto' : 0};
            padding: 0;
            text-align: left;
          }
        }
      `}</style>
    </>
  )
}

export default observer(SubnavFeed)
