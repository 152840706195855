import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import FacebookIcon from 'icons/Facebook'
import TwitterIcon from 'icons/Twitter'
import InstagramIcon from 'icons/Instagram'
import YoutubeIcon from 'icons/Youtube'
import SmartLink from 'components/SmartLink'
import { THEME } from 'utils'
import Image from 'next/image'
import { track } from 'lib/whoami'
import AllyantLogo from '../../icons/AllyantLogo'

const SiteFooter = ({ footerData }) => {
  const sections = {}
  if (footerData && footerData.length) {
    footerData.forEach((footerPiece) => {
      const { section, ...otherData } = footerPiece
      if (!sections[section]) sections[section] = []
      sections[section].push(otherData)
    })
  }

  const handleFooterLinkCDPEvent = (item) => {
    if (!item) return
    const src = item?.href.includes('http') ? item?.href : `https://barstoolsports.com${item.href}`
    track('ContentClicked', {
      content: {
        src,
        type: 'Link',
        componentName: 'SiteFooter'
      }
    })
  }
  return (
    <nav className='siteFooter'>
      <div className='container'>
        {Object.keys(sections).length > 0 && (
          <div className='row'>
            {[...new Set(Object.keys(sections))].map((sectionTitle) => (
              <div key={sectionTitle} className='col col--section'>
                <h4 className='siteFooter__listTitle'>{sectionTitle}</h4>
                <ul className='siteFooter__list'>
                  {sections[sectionTitle].map((item) => (
                    <li className='siteFooter__listItem' key={item.title}>
                      <SmartLink
                        item={item}
                        onClick={() => {
                          handleFooterLinkCDPEvent(item)
                        }}
                      >
                        {item.title}
                      </SmartLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
        <div className='siteFooter__bottom'>
          <Image
            className='siteFooter__logo'
            src={'https://chumley.barstoolsports.com/union/2023/11/03/logo.b8652ed3.png'}
            alt='Barstool Sports logo'
            height={0}
            width={0}
            sizes='(max-width: 1400px) 30vw, 200px'
          />
          <div className='siteFooter__iconsContainer'>
            <h4 className='siteFooter__socialTitle'>Follow Us</h4>
            <ul className='siteFooter__socialLinks'>
              <li>
                <a
                  href='https://www.facebook.com/barstoolsports/'
                  onClick={(e) => {
                    handleFooterLinkCDPEvent(e.currentTarget)
                  }}
                >
                  <FacebookIcon color={THEME.COLORS.SECONDARY} width='auto' height='24px' />
                </a>
              </li>
              <li>
                <a
                  href='https://twitter.com/barstoolsports'
                  onClick={(e) => {
                    handleFooterLinkCDPEvent(e.currentTarget)
                  }}
                >
                  <TwitterIcon color={THEME.COLORS.SECONDARY} width='auto' height='24px' />
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/barstoolsports/'
                  onClick={(e) => {
                    handleFooterLinkCDPEvent(e.currentTarget)
                  }}
                >
                  <InstagramIcon color={THEME.COLORS.SECONDARY} width='auto' height='24px' />
                </a>
              </li>
              <li>
                <a
                  href='https://www.youtube.com/user/itsthebroshowyo'
                  onClick={(e) => {
                    handleFooterLinkCDPEvent(e.currentTarget)
                  }}
                >
                  <YoutubeIcon color={THEME.COLORS.SECONDARY} width='24px' height='auto' />
                </a>
              </li>
            </ul>
            <div className='siteFooter__accessibility'>
              <h4 className='siteFooter__accessibilityTitle'>Accessibility</h4>
              <a
                href='http://www.allyant.com'
                rel='noopener noreferrer'
                target='_blank'
                onClick={(e) => {
                  handleFooterLinkCDPEvent(e.currentTarget)
                }}
              >
                <AllyantLogo />
              </a>
            </div>
          </div>
        </div>
        <div className='siteFooter__copyrightAndDocs'>
          <p className='siteFooter__docs'>
            <Link href='/download' legacyBehavior>
              <a
                onClick={() => {
                  handleFooterLinkCDPEvent('/download')
                }}
              >
                App
              </a>
            </Link>
            <Link href='/partnerships' legacyBehavior>
              <a
                onClick={() => {
                  handleFooterLinkCDPEvent('/partnerships')
                }}
              >
                Advertising Inquiries
              </a>
            </Link>
            <Link href='/terms-of-use' legacyBehavior>
              <a
                onClick={() => {
                  handleFooterLinkCDPEvent('/terms-of-use')
                }}
              >
                Terms of Use
              </a>
            </Link>
            <Link href='/privacy-policy' legacyBehavior>
              <a
                onClick={() => {
                  handleFooterLinkCDPEvent('/privacy-policy')
                }}
              >
                Privacy Policy
              </a>
            </Link>
            <Link href='/content-policy' legacyBehavior>
              <a
                onClick={() => {
                  handleFooterLinkCDPEvent('/content-policy')
                }}
              >
                Content Policy
              </a>
            </Link>
            <Link href='/cookie-policy' legacyBehavior>
              <a
                onClick={() => {
                  handleFooterLinkCDPEvent('/cookie-policy')
                }}
              >
                Cookie Policy
              </a>
            </Link>
            <Link href='/messaging-terms' legacyBehavior>
              <a
                onClick={() => {
                  handleFooterLinkCDPEvent('/messaging-terms')
                }}
              >
                Messaging Terms
              </a>
            </Link>
            <Link href='/digital-sale-terms' legacyBehavior>
              <a
                onClick={() => {
                  handleFooterLinkCDPEvent('/digital-sale-terms')
                }}
              >
                Digital Sale Terms
              </a>
            </Link>
          </p>
          <p className='siteFooter__copyright'>© {new Date().getFullYear()} Barstool Sports. All rights reserved.</p>
        </div>
      </div>
      <style jsx global>{`
        .siteFooter__logo {
          width: auto;
          height: 44px;
          align-self: center;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .siteFooter__logo {
            margin-bottom: 0;
          }
        }
      `}</style>
      <style jsx>{`
        .col--section {
          min-width: 50%;
          flex-basis: 50%;
          text-align: left;
        }
        .container {
          display: flex;
          flex-grow: 2;
          flex-direction: column;
          text-align: center;
        }
        .siteFooter {
          background: #ffffff;
          padding: 30px 0 60px;
        }
        .siteFooter__listTitle {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 1.25rem;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 0.4px;
          line-height: 20px;
          margin: 0 0 12px 0;
          color: ${THEME.COLORS.GRAY};
        }
        .siteFooter__list {
          list-style: none;
          margin: 0 0 30px;
          padding: 0;
        }
        .siteFooter__listItem {
          font-size: 14px;
          letter-spacing: 0.1px;
          line-height: 1rem;
          margin-bottom: 10px;
        }
        .siteFooter__bottom {
          padding-top: 20px;
          padding-bottom: 0;
          align-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;
        }
        .siteFooter__socialTitle,
        .siteFooter__accessibilityTitle {
          font-family: ${THEME.FONTS.BEBAS};
          font-size: 20px;
          text-transform: uppercase;
          color: ${THEME.COLORS.GRAY};
          margin: 25px 0 10px;
        }
        .siteFooter__socialLinks {
          display: flex;
          flex-direction: row;
          list-style: none;
          gap: 24px;
          padding: 0;
          margin: 0;
          justify-content: center;
          align-items: center;
        }
        .siteFooter__socialLinks li {
          margin: 0;
          padding: 0;
        }
        .siteFooter__socialLinks li:first-child {
          margin-left: 0;
        }
        .siteFooter__accessibility {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .siteFooter__copyright {
          font-size: 14px;
          letter-spacing: 0.1px;
          margin-bottom: 0;
          padding: 1em;
          order: 1;
        }
        .siteFooter__docs {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          letter-spacing: 0.1px;
          padding: 2.5em 1em 1em;
          order: 0;
        }
        .siteFooter__copyrightAndDocs {
          display: flex;
          flex-direction: column;
        }
        .siteFooter__copyrightAndDocs a {
          color: inherit;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .col--section {
            min-width: 16.66%;
            flex-basis: 16.66%;
          }
          .siteFooter {
            margin-top: 100px;
            padding: 30px 0;
          }
          .siteFooter__bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 30px;
            padding-bottom: 0;
          }
          .siteFooter__iconsContainer {
            display: flex;
            align-items: center;
          }
          .siteFooter__socialTitle,
          .siteFooter__accessibilityTitle {
            margin: 0;
          }
          .siteFooter__accessibility {
            flex-direction: row;
            gap: 20px;
            margin-left: 30px;
          }
          .siteFooter__socialLinks {
            margin: 0 0 0 20px;
          }
          .siteFooter__copyrightAndDocs {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 10px;
          }
          .siteFooter__copyrightAndDocs a {
            padding-right: 0;
          }
          .siteFooter__copyright {
            max-width: 30%;
            text-align: left;
            order: 0;
            padding-left: 0;
          }
          .siteFooter__docs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 70%;
            justify-content: flex-end;
            order: 1;
            gap: 12px;
            padding-right: 0;
            padding-top: 1em;
          }
          .siteFooter__docs li {
            display: block;
          }
        }
      `}</style>
    </nav>
  )
}

export default observer(SiteFooter)
