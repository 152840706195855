import AdWordsPixel from './adwords'
import { pageView as fbPageview } from './facebook'
import { pageView as twPageview } from './twitter'

export const loadPixels = () => {
  AdWordsPixel.loadScript()
}

export const pageView = () => {
  fbPageview()
  twPageview()
}

export default {
  loadPixels,
  pageView
}
