import { isMobile } from './server'

const adPositions = (mobile = false) => ({
  preroll: {
    site_id: mobile ? 8086 : 8089,
    appendString: '_pre'
  },
  midroll: {
    site_id: mobile ? 8087 : 8090,
    appendString: '_mid'
  },
  postroll: {
    site_id: mobile ? 8088 : 8091,
    appendString: '_post'
  }
})

export async function getPublicaResponse(videoSource, story, position, ip) {
  try {
    const positions = adPositions(isMobile())
    const { site_id, appendString } = positions[position]

    const response = await fetch(
      'https://pbs.getpublica.com/v1/s2s-hb?' +
        new URLSearchParams({
          site_id,
          ip,
          format: 'json',
          adserver: 'gam',
          cb: Math.floor(Math.random()),
          ua: navigator.userAgent,
          pod_duration: 15,
          min_ad_duration: 5,
          max_ad_duration: 15,
          site_page: 'https://barstoolsports.com',
          site_domain: 'https://barstoolsports.com',
          position,
          content_length: story?.post_type_meta?.barstool_original?.duration ?? 0,
          livestream: story.type === 'live' ? 1 : 0,
          content_series: story.brand_name,
          content_prodqual: 1,
          content_language: 'en',
          content_title: videoSource?.name,
          content_context: 1,
          content_url: story.url
        })
    )
    const data = await response.json()

    const encodedPublicaParamsList = []

    data.forEach((item) => {
      Object.keys(item.prebid.targeting).forEach(function (key) {
        var value = item.prebid.targeting[key]
        encodedPublicaParamsList.push(`${key + appendString}=${encodeURIComponent(value)}`)
      })
    })

    return encodedPublicaParamsList.join('&')
  } catch (e) {
    console.error('Publica Response Error: ', e)
    return null
  }
}

export default getPublicaResponse
