import Script from 'next/script'

const LINKEDIN_SRC = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'

export function LinkedInScript() {
  function handleOnLoad() {
    window._linkedin_partner_id = '2407012'
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
    window._linkedin_data_partner_ids.push(window._linkedin_partner_id)
  }

  return <Script strategy='lazyOnload' src={LINKEDIN_SRC} onLoad={handleOnLoad} />
}
