import uniqBy from 'lodash/uniqBy'

export const collegeSports = [
  'Baseball',
  'Field Hockey',
  'Football',
  "Men's Archery",
  "Men's Badminton",
  "Men's Basketball",
  "Men's Bowling",
  "Men's Cheerleading",
  "Men's Crew",
  "Men's Cricket",
  "Men's Cross Country",
  "Men's Equestrian",
  "Men's Fencing",
  "Men's Golf",
  "Men's Gymnastics",
  "Men's Handball",
  "Men's Ice Hockey",
  "Men's Judo",
  "Men's Lacrosse",
  "Men's Lightweight Crew",
  "Men's Lightweight Football",
  "Men's Pistol",
  "Men's Polo",
  "Men's Rifle",
  "Men's Rodeo",
  "Men's Rugby",
  "Men's Sailing",
  "Men's Skiing",
  "Men's Slow Pitch Softball",
  "Men's Soccer",
  "Men's Squash",
  "Men's Swimming and Diving",
  "Men's Tennis",
  "Men's Track, Indoor",
  "Men's Track, Outdoor",
  "Men's Triathlon",
  "Men's Volleyball",
  "Men's Water Polo",
  "Men's Water Skiing",
  "Men's Wrestling",
  'Mixed Archery',
  'Mixed Badminton',
  'Mixed Bowling',
  'Mixed Cheerleading',
  'Mixed Crew',
  'Mixed Cricket',
  'Mixed Cross Country',
  'Mixed Equestrian',
  'Mixed Fencing',
  'Mixed Golf Mixed Gymnastics',
  'Mixed Handball',
  'Mixed Judo',
  'Mixed Lacrosse ',
  'Mixed Pistol',
  'Mixed Polo',
  'Mixed Rifle',
  'Mixed Sailing',
  'Mixed Skiing',
  'Mixed Slow Pitch Softball',
  'Mixed Squash',
  'Mixed Swimmming and Diving',
  'Mixed Tennis',
  'Mixed Track, Indoor',
  'Mixed Track, Outdoor',
  'Mixed Water Skiing',
  'Mixed Wrestling',
  'Mixed esports',
  'Softball',
  "Women's Acrobatics and Tumbling",
  "Women's Basketball",
  "Women's Beach Volleyball ",
  "Women's Bowling",
  "Women's Cheerleading",
  "Women's Cricket",
  "Women's Cross Country",
  "Women's Equestrian",
  "Women's Fencing",
  "Women's Golf",
  "Women's Gymnastics",
  "Women's Ice Hockey",
  "Women's Ice Skating",
  "Women's Judo",
  "Women's Lacrosse",
  "Women's Pistol",
  "Women's Rifle",
  "Women's Rowing",
  "Women's Rugby",
  "Women's Sailing",
  "Women's Skiing",
  "Women's Slow Pitch Softball",
  "Women's Soccer",
  "Women's Swimming and Diving",
  "Women's Sychronized Swimming",
  "Women's Tennis ",
  "Women's Track, Indoor ",
  "Women's Track, Outdoor",
  "Women's Triathlon",
  "Women's Volleyball",
  "Women's Water Polo",
  "Women's Water Skiing",
  "Women's Wrestling"
]

export const collegeSportsSelectOptions = collegeSports.map((sport) => ({ id: sport, value: sport, label: sport }))

export const collegeDivisions = ['Division 1', 'Division 2', 'Division 3']
export const collegeDivisionsSelectOptions = collegeDivisions.map((division) => ({
  id: division,
  value: division,
  label: division
}))

export const divisionOneSchools = [
  'Abilene Christian University',
  'Alabama A&M University',
  'Alabama State University',
  'Alcorn State University',
  'American University',
  'Appalachian State University',
  'Arizona State University',
  'Arkansas State University',
  'Auburn University',
  'Austin Peay State University',
  'Ball State University',
  'Baylor University',
  'Belmont University',
  'Bethune-Cookman University',
  'Binghamton University',
  'Boise State University',
  'Boston College',
  'Boston University',
  'Bowling Green State University',
  'Bradley University',
  'Brigham Young University',
  'Brown University',
  'Bryant University',
  'Bucknell University',
  'Butler University',
  'California Baptist University',
  'California Polytechnic State University',
  'California State University, Bakersfield',
  'California State University, Fresno',
  'California State University, Fullerton',
  'California State University, Northridge',
  'California State University, Sacramento',
  'Campbell University',
  'Canisius College',
  'Central Connecticut State University',
  'Central Michigan University',
  'Charleston Southern University',
  'Chicago State University',
  'Clemson University',
  'Cleveland State University',
  'Coastal Carolina University',
  'Colgate University',
  'College of Charleston (South Carolina)',
  'College of the Holy Cross',
  'Colorado State University',
  'Columbia University-Barnard College',
  'Coppin State University',
  'Cornell University',
  'Creighton University',
  'Dartmouth College',
  'Davidson College',
  'Delaware State University',
  'DePaul University',
  'Drake University',
  'Drexel University',
  'Duke University',
  'Duquesne University',
  'East Carolina University',
  'East Tennessee State University',
  'Eastern Illinois University',
  'Eastern Kentucky University',
  'Eastern Michigan University',
  'Eastern Washington University',
  'Elon University',
  'Fairfield University',
  'Fairleigh Dickinson University, Metropolitan Campus',
  'Florida A&M University',
  'Florida Atlantic University',
  'Florida Gulf Coast University',
  'Florida International University',
  'Florida State University',
  'Fordham University',
  'Furman University',
  'Gardner-Webb University',
  'George Mason University',
  'George Washington University',
  'Georgetown University',
  'Georgia Institute of Technology',
  'Georgia Southern University',
  'Georgia State University',
  'Gonzaga University',
  'Grambling State University',
  'Grand Canyon University',
  'Hampton University',
  'Harvard University',
  'High Point University',
  'Hofstra University',
  'Houston Baptist University',
  'Howard University',
  'Idaho State University',
  'Illinois State University',
  'Indiana State University',
  'Indiana University, Bloomington',
  'Indiana University-Purdue University Indianapolis',
  'Iona College',
  'Iowa State University',
  'Jackson State University',
  'Jacksonville State University',
  'Jacksonville University',
  'James Madison University',
  'Kansas State University',
  'Kennesaw State University',
  'Kent State University',
  'La Salle University',
  'Lafayette College',
  'Lamar University',
  'Lehigh University',
  'Liberty University',
  'Lipscomb University',
  'Long Beach State University',
  'Long Island University',
  'Longwood University',
  'Louisiana State University',
  'Louisiana Tech University',
  'Loyola Marymount University',
  'Loyola University Chicago',
  'Loyola University Maryland',
  'Manhattan College',
  'Marist College',
  'Marquette University',
  'Marshall University',
  'McNeese State University',
  'Mercer University',
  'Merrimack College',
  'Miami University (Ohio)',
  'Michigan State University',
  'Middle Tennessee State University',
  'Mississippi State University',
  'Mississippi Valley State University',
  'Missouri State University',
  'Monmouth University',
  'Montana State University-Bozeman',
  'Morehead State University',
  'Morgan State University',
  "Mount St. Mary's University",
  'Murray State University',
  'New Jersey Institute of Technology',
  'New Mexico State University',
  'Niagara University',
  'Nicholls State University',
  'Norfolk State University',
  'North Carolina A&T State University',
  'North Carolina Central University',
  'North Carolina State University',
  'North Dakota State University',
  'Northeastern University',
  'Northern Arizona University',
  'Northern Illinois University',
  'Northern Kentucky University',
  'Northwestern State University',
  'Northwestern University',
  'Oakland University',
  'Ohio University',
  'Oklahoma State University',
  'Old Dominion University',
  'Oral Roberts University',
  'Oregon State University',
  'Pennsylvania State University',
  'Pepperdine University',
  'Portland State University',
  'Prairie View A&M University',
  'Presbyterian College',
  'Princeton University',
  'Providence College',
  'Purdue University',
  'Purdue University Fort Wayne',
  'Quinnipiac University',
  'Radford University',
  'Rice University',
  'Rider University',
  'Robert Morris University',
  'Rutgers, The State University of New Jersey, New Brunswick',
  'Sacred Heart University',
  'Saint Francis University (Pennsylvania)',
  "Saint Joseph's University",
  'Saint Louis University',
  "Saint Mary's College of California",
  "Saint Peter's University",
  'Sam Houston State University',
  'Samford University',
  'San Diego State University',
  'San Jose State University',
  'Santa Clara University',
  'Seattle University',
  'Seton Hall University',
  'Siena College',
  'South Carolina State University',
  'South Dakota State University',
  'Southeast Missouri State University',
  'Southeastern Louisiana University',
  'Southern Illinois University at Carbondale',
  'Southern Illinois University Edwardsville',
  'Southern Methodist University',
  'Southern University, Baton Rouge',
  'Southern Utah University',
  'St. Bonaventure University',
  'St. Francis College Brooklyn',
  "St. John's University (New York)",
  'Stanford University',
  'Stephen F. Austin State University',
  'Stetson University',
  'Stony Brook University',
  'Syracuse University',
  'Temple University',
  'Tennessee State University',
  'Tennessee Technological University',
  'Texas A&M University, College Station',
  'Texas A&M University-Corpus Christi',
  'Texas Christian University',
  'Texas Southern University',
  'Texas State University',
  'Texas Tech University',
  'The Citadel',
  'The Ohio State University',
  'The University of North Carolina at Charlotte',
  'The University of North Carolina at Greensboro',
  'The University of Southern Mississippi',
  'The University of Texas Rio Grande Valley',
  'The University of Tulsa',
  'Towson University',
  'Troy University',
  'Tulane University',
  'U.S. Air Force Academy',
  'U.S. Military Academy',
  'U.S. Naval Academy',
  'University at Albany',
  'University at Buffalo, the State University of New York',
  'University of Akron',
  'University of Alabama',
  'University of Alabama at Birmingham',
  'University of Arizona',
  'University of Arkansas at Little Rock',
  'University of Arkansas, Fayetteville',
  'University of Arkansas, Pine Bluff',
  'University of California, Berkeley',
  'University of California, Davis',
  'University of California, Irvine',
  'University of California, Los Angeles',
  'University of California, Riverside',
  'University of California, Santa Barbara',
  'University of Central Arkansas',
  'University of Central Florida',
  'University of Cincinnati',
  'University of Colorado, Boulder',
  'University of Connecticut',
  'University of Dayton',
  'University of Delaware',
  'University of Denver',
  'University of Detroit Mercy',
  'University of Evansville',
  'University of Florida',
  'University of Georgia',
  'University of Hartford',
  'University of Hawaii, Manoa',
  'University of Houston',
  'University of Idaho',
  'University of Illinois at Chicago',
  'University of Illinois Urbana-Champaign',
  'University of Iowa',
  'University of Kansas',
  'University of Kentucky',
  'University of Louisiana at Lafayette',
  'University of Louisiana Monroe',
  'University of Louisville',
  'University of Maine',
  'University of Maryland Eastern Shore',
  'University of Maryland, Baltimore County',
  'University of Maryland, College Park',
  'University of Massachusetts Lowell',
  'University of Massachusetts, Amherst',
  'University of Memphis',
  'University of Miami (Florida)',
  'University of Michigan',
  'University of Minnesota, Twin Cities',
  'University of Mississippi',
  'University of Missouri, Columbia',
  'University of Missouri-Kansas City',
  'University of Montana',
  'University of Nebraska Omaha',
  'University of Nebraska, Lincoln',
  'University of Nevada, Las Vegas',
  'University of Nevada, Reno',
  'University of New Hampshire',
  'University of New Mexico',
  'University of New Orleans',
  'University of North Alabama',
  'University of North Carolina Asheville',
  'University of North Carolina Wilmington',
  'University of North Carolina, Chapel Hill',
  'University of North Dakota',
  'University of North Florida',
  'University of North Texas',
  'University of Northern Colorado',
  'University of Northern Iowa',
  'University of Notre Dame',
  'University of Oklahoma',
  'University of Oregon',
  'University of Pennsylvania',
  'University of Pittsburgh',
  'University of Portland',
  'University of Rhode Island',
  'University of Richmond',
  'University of San Diego',
  'University of San Francisco',
  'University of South Alabama',
  'University of South Carolina Upstate',
  'University of South Carolina, Columbia',
  'University of South Dakota',
  'University of South Florida',
  'University of Southern California',
  'University of Tennessee at Chattanooga',
  'University of Tennessee at Martin',
  'University of Tennessee, Knoxville',
  'University of Texas at Arlington',
  'University of Texas at Austin',
  'University of Texas at El Paso',
  'University of Texas at San Antonio',
  'University of the Incarnate Word',
  'University of the Pacific',
  'University of Toledo',
  'University of Utah',
  'University of Vermont',
  'University of Virginia',
  'University of Washington',
  'University of Wisconsin-Green Bay',
  'University of Wisconsin-Madison',
  'University of Wisconsin-Milwaukee',
  'University of Wyoming',
  'Utah State University',
  'Utah Valley University',
  'Valparaiso University',
  'Vanderbilt University',
  'Villanova University',
  'Virginia Commonwealth University',
  'Virginia Military Institute',
  'Virginia Polytechnic Institute and State University',
  'Wagner College',
  'Wake Forest University',
  'Washington State University',
  'Weber State University',
  'West Virginia University',
  'Western Carolina University',
  'Western Illinois University',
  'Western Kentucky University',
  'Western Michigan University',
  'Wichita State University',
  'William & Mary',
  'Winthrop University',
  'Wofford College',
  'Wright State University',
  'Xavier University',
  'Yale University',
  'Youngstown State University'
]

export const divisionOneSchoolSelectOptions = divisionOneSchools.map((school) => ({
  id: school,
  label: school,
  value: school
}))

export const divisionTwoSchools = [
  'Academy of Art University',
  'Adams State University',
  'Adelphi University',
  'Albany State University (Georgia)',
  'Alderson Broaddus University',
  'American International College',
  'Anderson University (South Carolina)',
  'Angelo State University',
  'Arkansas Tech University',
  'Ashland University',
  'Assumption College',
  'Auburn University at Montgomery',
  'Augusta University',
  'Augustana University (South Dakota)',
  'Azusa Pacific University',
  'Barry University',
  'Barton College',
  'Bellarmine University',
  'Belmont Abbey College',
  'Bemidji State University',
  'Benedict College',
  'Bentley University',
  'Biola University',
  'Black Hills State University',
  'Bloomfield College',
  'Bloomsburg University of Pennsylvania',
  'Bluefield State College',
  'Bowie State University',
  'Brevard College',
  'Caldwell University',
  'California State Polytechnic University, Pomona',
  'California State University, Chico',
  'California State University, Dominguez Hills',
  'California State University, East Bay',
  'California State University, Los Angeles',
  'California State University, Monterey Bay',
  'California State University, San Bernardino',
  'California State University, San Marcos',
  'California State University, Stanislaus',
  'California University of Pennsylvania',
  'Cameron University',
  'Carson-Newman University',
  'Catawba College',
  'Cedarville University',
  'Central State University',
  'Central Washington University',
  'Chadron State College',
  'Chaminade University',
  'Chestnut Hill College',
  'Chowan University',
  'Christian Brothers University',
  'Claflin University',
  'Clarion University of Pennsylvania',
  'Clark Atlanta University',
  'Clayton State University',
  'Coker University',
  'Colorado Christian University',
  'Colorado Mesa University',
  'Colorado School of Mines',
  'Colorado State University-Pueblo',
  'Columbus State University',
  'Concord University',
  'Concordia College (New York)',
  'Concordia University Irvine',
  'Concordia University, St. Paul',
  'Converse College',
  'Daemen College',
  'Dallas Baptist University',
  'Davenport University',
  'Davis & Elkins College',
  'Delta State University',
  'Dixie State University',
  'Dominican College (New York)',
  'Dominican University of California',
  'Drury University',
  'East Central University',
  'East Stroudsburg University of Pennsylvania',
  'Eastern New Mexico University',
  'Eckerd College',
  'Edinboro University of Pennsylvania',
  'Elizabeth City State University',
  'Embry-Riddle Aeronautical University (Florida)',
  'Emmanuel College (Georgia)',
  'Emporia State University',
  'Erskine College',
  'Fairmont State University',
  'Fayetteville State University',
  'Felician University',
  'Ferris State University',
  'Flagler College',
  'Florida Institute of Technology',
  'Florida Southern College',
  'Fort Hays State University',
  'Fort Lewis College',
  'Fort Valley State University',
  'Francis Marion University',
  'Franklin Pierce University',
  'Fresno Pacific University',
  'Frostburg State University',
  'Gannon University',
  'Georgia College',
  'Georgia Southwestern State University',
  'Georgian Court University',
  'Glenville State College',
  'Goldey-Beacom College',
  'Grand Valley State University',
  'Harding University',
  'Hawaii Pacific University',
  'Henderson State University',
  'Hillsdale College',
  'Holy Family University',
  'Holy Names University',
  'Humboldt State University',
  'Indiana University of Pennsylvania',
  'Johnson C. Smith University',
  'Kentucky State University',
  'Kentucky Wesleyan College',
  'King University',
  'Kutztown University of Pennsylvania',
  'Lake Erie College',
  'Lake Superior State University',
  'Lander University',
  'Lane College',
  'Le Moyne College',
  'Lee University',
  'Lees-McRae College',
  'LeMoyne-Owen College',
  'Lenoir-Rhyne University',
  'Lewis University',
  'Limestone College',
  'Lincoln Memorial University',
  'Lincoln University (Missouri)',
  'Lincoln University (Pennsylvania)',
  'Lindenwood University',
  'Livingstone College',
  'Lock Haven University of Pennsylvania',
  'Lubbock Christian University',
  'Lynn University',
  'Malone University',
  'Mansfield University of Pennsylvania',
  'Mars Hill University',
  'Maryville University of Saint Louis',
  'McKendree University',
  'Mercy College',
  'Mercyhurst University',
  'Metropolitan State University of Denver',
  'Michigan Technological University',
  'Midwestern State University',
  'Miles College',
  'Millersville University of Pennsylvania',
  'Minnesota State University Moorhead',
  'Minnesota State University, Mankato',
  'Minot State University',
  'Mississippi College',
  'Missouri Southern State University',
  'Missouri University of Science and Technology',
  'Missouri Western State University',
  'Molloy College',
  'Montana State University Billings',
  'Morehouse College',
  'New Mexico Highlands University',
  'New York Institute of Technology',
  'Newberry College',
  'Newman University',
  'North Greenville University',
  'Northeastern State University',
  'Northern Michigan University',
  'Northern State University',
  'Northwest Missouri State University',
  'Northwest Nazarene University',
  'Northwestern Oklahoma State University',
  'Northwood University',
  'Notre Dame College (Ohio)',
  'Nova Southeastern University',
  'Nyack College',
  'Oakland City University',
  'Ohio Dominican University',
  'Ohio Valley University',
  'Oklahoma Baptist University',
  'Oklahoma Christian University',
  'Ouachita Baptist University',
  'Pace University',
  'Paine College',
  'Palm Beach Atlantic University',
  'Pittsburg State University',
  'Point Loma Nazarene University',
  'Post University',
  'Purdue University Northwest',
  'Queens College (New York)',
  'Queens University of Charlotte',
  'Quincy University',
  'Regis University (Colorado)',
  'Roberts Wesleyan College',
  'Rockhurst University',
  'Rogers State University',
  'Rollins College',
  'Saginaw Valley State University',
  'Saint Anselm College',
  "Saint Augustine's University",
  'Saint Leo University',
  "Saint Martin's University",
  "Saint Michael's College",
  'Salem University (West Virginia)',
  'San Francisco State University',
  'Savannah State University',
  'Seattle Pacific University',
  'Seton Hill University',
  'Shaw University',
  'Shepherd University',
  'Shippensburg University of Pennsylvania',
  'Shorter University',
  'Simon Fraser University',
  'Slippery Rock University of Pennsylvania',
  'Sonoma State University',
  'South Dakota School of Mines & Technology',
  'Southeastern Oklahoma State University',
  'Southern Arkansas University',
  'Southern Connecticut State University',
  'Southern Nazarene University',
  'Southern New Hampshire University',
  'Southern Wesleyan University',
  'Southwest Baptist University',
  'Southwest Minnesota State University',
  'Southwestern Oklahoma State University',
  'Spring Hill College',
  'St. Cloud State University',
  "St. Edward's University",
  "St. Mary's University (Texas)",
  'St. Thomas Aquinas College',
  'Stonehill College',
  'Tarleton State University',
  'Texas A&M International University',
  'Texas A&M University-Commerce',
  'Texas A&M University-Kingsville',
  "Texas Woman's University",
  'The College of Saint Rose',
  'The University of Texas at Tyler',
  "The University of Virginia's College at Wise",
  'Thomas Jefferson University',
  'Tiffin University',
  'Trevecca Nazarene University',
  'Truman State University',
  'Tusculum University',
  'Tuskegee University',
  'Union University',
  'University of Alabama in Huntsville',
  'University of Alaska Anchorage',
  'University of Alaska Fairbanks',
  'University of Arkansas, Fort Smith',
  'University of Arkansas, Monticello',
  'University of Bridgeport',
  'University of California, San Diego',
  'University of Central Missouri',
  'University of Central Oklahoma',
  'University of Charleston (West Virginia)',
  'University of Colorado, Colorado Springs',
  'University of Findlay',
  'University of Hawaii at Hilo',
  'University of Illinois at Springfield',
  'University of Indianapolis',
  'University of Mary',
  'University of Minnesota Duluth',
  'University of Minnesota, Crookston',
  'University of Missouri-St. Louis',
  'University of Montevallo',
  'University of Mount Olive',
  'University of Nebraska at Kearney',
  'University of New Haven',
  'University of North Carolina at Pembroke',
  'University of North Georgia',
  'University of Pittsburgh, Johnstown',
  'University of Puerto Rico, Bayamon',
  'University of Puerto Rico, Mayaguez',
  'University of Puerto Rico, Rio Piedras',
  'University of Sioux Falls',
  'University of South Carolina Aiken',
  'University of Southern Indiana',
  'University of Tampa',
  'University of Texas of the Permian Basin',
  'University of the District of Columbia',
  'University of the Sciences',
  'University of West Alabama',
  'University of West Florida',
  'University of West Georgia',
  'University of Wisconsin-Parkside',
  'Upper Iowa University',
  'Urbana University',
  'Ursuline College',
  'Valdosta State University',
  'Virginia State University',
  'Virginia Union University',
  'Walsh University',
  'Washburn University of Topeka',
  'Wayne State College (Nebraska)',
  'Wayne State University (Michigan)',
  'West Chester University of Pennsylvania',
  'West Liberty University',
  'West Texas A&M University',
  'West Virginia State University',
  'West Virginia Wesleyan College',
  'Western Colorado University',
  'Western New Mexico University',
  'Western Oregon University',
  'Western Washington University',
  'Westminster College (Utah)',
  'Wheeling University',
  'William Jewell College',
  'Wilmington University (Delaware)',
  'Wingate University',
  'Winona State University',
  'Winston-Salem State University',
  'Young Harris College'
]

export const divisionTwoSchoolSelectOptions = divisionTwoSchools.map((school) => ({
  id: school,
  label: school,
  value: school
}))

const divisionThreeSchools = [
  'Adrian College',
  'Agnes Scott College',
  'Albertus Magnus College',
  'Albion College',
  'Albright College',
  'Alfred State College',
  'Alfred University',
  'Allegheny College',
  'Alma College',
  'Alvernia University',
  'Alverno College',
  'Amherst College',
  'Anderson University (Indiana)',
  'Anna Maria College',
  'Arcadia University',
  'Augsburg University',
  'Augustana College (Illinois)',
  'Aurora University',
  'Austin College',
  'Averett University',
  'Babson College',
  'Baldwin Wallace University',
  'Bard College',
  'Baruch College',
  'Bates College',
  'Bay Path University',
  'Becker College',
  'Belhaven University',
  'Beloit College',
  'Benedictine University (Illinois)',
  'Berea College',
  'Berry College',
  'Bethany College (West Virginia)',
  'Bethany Lutheran College',
  'Bethel University (Minnesota)',
  'Birmingham-Southern College',
  'Blackburn College',
  'Bluffton University',
  'Bowdoin College',
  'Brandeis University',
  'Brevard College',
  'Bridgewater College (Virginia)',
  'Bridgewater State University',
  'Brooklyn College',
  'Bryn Athyn College',
  'Bryn Mawr College',
  'Buena Vista University',
  'Buffalo State, State University of New York',
  'Cabrini University',
  'Cairn University',
  'California Institute of Technology',
  'California Lutheran University',
  'Calvin University',
  'Capital University',
  'Carleton College',
  'Carnegie Mellon University',
  'Carroll University (Wisconsin)',
  'Carthage College',
  'Case Western Reserve University',
  'Castleton University',
  'Catholic University',
  'Cazenovia College',
  'Cedar Crest College',
  'Centenary College (Louisiana)',
  'Centenary University (New Jersey)',
  'Central College (Iowa)',
  'Centre College',
  'Chapman University',
  'Chatham University',
  'Christopher Newport University',
  'Claremont McKenna-Harvey Mudd-Scripps Colleges',
  'Clark University (Massachusetts)',
  'Clarks Summit University',
  'Clarkson University',
  'Coe College',
  'Colby College',
  'Colby-Sawyer College',
  'College at Brockport, State University of New York',
  'College of Mount Saint Vincent',
  'College of Saint Benedict',
  'College of Saint Elizabeth',
  'Colorado College',
  'Concordia College, Moorhead',
  'Concordia University Chicago',
  'Concordia University Texas',
  'Concordia University Wisconsin',
  'Connecticut College',
  'Cornell College',
  'Covenant College',
  'Crown College (Minnesota)',
  'Curry College',
  'Dean College',
  'Defiance College',
  'Delaware Valley University',
  'Denison University',
  'DePauw University',
  'DeSales University',
  'Dickinson College',
  'Dominican University (Illinois)',
  'Drew University',
  "D'Youville College",
  'Earlham College',
  'East Texas Baptist University',
  'Eastern Connecticut State University',
  'Eastern Mennonite University',
  'Eastern Nazarene College',
  'Eastern University',
  'Edgewood College',
  'Elizabethtown College',
  'Elmhurst College',
  'Elmira College',
  'Elms College',
  'Emerson College',
  'Emmanuel College (Massachusetts)',
  'Emory & Henry College',
  'Emory and Henry College',
  'Emory University',
  'Endicott College',
  'Eureka College',
  'Fairleigh Dickinson University, Florham',
  'Farmingdale State College',
  'Ferrum College',
  'Finlandia University',
  'Fitchburg State University',
  'Fontbonne University',
  'Framingham State University',
  'Franciscan University of Steubenville',
  'Franklin & Marshall College',
  'Franklin College',
  'Gallaudet University',
  'Geneva College',
  'George Fox University',
  'Gettysburg College',
  'Gordon College',
  'Goucher College',
  'Greensboro College',
  'Greenville University',
  'Grinnell College',
  'Grove City College',
  'Guilford College',
  'Gustavus Adolphus College',
  'Gwynedd Mercy University',
  'Hamilton College',
  'Hamline University',
  'Hampden-Sydney College',
  'Hanover College',
  'Hardin-Simmons University',
  'Hartwick College',
  'Haverford College',
  'Heidelberg University',
  'Hendrix College',
  'Hilbert College',
  'Hiram College',
  'Hobart and William Smith Colleges',
  'Hollins University',
  'Hood College',
  'Hope College',
  'Houghton College',
  'Howard Payne University',
  'Hunter College',
  'Huntingdon College',
  'Husson University',
  'Illinois College',
  'Illinois Institute of Technology',
  'Illinois Wesleyan University',
  'Immaculata University',
  'Iowa Wesleyan University',
  'Ithaca College',
  'John Carroll University',
  'John Jay College of Criminal Justice',
  'Johns Hopkins University',
  'Johnson & Wales University (Denver)',
  'Johnson & Wales University (Providence)',
  'Juniata College',
  'Kalamazoo College',
  'Kean University',
  'Keene State College',
  'Kenyon College',
  'Keuka College',
  'Keystone College',
  "King's College (Pennsylvania)",
  'Knox College',
  'La Roche University',
  'LaGrange College',
  'Lake Forest College',
  'Lakeland University',
  'Lancaster Bible College',
  'Lasell University',
  'Lawrence University',
  'Lebanon Valley College',
  'Lehman College',
  'Lesley University',
  'LeTourneau University',
  'Lewis & Clark College',
  'Linfield College',
  'Loras College',
  'Louisiana College',
  'Luther College',
  'Lycoming College',
  'Macalester College',
  'Maine Maritime Academy',
  'Manchester University',
  'Manhattanville College',
  'Maranatha Baptist University',
  'Marian University (Wisconsin)',
  'Marietta College',
  'Martin Luther College',
  'Mary Baldwin University',
  'Marymount University (Virginia)',
  'Maryville College (Tennessee)',
  'Marywood University',
  'Massachusetts College of Liberal Arts',
  'Massachusetts Institute of Technology',
  'Massachusetts Maritime Academy',
  'McDaniel College',
  'McMurry University',
  'Medaille College',
  'Medgar Evers College',
  'Meredith College',
  'Messiah College',
  'Methodist University',
  'Middlebury College',
  'Millikin University',
  'Mills College',
  'Millsaps College',
  'Milwaukee School of Engineering',
  'Misericordia University',
  'Mississippi University for Women',
  'Mitchell College',
  'Monmouth College (Illinois)',
  'Montclair State University',
  'Moravian College',
  'Morrisville State College',
  'Mount Aloysius College',
  'Mount Holyoke College',
  'Mount Mary University',
  'Mount Saint Mary College (New York)',
  'Mount St. Joseph University',
  'Muhlenberg College',
  'Muskingum University',
  'Nazareth College',
  'Nebraska Wesleyan University',
  'Neumann University',
  'New England College',
  'New Jersey City University',
  'New York University',
  'Nichols College',
  'North Carolina Wesleyan College',
  'North Central College',
  'North Central University',
  'North Park University',
  'Northern Vermont University-Johnson',
  'Northern Vermont University-Lyndon',
  'Northland College',
  'Norwich University',
  'Notre Dame of Maryland University',
  'Oberlin College',
  'Occidental College',
  'Oglethorpe University',
  'Ohio Northern University',
  'Ohio Wesleyan University',
  'Olivet College',
  'Otterbein University',
  'Pacific Lutheran University',
  'Pacific University (Oregon)',
  'Penn State Berks College',
  'Penn State Harrisburg',
  'Penn State University, Abington',
  'Penn State University, Altoona',
  'Pennsylvania College of Technology',
  'Pennsylvania State Univ. Erie, the Behrend College',
  'Pfeiffer University',
  'Piedmont College',
  'Pine Manor College',
  'Plattsburgh State University of New York',
  'Plymouth State University',
  'Pomona-Pitzer Colleges',
  'Pratt Institute',
  'Principia College',
  'Purchase College, State University of New York',
  'Ramapo College',
  'Randolph College',
  'Randolph-Macon College',
  'Regis College (Massachusetts)',
  'Rensselaer Polytechnic Institute',
  'Rhode Island College',
  'Rhodes College',
  'Ripon College',
  'Rivier University',
  'Roanoke College',
  'Rochester Institute of Technology',
  'Rockford University',
  'Roger Williams University',
  'Rose-Hulman Institute of Technology',
  'Rosemont College',
  'Rowan University',
  'Rutgers, The State Univ. of New Jersey, Camden',
  'Rutgers, The State Univ. of New Jersey, Newark',
  "Saint John's University (Minnesota)",
  "Saint Joseph's College (Maine)",
  "Saint Mary's College (Indiana)",
  "Saint Mary's University of Minnesota",
  'Saint Vincent College',
  'Salem College (North Carolina)',
  'Salem State University',
  'Salisbury University',
  'Salve Regina University',
  'Sarah Lawrence College',
  'Schreiner University',
  'Shenandoah University',
  'Simmons University',
  'Simpson College',
  'Skidmore College',
  'Smith College',
  'Southern Virginia University',
  'Southwestern University (Texas)',
  'Spalding University',
  'Springfield College',
  'St. Catherine University',
  'St. John Fisher College',
  "St. Joseph's College (Brooklyn)",
  "St. Joseph's College (Long Island)",
  'St. Lawrence University',
  "St. Mary's College of Maryland",
  'St. Norbert College',
  'St. Olaf College',
  'State University College at Old Westbury',
  'State University of New York at Canton',
  'State University of New York at Cobleskill',
  'State University of New York at Cortland',
  'State University of New York at Delhi',
  'State University of New York at Geneseo',
  'State University of New York at New Paltz',
  'State University of New York at Oneonta',
  'State University of New York at Oswego',
  'State University of New York at Potsdam',
  'State University of New York Maritime College',
  'State University of New York Polytechnic Institute',
  'Stevens Institute of Technology',
  'Stevenson University',
  'Stockton University',
  'Suffolk University',
  'Sul Ross State University',
  'Susquehanna University',
  'Swarthmore College',
  'Sweet Briar College',
  'Texas Lutheran University',
  'The City College of New York',
  'The College of New Jersey',
  'The College of St. Scholastica',
  'The College of Wooster',
  'The Sage Colleges',
  'The State University of New York at Fredonia',
  'Thiel College',
  'Thomas College',
  'Transylvania University',
  'Trine University',
  'Trinity College (Connecticut)',
  'Trinity University (Texas)',
  'Trinity Washington University',
  'Tufts University',
  'U.S. Coast Guard Academy',
  'U.S. Merchant Marine Academy',
  'Union College (New York)',
  'University of California, Santa Cruz',
  'University of Chicago',
  'University of Dallas',
  'University of Dubuque',
  'University of La Verne',
  'University of Lynchburg',
  'University of Maine at Presque Isle',
  'University of Maine, Farmington',
  'University of Mary Hardin-Baylor',
  'University of Mary Washington',
  'University of Massachusetts Boston',
  'University of Massachusetts, Dartmouth',
  'University of Minnesota, Morris',
  'University of Mount Union',
  'University of New England',
  'University of Northwestern-St. Paul',
  'University of Pittsburgh, Bradford',
  'University of Pittsburgh, Greensburg',
  'University of Puget Sound',
  'University of Redlands',
  'University of Rochester',
  'University of Scranton',
  'University of Southern Maine',
  'University of St. Joseph (Connecticut)',
  'University of St. Thomas (Minnesota)',
  'University of St. Thomas (Texas)',
  'University of Texas at Dallas',
  'University of the Ozarks (Arkansas)',
  'University of the South',
  'University of Valley Forge',
  'University of Wisconsin-Eau Claire',
  'University of Wisconsin-La Crosse',
  'University of Wisconsin-Oshkosh',
  'University of Wisconsin-Platteville',
  'University of Wisconsin-River Falls',
  'University of Wisconsin-Stevens Point',
  'University of Wisconsin-Stout',
  'University of Wisconsin-Superior',
  'University of Wisconsin-Whitewater',
  'Ursinus College',
  'Utica College',
  'Vassar College',
  'Virginia Wesleyan University',
  'Wabash College',
  'Wartburg College',
  'Washington and Jefferson College',
  'Washington and Lee University',
  'Washington College (Maryland)',
  'Washington University in St. Louis',
  'Waynesburg University',
  'Webster University',
  'Wellesley College',
  'Wells College',
  'Wentworth Institute of Technology',
  'Wesley College',
  'Wesleyan College (Georgia)',
  'Wesleyan University (Connecticut)',
  'Western Connecticut State University',
  'Western New England University',
  'Westfield State University',
  'Westminster College (Missouri)',
  'Westminster College (Pennsylvania)',
  'Wheaton College (Illinois)',
  'Wheaton College (Massachusetts)',
  'Whitman College',
  'Whittier College',
  'Whitworth University',
  'Widener University',
  'Wilkes University',
  'Willamette University',
  'William Paterson University of New Jersey',
  'William Peace University',
  'Williams College',
  'Wilmington College (Ohio)',
  'Wilson College',
  'Wisconsin Lutheran College',
  'Wittenberg University',
  'Worcester Polytechnic Institute',
  'Worcester State University',
  'Yeshiva University',
  'York College (New York)',
  'York College (Pennsylvania)'
]

export const divisionThreeSchoolSelectOptions = divisionThreeSchools.map((school) => ({
  id: school,
  label: school,
  value: school
}))

export const allCollegeOptions = uniqBy(
  [...divisionOneSchoolSelectOptions, ...divisionTwoSchoolSelectOptions, ...divisionThreeSchoolSelectOptions],
  'value'
)
