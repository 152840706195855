export function TaboolaScript({ type }: { type: string }) {
  return (
    <script
      id='taboola_script'
      type='text/javascript'
      dangerouslySetInnerHTML={{
        __html: `
          window._taboola = window._taboola || [];
          _taboola.push({${type}:'auto'});
          !function (e, f, u, i) {
              if (!document.getElementById(i)){
              e.async = 1;
              e.src = u;
              e.id = i;
              f.parentNode.insertBefore(e, f);
            }
          }(document.createElement('script'),
          document.getElementsByTagName('script')[0],
          '//cdn.taboola.com/libtrc/barstool-network/loader.js',
          'tb_loader_script');
          if(window.performance && typeof window.performance.mark == 'function')
            {window.performance.mark('tbl_ic');}
      `
      }}
    />
  )
}
