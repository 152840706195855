import { observable, flow, toJS } from 'mobx'
import { v4 as uuid } from 'uuid'
import { storiesApi, feedSubscriptionsApi } from 'api'
import { CommonStore } from 'stores/common'

export const StoriesStore = () => {
  const findNavStories = flow(function* () {
    const [navBlogs, navVideos, navPodcasts, navGalleries] = yield Promise.all([
      this.api.find({ type: 'standard_post', limit: 4, exclude_category: '420' }),
      this.api.find({ type: 'barstool_original', limit: 4 }),
      this.api.find({ type: 'podcast', limit: 4 }),
      this.api.find({ type: 'gallery', limit: 4 })
    ])
    this.navBlogs = navBlogs
    this.navVideos = navVideos
    this.navPodcasts = navPodcasts
    this.navGalleries = navGalleries
    return { navBlogs, navVideos, navPodcasts, navGalleries }
  })
  const findTopStories = flow(function* () {
    const [topBlogs, topVideos, topPodcasts] = yield Promise.all([
      this.api.findTrending({ type: 'standard_post', limit: 3 }),
      this.api.findTrending({ type: 'barstool_original', limit: 3 }),
      this.api.findTrending({ type: 'podcast', limit: 3 })
    ])
    this.topBlogs = topBlogs
    this.topVideos = topVideos
    this.topPodcasts = topPodcasts
    return { topBlogs, topVideos, topPodcasts }
  })
  const findFavorites = flow(function* (userId, params) {
    try {
      this.isLoading = true
      const url = yield feedSubscriptionsApi.getStoryFeedUrl(userId)
      const items = yield this.api.findFavorites(url.path, {
        authors: url.query.authors || undefined,
        brands: url.query.brands || undefined,
        ...params
      })
      this.items = items
      return items
    } finally {
      this.isLoading = false
    }
  })
  const findNextFavorites = flow(function* (userId, params) {
    try {
      this.isLoading = true
      const url = yield feedSubscriptionsApi.getStoryFeedUrl(userId)
      const items = yield this.api.findFavorites(url.path, {
        authors: url.query.authors || undefined,
        brands: url.query.brands || undefined,
        ...params
      })
      this.items.push(...items)
      return toJS(this.items)
    } finally {
      this.isLoading = false
    }
  })
  const findFeatured = flow(function* (params) {
    const response = yield this.api.find({ featured_section: true, ...params })
    this.featuredItems = response
    return this.featuredItems
  })
  const findRandomStory = flow(function* (params = {}) {
    const response = yield this.api.random(params)
    this.randomStory = response
    return this.randomStory
  })
  const findTrending = flow(function* (params = {}) {
    const response = yield this.api.findTrending(params)
    this.trendingItems = response
    return response
  })
  const findVideoSource = flow(function* (storyId) {
    const response = yield this.api.findVideoSource(storyId)
    this.currentVideoSource = response
    return response
  })
  const findStickyVideoSource = flow(function* (storyId) {
    const response = yield this.api.findVideoSource(storyId)
    this.stickyVideoSource = response
    return response
  })
  const findLive = flow(function* () {
    const response = yield this.api.findLive()
    this.liveItems = response
    return response
  })
  const findCategories = flow(function* (storyId) {
    const response = yield this.api.findCategories(storyId)
    this.categories = response
    return response
  })
  const findViewerCount = flow(function* (storyId) {
    const response = yield this.api.findViewerCount(storyId)
    this.viewerCount = response
    return response
  })
  const postViewerCount = flow(function* (storyId, clientId) {
    const response = yield this.api.postViewerCount(storyId, { clientId })
    this.viewerCount = response
    return response
  })
  const checkGeofencing = flow(function* (storyId) {
    const response = yield this.api.findVideoSource(storyId)
    if (this.currentVideoSource?.id !== response?.id) {
      this.currentVideoSource = response
    }
    return response
  })
  const findByTag = flow(function* (tag, params) {
    const response = yield this.api.findByTag(tag, params)
    this.items = response
    return response
  })
  const findNextByTag = flow(function* (tag, params) {
    const response = yield this.api.findByTag(tag, params)
    this.items.push(...response)
    return response
  })
  const findVideoList = flow(function* (params) {
    let options = {
      type: 'barstool_original',
      limit: 25,
      ...params
    }
    if (params.brand) options.brand = params.brand
    const response = yield this.api.find(options)
    this.videoList = [...this.videoList, ...response]
    return response
  })
  const findPreviewById = flow(function* (id) {
    try {
      this.isLoading = true
      const current = yield this.api.findPreviewById(id)
      this.current = current
      return current
    } finally {
      this.isLoading = false
    }
  })
  const findBySlug = flow(function* (slug) {
    try {
      this.isLoading = true
      const current = yield this.api.findBySlug(slug)
      this.current = current
      return current
    } finally {
      this.isLoading = false
    }
  })
  const search = flow(function* ({ query, type, author, page, limit }) {
    try {
      this.isLoading = true
      const current = yield this.api.search({ query, type, author, page, limit })
      this.current = current
      return current
    } finally {
      this.isLoading = false
    }
  })
  const updateViewerCount = function (count) {
    this.viewerCount = count
  }
  const setConsecutiveViewCount = function (count) {
    this.consecutiveViewCount = count
  }
  const startConsecutiveViewingSession = function () {
    this.consecutiveViewingSession = uuid()
  }

  return observable({
    ...CommonStore,
    search,
    api: storiesApi,
    findNavStories,
    findTopStories,
    findFavorites,
    findNextFavorites,
    findFeatured,
    findRandomStory,
    findTrending,
    findVideoSource,
    findStickyVideoSource,
    findLive,
    findCategories,
    findViewerCount,
    postViewerCount,
    updateViewerCount,
    setConsecutiveViewCount,
    startConsecutiveViewingSession,
    findByTag,
    findNextByTag,
    findVideoList,
    findPreviewById,
    findBySlug,
    checkGeofencing,
    topBlogs: [],
    topVideos: [],
    topPodcasts: [],
    navBlogs: [],
    navVideos: [],
    navPodcasts: [],
    navGalleries: [],
    featuredItems: [],
    trendingItems: [],
    videoList: [],
    randomStory: null,
    currentVideoSource: null,
    stickyVideoSource: null,
    liveItems: [],
    viewerCount: 1,
    consecutiveViewCount: 0,
    consecutiveViewingSession: null
  })
}

StoriesStore.cacheKey = 'StoriesStore'

export default StoriesStore
