import { useEffect } from 'react'

// See useGlobalInterval
let globalIntervalCache = {}

/**
 *
 * @param {Function} callback
 * @param {number} delay
 */
export const useInterval = (callback, delay, deps = []) => {
  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(callback, delay)
      return () => clearInterval(id)
    }
  }, [delay, ...deps])
}

/**
 * Executes handler every `delay` milliseconds
 * and globally prevents callback from being called
 * more than `delay` seconds
 *
 * @param {Hashable} key
 * @param {Function} callback
 * @param {number} delay
 */
export const useGlobalInterval = (key, callback, delay) => {
  useInterval(() => {
    callback()
  }, delay)

  useEffect(() => {
    if (globalIntervalCache[key] > Date.now() - delay) {
      return
    }
    globalIntervalCache[key] = Date.now()
    callback()
  }, [])
}
