import { CrmResource } from 'api/resource'

class KlaviyoApi extends CrmResource {
  constructor() {
    super('klaviyo')
  }
  async findById(id, params = {}) {
    const data = await this.get({ url: `/${this.resource}/profile/${id}`, params })
    return data
  }
  async update(id, options) {
    const data = await this.put({
      url: `${this.resource}/profile/${id}`,
      data: options
    })
    return data
  }
  async unsubscribe(id) {
    const data = await this.delete({ url: `${this.resource}/profile/${id}` })
    return data
  }
  async findNewsletters() {
    const data = await this.get({ url: `/${this.resource}/config` })
    return data
  }
}

export default KlaviyoApi
