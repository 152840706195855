import React from 'react'
import { observer } from 'mobx-react-lite'
import capitalize from 'lodash/capitalize'
import SmartLink from 'components/SmartLink'
import { siteHeaderEvents } from 'lib/ga'
import { THEME } from 'utils'
import Thumbnail from 'components/Thumbnail'
import { track } from 'lib/whoami'

const SubnavList = ({ type, list }) => {
  if (!type) return null
  const linkStyle = {
    display: 'block',
    color: '#222',
    fontSize: '14px',
    paddingTop: '8px',
    paddingBottom: '8px'
  }

  const handleSubNavListLinkCDPEvent = (item) => {
    if (!item) return
    track('ContentClicked', {
      content: {
        src: `https://barstoolsports.com${item?.slug}`,
        type: 'Link',
        componentName: 'SubnavList'
      }
    })
  }

  return (
    <>
      <div className='dropdownMenu'>
        <div className='container'>
          <div className='row'>
            {type.image.enabled && (
              <div className='col col--image'>
                {type.image && (
                  <SmartLink
                    item={type.image}
                    style={linkStyle}
                    onClick={() => {
                      siteHeaderEvents.navLink(
                        `${type.image.title} - ${capitalize(list)} Promotion Image`,
                        type.image.external
                      )
                      handleSubNavListLinkCDPEvent(item)
                    }}
                  >
                    <Thumbnail src={type.image.imageUrl} alt={type.image.title} />
                  </SmartLink>
                )}
              </div>
            )}
            {type.sections.map((section, idx) => {
              return (
                <div className='col col--section' key={idx}>
                  <h4 className='sectionTitle'>{section.title}</h4>
                  <ul className='sectionDetailBox'>
                    {section.items.map((item, idx) => {
                      return (
                        <li className='sectionDetailItem' key={idx}>
                          <SmartLink
                            item={item}
                            style={linkStyle}
                            onClick={() => {
                              siteHeaderEvents.navLink(
                                `${list.toUpperCase()} - ${section.title.toUpperCase()} - ${item.title}`,
                                item.external
                              )
                              handleSubNavListLinkCDPEvent(item)
                            }}
                          >
                            {item.title}
                          </SmartLink>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
          padding: 0 2rem;
        }
        .col--image {
          min-width: 100%;
          flex-basis: 100%;
          margin-bottom: 15px;
          order: 1;
        }
        .col--section {
          min-width: 100%;
          flex-basis: 50%;
          margin-bottom: 15px;
          order: 0;
        }
        .sectionDetailBox {
          border-bottom: 0.5px solid #ddd;
          width: 100%;
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;
          padding: 0 0 1rem 0;
        }
        .sectionDetailItem {
          display: inline-block;
          width: 50%;
        }
        .sectionTitle {
          text-transform: uppercase;
        }
        img {
          display: block;
          width: 100%;
        }
        h4 {
          margin: 0 0 5px;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        ul li a {
          display: block;
          color: ${THEME.COLORS.TEXTCOLOR};
          font-size: 14px;
          padding-top: 8px;
          padding-bottom: 8px;
        }
        .dropdownMenu {
          width: 100%;
          position: absolute;
          z-index: 15;
          top: 100%;
          left: 0;
          background: #f1f1f1;
          padding: 20px 0;
          border-top: 1px solid ${THEME.COLORS.LIGHTERGRAY};
          border-bottom: 1px solid ${THEME.COLORS.LIGHTERGRAY};
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .sectionDetailBox {
            border: none;
            padding: 0;
            margin: 0;
          }
          .sectionDetailItem {
            display: block;
            width: 100%;
          }
          .col--image {
            min-width: 40%;
            flex-basis: 40%;
            margin-bottom: 0;
            order: 0;
          }
          .col--section {
            min-width: 20%;
            flex-basis: 20%;
            margin-bottom: 0;
            order: 1;
          }
        }
      `}</style>
    </>
  )
}

export default observer(SubnavList)
