import ExternalScriptLoader from 'lib/external-script-loader'

class IMA extends ExternalScriptLoader {
  scriptUrl = 'https://imasdk.googleapis.com/js/sdkloader/ima3.js'
  readyStates = ['complete', 'interactive']

  disabled() {
    return window.google === undefined
  }
}

export default new IMA()
