import { useRouter } from 'next/router'

export const usePageUrl = () => {
  const router = useRouter()
  const { pathname, query } = router
  let fullDomain = 'https://www.barstoolsports.com'

  let dynamicPathname = pathname
  Object.entries(query).forEach(([key, value]) => {
    dynamicPathname = dynamicPathname.replace(`[${key}]`, value)
  })

  return {
    path: `${fullDomain}${dynamicPathname}`,
    asPath: router.asPath,
    id: router.query?.id,
    pathname: router.pathname
  }
}
