import uniq from 'lodash/uniq'

const colorCodes = Object.freeze({
  Black: '#000',
  Grey: '#bababa',
  Blue: '#0894fd',
  White: '#ffffff',
  'Light Blue': '#b3cfdd'
})
export const cleanProductData = (data) => {
  const mapNode = (arr) => {
    return arr.map((i) => {
      return i.node
    })
  }
  const getColors = (variants) => {
    const colors = variants.map((variant) => {
      return colorCodes[variant.selectedOptions.find((o) => o.name === 'Color')?.value]
    })
    return uniq(colors)
  }
  return data?.edges?.map((i) => {
    const variants = mapNode(i.node.variants.edges)
    const images = mapNode(i.node.images.edges)
    const price = i.node.price || i.node.variants.edges[0].node.price
    return {
      id: i.node.id,
      title: i.node.title,
      price: price?.amount ?? price,
      tags: i.node.tags,
      handle: i.node.handle,
      variants,
      images,
      colors: getColors(variants)
    }
  })
}
