import { useMediaQuery } from 'react-responsive'
import { isServer, THEME } from 'utils'

const MediaQuerySSR = ({ children, ...props }) => {
  const mediaOptions = isServer ? { width: THEME.BREAKPOINTS.XL } : undefined
  const shouldRender = useMediaQuery(props, mediaOptions)
  return shouldRender ? children : null
}

export default MediaQuerySSR
