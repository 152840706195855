import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import MediaQuery from 'components/MediaQuery'
import { THEME } from 'utils'
import { useStore } from 'hooks'
import { AuthStore } from 'stores/auth'
import { NavigationStore } from 'stores/navigation'
import SiteNavigation from 'components/SiteNavigation'
import MyFeedIcon from 'icons/MyFeed'
import PhoneIcon from 'icons/Phone'
import AvatarIcon from 'icons/Avatar'
import SearchIcon from 'icons/Search'
import { siteHeaderEvents } from 'lib/ga'
import ClientOnly from 'components/ClientOnly'
import Image from 'next/image'
import { track } from 'lib/whoami'

const SiteHeader = () => {
  const authStore = useStore(AuthStore)
  const navigationStore = useStore(NavigationStore)

  const handleSiteHeaderLinkCDPEvent = (href) => {
    track('ContentClicked', {
      content: {
        src: `https://barstoolsports.com${href}`,
        type: 'Link',
        componentName: 'SiteHeader'
      }
    })
  }

  return (
    <header className='siteHeader'>
      <div className='container'>
        <div className='siteHeader__logos'>
          {navigationStore.stickyVideoInteraction ? (
            <Link href='/' legacyBehavior>
              <a
                className='siteHeader__logo siteHeader__logo--sports'
                onClick={() => {
                  siteHeaderEvents.logo()
                  handleSiteHeaderLinkCDPEvent('/')
                }}
              >
                <Image
                  className='siteHeader__logoImg'
                  src={'https://chumley.barstoolsports.com/union/2023/11/03/logo.b8652ed3.png'}
                  alt='Barstool Sports'
                  height={0}
                  width={0}
                  sizes='(max-width: 1400px) 20vw, 150px'
                />
              </a>
            </Link>
          ) : (
            <a
              href='/'
              className='siteHeader__logo siteHeader__logo--sports'
              onClick={() => {
                siteHeaderEvents.logo()
                handleSiteHeaderLinkCDPEvent('/')
              }}
            >
              <Image
                className='siteHeader__logoImg'
                src={'https://chumley.barstoolsports.com/union/2023/11/03/logo.b8652ed3.png'}
                alt='Barstool Sports'
                height={0}
                width={0}
                sizes='(max-width: 1400px) 20vw, 150px'
              />
            </a>
          )}
          {navigationStore.stickyVideoInteraction ? (
            <Link href='/greenie' legacyBehavior>
              <a
                className='siteHeader__logo siteHeader__logo--greenie'
                onClick={() => {
                  siteHeaderEvents.logoGreenie()
                  handleSiteHeaderLinkCDPEvent('/greenie')
                }}
              >
                <Image
                  className='siteHeader__logoImg'
                  src={'https://chumley.barstoolsports.com/union/2023/11/03/logo-greenie.adbf6ba4.png'}
                  alt='Greenie at Barstool'
                  height={0}
                  width={0}
                  sizes='(max-width: 1400px) 20vw, 100px'
                />
              </a>
            </Link>
          ) : (
            <a
              href='/greenie'
              className='siteHeader__logo siteHeader__logo--greenie'
              onClick={() => {
                siteHeaderEvents.logoGreenie()
                handleSiteHeaderLinkCDPEvent('/greenie')
              }}
            >
              <Image
                className='siteHeader__logoImg'
                height={0}
                width={0}
                sizes='(max-width: 1400px) 33vw, 100px'
                src={'https://chumley.barstoolsports.com/union/2023/11/03/logo-greenie.adbf6ba4.png'}
                alt='Greenie at Barstool'
              />
            </a>
          )}
        </div>
        <div className='siteHeader__navContainer'>
          <MediaQuery minWidth={THEME.BREAKPOINTS.XL}>
            <SiteNavigation />
          </MediaQuery>
        </div>
        <div className='siteHeader__right'>
          <div className='searchLink'>
            <Link href='/search' legacyBehavior>
              <a
                className='iconLink'
                onClick={() => {
                  handleSiteHeaderLinkCDPEvent('/search')
                }}
              >
                <SearchIcon color={THEME.COLORS.TEXTCOLOR} width='auto' height='20px' />
                <MediaQuery minWidth={THEME.BREAKPOINTS.MD}>
                  <span className='iconSubtitle'>Search</span>
                </MediaQuery>
              </a>
            </Link>
          </div>
          <Link href='/favorites' legacyBehavior>
            <a
              className='iconLink icon-myFeed'
              onClick={() => {
                siteHeaderEvents.myFeed()
                handleSiteHeaderLinkCDPEvent('/favorites')
              }}
            >
              <MyFeedIcon color={THEME.COLORS.TEXTCOLOR} width='auto' height='20px' />
              <MediaQuery minWidth={THEME.BREAKPOINTS.MD}>
                <span className='iconSubtitle'>My Feed</span>
              </MediaQuery>
            </a>
          </Link>
          <Link href='/download' legacyBehavior>
            <a
              className='iconLink icon-download'
              onClick={() => {
                siteHeaderEvents.myFeed()
                handleSiteHeaderLinkCDPEvent('/download')
              }}
            >
              <PhoneIcon color={THEME.COLORS.TEXTCOLOR} width='auto' height='20px' />
              <MediaQuery minWidth={THEME.BREAKPOINTS.MD}>
                <span className='iconSubtitle'>App</span>
              </MediaQuery>
            </a>
          </Link>
          <ClientOnly>
            <Link href={authStore.loggedIn ? '/account' : '/account/signin'} legacyBehavior>
              <a
                className='iconLink'
                onClick={() => {
                  if (authStore.loggedIn) siteHeaderEvents.account()
                  handleSiteHeaderLinkCDPEvent(authStore.loggedIn ? '/account' : '/account/signin')
                }}
              >
                <AvatarIcon color={THEME.COLORS.TEXTCOLOR} width='auto' height='20px' />
                <ClientOnly>
                  <MediaQuery minWidth={THEME.BREAKPOINTS.MD}>
                    <span className='iconSubtitle'>{authStore.loggedIn ? 'Account' : 'Sign In'}</span>
                  </MediaQuery>
                </ClientOnly>
              </a>
            </Link>
          </ClientOnly>
        </div>
      </div>
      <div className='siteHeader__navContainerBottom'>
        <MediaQuery maxWidth={THEME.BREAKPOINTS.XL - 1}>
          <SiteNavigation />
        </MediaQuery>
      </div>
      <style jsx global>
        {`
          .siteHeader__logoImg {
            height: 32px;
            width: auto;
          }
          .siteHeader__logo--greenie img {
            padding: 6px 0;
          }
          @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
            .siteHeader__logoImg {
              height: 38px;
            }
          }
        `}
      </style>
      <style jsx>{`
        .siteHeader {
          background: #fff;
          position: relative;
          padding: 1rem 1rem 0;
        }
        .siteHeader > .container {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0;
          max-height: 58px;
        }
        .siteHeader__logos {
          display: flex;
          height: 32px;
          width: auto;
          align-items: baseline;
        }
        .siteHeader__logo {
          display: block;
          margin-right: 16px;
        }
        .siteHeader__logo img {
          height: 32px;
          width: auto;
        }
        .siteHeader__logo--greenie {
          position: relative;
          margin-left: 8px;
        }
        .siteHeader__logo--greenie:before {
          content: '';
          position: absolute;
          left: -10px;
          top: 4px;
          bottom: 4px;
          width: 1px;
          background-color: ${THEME.COLORS.LIGHTERGRAY};
        }
        .siteHeader__logo--greenie img {
          padding: 6px 0;
        }
        .siteHeader__right {
          display: flex;
          align-items: baseline;
        }
        .iconLink {
          text-align: center;
          color: ${THEME.COLORS.TEXTCOLOR};
          margin-left: 15px;
          display: block;
          padding: 3px 0;
        }
        .iconLink--app {
          display: none;
        }
        .icon-myFeed {
          display: none;
        }
        .iconLink span {
          display: block;
          font-size: 10px;
          font-weight: 500;
          line-height: 12px;
          margin-top: 4px;
        }
        .searchLink {
          display: flex;
          position: initial;
        }
        .searchLink a {
          cursor: pointer;
        }
        .siteHeader__navContainerBottom {
          margin: 9px 0 0;
        }
        @media (min-width: 400px) {
          .iconLink--app {
            display: block;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .searchLink {
            position: relative;
          }
          .siteHeader__logos {
            height: 38px;
          }
          .siteHeader__logo img {
            height: 38px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .siteHeader__navContainerBottom {
            margin: 4px 0 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .icon-myFeed {
            display: block;
          }
          .iconLink {
            margin-left: 16px;
          }
          .iconLink--theScore {
            margin-left: 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .siteHeader__navContainer {
            margin: 0 auto;
            margin-top: 5px !important;
          }
          .siteHeader__navContainerBottom {
            display: none;
          }
          .siteHeader {
            padding: 0 1rem;
          }
          .siteHeader > .container {
            margin: auto;
            padding-top: 0;
            align-items: center;
            max-height: 75px;
          }
        }
      `}</style>
    </header>
  )
}

export default observer(SiteHeader)
