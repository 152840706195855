import { UnionResource } from 'api/resource'

class FundsApi extends UnionResource {
  constructor() {
    super('funds')
  }
  async findEntitiesById(id, params) {
    const result = await this.get({
      url: `/${this.resource}/${id}/entities`,
      params
    })
    return result
  }
}

export default FundsApi
