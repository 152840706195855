import React from 'react'
import Link from 'next/link'
import { THEME } from 'utils'

/**
 * @param {Object} props
 * navOptions is a prop expecting to be an Array of Objects with the shape:
 * { label: String, href: String, current: Boolean }
 */
const FeedNavigation = ({ navOptions = [] }) => {
  return (
    <>
      <div className='feedContainer'>
        <nav className='feedNav'>
          {navOptions
            .filter((d) => d.href)
            .map(({ label, href, as, current }, index) => (
              <Link href={href} as={as} key={index} legacyBehavior>
                <a
                  aria-current={current ? 'page' : false}
                  className={`feedNavItem ${current ? 'feedNavItem--active' : ''}`}
                >
                  {label}
                </a>
              </Link>
            ))}
        </nav>
      </div>
      <style jsx>{`
        .feedContainer {
          width: 100%;
          max-width: 75rem;
          margin: 0 auto;
          padding: 0;
          border-bottom: 1px solid #f1f1f1;
          background: #fff;
        }
        .feedNav {
          padding: 0;
          display: flex;
        }
        .feedNavItem {
          display: flex;
          padding: 0.875rem 0;
          font-family: ${THEME.FONTS.HELVETICA};
          font-size: 0.825rem;
          font-weight: 700;
          color: #a1a5ac;
          border-bottom: 2px solid transparent;
          width: 50%;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
        .feedNavItem--active {
          color: ${THEME.COLORS.SECONDARY};
          border-bottom-color: ${THEME.COLORS.SECONDARY};
        }
        @media (min-width: 37.5rem) {
          .feedContainer {
            padding: 0 20px;
          }
          .feedNavItem {
            font-size: 1rem;
          }
        }
        @media (min-width: 47.9375rem) {
          .feedNav {
            padding: 0 1.125rem;
          }
          .feedNavItem {
            margin-right: 2.5rem;
            width: auto;
            text-align: left;
          }
        }
      `}</style>
    </>
  )
}

export default FeedNavigation
