import { useEffect } from 'react'
import { getCookie, setCookie } from './cookies'
import { isServer } from 'utils'

const whoamiSDK = isServer ? undefined : window.whoamiSDK

export function reloadWhoamiSDK(anonymousID) {
  window?.whoamiSDK?.setAnonymousID(anonymousID)
  setCookie('ajs_anonymous_id', anonymousID, 365)
}

export function parseValidAnonymousID(anonymousID) {
  const sha1Regex = `[a-f0-9]{40}`
  const uuidRegex = `[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}`
  const regex = new RegExp(`^((${sha1Regex})-(${uuidRegex}))|(${uuidRegex})$`, 'i')
  const matches = anonymousID.match(regex)

  const validAnonymousID = matches?.[1]
  const sha1 = matches?.[2]
  const uuid = matches?.[3] ?? matches?.[4]

  return {
    validAnonymousID,
    sha1,
    uuid
  }
}

async function buildValidAnonymousID(input, { validAnonymousID, uuid }) {
  if (!input || !validAnonymousID) {
    const { sha1, uuid: fetchedUUID } = await fetchAnonymousID()
    return `${sha1}-${uuid ?? fetchedUUID}`
  } else {
    return input
  }
}

export async function getAnonymousID() {
  const anonymousID = window?.whoamiSDK?.anonymousID

  if (anonymousID) {
    return anonymousID
  } else {
    const cookieAnonymousID = getCookie('ajs_anonymous_id')
    const parsedAnonymousID = cookieAnonymousID ? parseValidAnonymousID(cookieAnonymousID) : {}
    const ajs_anonymous_id = await buildValidAnonymousID(cookieAnonymousID, parsedAnonymousID)
    reloadWhoamiSDK(ajs_anonymous_id)
    return ajs_anonymous_id
  }
}

export async function fetchAnonymousID() {
  const response = await fetch('https://tao.barstoolsports.com/v2/i')
  const json = await response.json()
  return json
}

export function useWhoamiPage() {
  useEffect(() => {
    async function runEffect() {
      const res = await getAnonymousID()
      page()

      return res
    }
    // built in reload mechanism for whoamiSDK on page view
    if (!window?.whoamiSDK?.anonymousID) {
      runEffect()
    } else {
      // generally once reloaded once will just call page() as expected
      page()
    }
  }, [])
}

export function track(event, content) {
  if (!window.whoamiSDK) return
  return window.whoamiSDK.track(event, content)
}

export function page() {
  if (!window.whoamiSDK) return
  return window.whoamiSDK.page()
}

export function identify(props) {
  if (!window.whoamiSDK) return
  return window.whoamiSDK.identify(props)
}
