import React from 'react'
import { THEME } from 'utils'

export default function YoutubeIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg
      viewBox='0 0 38.39 33'
      className='svgIcon socialIcon iconYouTube'
      style={{ width, height, fill: color }}
      aria-labelledby='youtube-icon'
    >
      <title id='youtube-icon'>YouTube Icon</title>
      <path d='M38,8.82S37.63,6.18,36.48,5a5.49,5.49,0,0,0-3.85-1.63C27.26,3,19.2,3,19.2,3h0s-8.06,0-13.43.39A5.49,5.49,0,0,0,1.91,5C.76,6.18.38,8.82.38,8.82A58.06,58.06,0,0,0,0,15V18a58.06,58.06,0,0,0,.38,6.21S.76,26.81,1.91,28c1.46,1.53,3.38,1.48,4.23,1.64,3.07.29,13.05.39,13.05.39s8.07,0,13.44-.4A5.49,5.49,0,0,0,36.48,28C37.63,26.81,38,24.16,38,24.16A58.14,58.14,0,0,0,38.39,18V15A58.14,58.14,0,0,0,38,8.82ZM15.23,21.48V10.69L25.6,16.11Z' />
    </svg>
  )
}
