import { BetsResource } from './resource'

export default class TeamsApi extends BetsResource {
  constructor() {
    super('teams')
  }

  async search(params) {
    const data = await this.get({ url: `/${this.resource}/search`, params })
    return data
  }
}
