import Script from 'next/script'

const FB_PIXELS_ARRAY = [process.env.FB_APP_ID, process.env.FB_RNR_ID, process.env.FB_STORE_ID, process.env.FB_WEB_ID]

export function FacebookPixelImg() {
  return FB_PIXELS_ARRAY.map((id) => (
    <noscript key={id}>
      <img
        height='1'
        width='1'
        style={{ display: 'none' }}
        alt=''
        src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
      />
    </noscript>
  ))
}

export function FacebookPixelScript() {
  function initPixels() {
    try {
      FB_PIXELS_ARRAY.forEach((id) => window.fbq('init', id))
    } catch (err) {
      console.log('fb err', err)
    }
  }

  return (
    <Script
      id='fb-pixel'
      onLoad={initPixels}
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          `
      }}
    />
  )
}

export function pageView() {
  try {
    window.fbq('track', 'PageView')
  } catch (err) {
    console.error('fb', err)
  }
}
