/**
 * Adds an exception handler to each passed in promise operation so it can
 * be properly cancelled without re-throwing an exception on cancellation
 *
 * @param  {...CancellablePromise} operations
 */
export const withFlow = (...operations) => {
  for (const op of operations) {
    op.catch((err) => {
      if (err.message === 'FLOW_CANCELLED') {
        return
      }
      throw err
    })
  }

  return Object.freeze({
    then: function () {
      return operations.length === 1 ? operations[0].then(...arguments) : Promise.all(operations).then(...arguments)
    },
    catch: function () {
      return operations.length === 1 ? operations[0].catch(...arguments) : Promise.all(operations).catch(...arguments)
    },
    finally: function () {
      return operations.length === 1
        ? operations[0].finally(...arguments)
        : Promise.all(operations).finally(...arguments)
    },
    cancel: function () {
      for (const op of operations) {
        op.cancel()
      }
    }
  })
}
