import ExternalScriptLoader from './external-script-loader'
import storage from 'lib/storage'
import { withLogger } from 'lib/logger'

const logger = withLogger('Chartbeat')

class Chartbeat extends ExternalScriptLoader {
  scriptUrl = 'https://static.chartbeat.com/js/chartbeat_video.js'
  options = { defer: true }

  init() {
    window._sf_startpt = Date.now()
    window._sf_async_config = window._sf_async_config || {}
    window._sf_async_config.uid = 63395
    window._sf_async_config.domain = 'www.barstoolsports.com'
    window._sf_async_config.useCanonical = false
    window._sf_async_config.autoDetect = false
    window._sf_async_config.path = window.location.pathname.replace(/\/$/g, '') + '/'
    window._cbq = window._cbq || []
    window._cbq.push(['_acct', storage.loggedIn ? 'lgdin' : 'anon'])
  }

  async storyPageView(story) {
    this.loadScript()
    const category = story.category.length > 0 ? story.category[0].name + ',' : ''
    const title = story.title
    const url = story.urls.barstoolsports + '/'
    window._sf_async_config.sections = category + story.type
    window._sf_async_config.authors = story.author.name
    window._sf_async_config.title = title
    logger.log('storyPageView():', title)
    window.pSUPERFLY?.virtualPage(url, title)
  }

  async pageView(url, title) {
    this.loadScript()
    logger.log('pageView():', title)
    window.pSUPERFLY?.virtualPage(url, title)
  }

  push() {
    window._cbq?.push(...arguments)
  }
}

// Taken from https://static.chartbeat.com/js/chartbeat_brightcove_plugin.js
export function chartbeatInit(c) {
  this.cb_configs = c
  this.cb_plugin_version = 1
  window._cbv = window._cbv || []
  window._cbv.push(this)
}

export default new Chartbeat()
