import { v4 as uuid } from 'uuid'
import { Dropbox, DropboxAuth } from 'dropbox'

const FOLDER_VALUES = {
  influencer_form: {
    clientId: process.env.INFLUENCER_FORM_ID,
    clientSecret: process.env.INFLUENCER_FORM_SECRET,
    refreshToken: process.env.INFLUENCER_FORM_REFRESH,
    path: '/Photo ID Request/'
  },
  viral_video_form: {
    clientId: process.env.VIRAL_VIDEO_FORM_ID,
    clientSecret: process.env.VIRAL_VIDEO_FORM_SECRET,
    refreshToken: process.env.VIRAL_VIDEO_FORM_REFRESH,
    path: '/Viral Video Form ID/'
  }
}

export async function createDropboxImage(file, folder) {
  if (!folder) return

  const { clientId, clientSecret, refreshToken, path } = FOLDER_VALUES[folder]

  const dbxAuth = new DropboxAuth({
    clientId,
    clientSecret,
    refreshToken
  })

  await dbxAuth.checkAndRefreshAccessToken()
  const dbx = new Dropbox({ accessToken: dbxAuth.getAccessToken() })

  try {
    const response = await dbx.filesUpload({ path: path + uuid() + '-' + file.name, contents: file })
    const sharedLink = await dbx.sharingCreateSharedLinkWithSettings({
      path: response?.result?.path_lower,
      settings: {
        access: 'viewer',
        allow_download: true,
        audience: 'public',
        requested_visibility: 'public'
      }
    })
    return sharedLink?.result?.url
  } catch (e) {
    console.error('Dropbox Error', e.error || e)
  }
}
