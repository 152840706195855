import { observable, flow } from 'mobx'
import { authorsApi } from 'api'
import { CommonStore } from 'stores/common'

export const AuthorsStore = () => {
  const findNavAuthors = flow(function* (params) {
    const response = yield this.api.find({ is_suggested: true, ...params })
    const sortedResponse = response.sort((a, b) => b.sort - a.sort)
    this.navAuthors = sortedResponse
    return sortedResponse
  })

  return observable({
    ...CommonStore,
    api: authorsApi,
    navAuthors: [],
    findNavAuthors
  })
}

AuthorsStore.cacheKey = 'AuthorsStore'

export default AuthorsStore
