import { useEffect, useState, useLayoutEffect } from 'react'
import { isServer } from 'utils'

let isClientSide = false

export const useClientSide = () => {
  const [ready, setReady] = useState(isClientSide)
  useClientLayoutEffect(() => {
    isClientSide = true
    setReady(true)
  }, [])
  return ready
}

// Stolen from: https://github.com/zeit/swr/blob/master/src/use-swr.ts#L41
export const useClientLayoutEffect = isServer ? useEffect : useLayoutEffect
