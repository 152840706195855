import React from 'react'

export default function Star({ fill = '#007AFF' }) {
  return (
    <svg width='11px' height='10px' viewBox='0 0 11 10'>
      <title>Star</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-72.000000, -749.000000)' fill={fill}>
          <g transform='translate(0.000000, 641.000000)'>
            <g transform='translate(0.000000, 45.000000)'>
              <g transform='translate(0.000000, 45.000000)'>
                <g id='Group' transform='translate(16.000000, 12.000000)'>
                  <polygon
                    id='Star'
                    points='61.5 14.25 58.2671811 15.9495935 58.8845946 12.3497967 56.2691892 9.80040653 59.8835906 9.27520327 61.5 6 63.1164094 9.27520327 66.7308108 9.80040653 64.1154054 12.3497967 64.7328189 15.9495935'
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
