import { UnionResource } from 'api/resource'

class FeedSubscriptionsApi extends UnionResource {
  constructor() {
    super('feed-subscriptions')
  }

  async find(userId, params) {
    const data = await this.get({ url: `/user/${userId}/${this.resource}`, params })
    return data
  }

  async findChoices(params) {
    const data = await this.get({ url: `/${this.resource}/choices`, params })
    return data
  }

  async getStoryFeedUrl(userId, params) {
    const data = await this.get({ url: `/user/${userId}/story-feed-url`, params })
    return data
  }

  async update(data) {
    const response = await this.put({ url: `/${this.resource}`, data })
    return response
  }
}

export default FeedSubscriptionsApi
