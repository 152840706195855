import { useRouter } from 'next/router'
import { useEffect } from 'react'

export function useRouteChangeComplete(callback) {
  const router = useRouter()

  useEffect(() => {
    router.events?.on('routeChangeComplete', callback)
    return () => router.events?.off('routeChangeComplete', callback)
  }, [callback])
}
