import { UnionResource } from 'api/resource'

class ViralApi extends UnionResource {
  constructor() {
    super('viral/posts')
  }

  async updateVoteById(id, data) {
    const result = await this.post({ url: `${this.resource}/${id}/vote`, data })
    return result
  }

  async updateViewedById(id) {
    const result = await this.post({ url: `${this.resource}/${id}/view` })
    return result
  }

  async getUploadUrl(data) {
    const result = await this.post({ url: `viral/upload`, data })
    return result
  }
}

export default ViralApi
