import { isServer } from './server'

// Another version of this function exists here src/utils/server.js
// This version returns a number whereas the other version returns a string

export const getDeviceTypeAsNumber = () => {
  // 1 = phone/tablet
  // 2 = personal computer
  // 3 = connected TV
  // 4 = phone
  // 5 = tablet
  // 6 = connected TV
  // 7 = set top box

  if (isServer || typeof navigator === 'undefined') return
  const userAgent = navigator.userAgent

  if (userAgent) {
    // Check for specific keywords in the user agent string
    if (/Mobi|Android/i.test(userAgent)) {
      return 1 // phone/tablet
    } else if (/iPad|Tablet/i.test(userAgent)) {
      return 5 // tablet
    } else if (/Win|Mac|Linux/i.test(userAgent)) {
      return 2 // personal computer
    } else if (/SmartTV|TV/i.test(userAgent)) {
      return 3 // connected TV
    } else {
      return 0 // unknown
    }
  } else {
    return 0
  }
}

export const getNetworkTypeAsNumber = () => {
  // 0 = Unknown
  // 1 = Ethernet
  // 2 = WiFi
  // 3 = Cell (unknown)
  // 4 = Cell (2G)
  // 5 = Cell (3G)
  // 6 = Cell (4G+)

  if (isServer || typeof navigator === 'undefined') return
  const connection = navigator.connection

  if (connection) {
    // type is not supported in the majority of browsers but adding it here for completeness and future proofing
    const { effectiveType, type } = connection

    let value = 0
    let usedType = type

    if (type === undefined || type === 'cellular') {
      usedType = effectiveType
    }

    if (usedType === 'ethernet') {
      value = 1
    } else if (usedType === 'wifi') {
      value = 2
    } else if (usedType === 'cellular') {
      value = 3
    } else if (usedType === 'slow-2g') {
      value = 4
    } else if (usedType === '2g') {
      value = 4
    } else if (usedType === '3g') {
      value = 5
    } else if (usedType === '4g') {
      value = 6
    }

    return value
  } else {
    return 0
  }
}

export function useClientInfo() {
  if (typeof navigator === 'undefined' || isServer) return

  const ua = navigator.userAgent
  const dnt = navigator.doNotTrack ? 1 : 0
  const os = navigator.oscpu ?? navigator.platform
  const devicetype = getDeviceTypeAsNumber()
  const h = window.screen.height
  const w = window.screen.width
  const pxratio = window.devicePixelRatio
  const connectiontype = getNetworkTypeAsNumber()

  const device = {
    ua,
    dnt,
    os,
    devicetype,
    h,
    w,
    pxratio,
    connectiontype
  }

  return device
}
