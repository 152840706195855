import { getAnonymousID } from 'lib/whoami'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { isServer } from 'utils'
import { useStatus } from 'hooks/data/status'
import { getQueryParam } from 'utils/query'
import { hashBarstoolUserId } from 'utils/user'
import storage from 'lib/storage'
import { useRouteChangeComplete } from './route-change'

const htlAdProdUrl = 'https://htlbid.com/v3/barstoolsports.com/htlbid'
const isTesting = process.env.HTL_AD_URL === htlAdProdUrl ? 'no' : 'yes'
const keys = [
  'authorId',
  'brandId',
  'brandName',
  'category',
  'pagetype',
  'tags',
  'videoTags',
  'adtar',
  'verity_keywords',
  'verity_iabv1',
  'verity_iabv2',
  'verity_iabv3',
  'verity_neutral',
  'verity_positive',
  'verity_negative',
  'verity_ggt1_conf',
  'verity_ggt1_risk',
  'verity_ggt2_conf',
  'verity_ggt2_risk',
  'verity_ggt3_conf',
  'verity_ggt3_risk',
  'verity_ggt4_conf',
  'verity_ggt4_risk',
  'verity_ggt5_conf',
  'verity_ggt5_risk',
  'verity_ggt6_conf',
  'verity_ggt6_risk',
  'verity_ggt7_conf',
  'verity_ggt7_risk',
  'verity_ggt8_conf',
  'verity_ggt8_risk',
  'verity_ggt9_conf',
  'verity_ggt9_risk'
]

export const withHTL = (callback) => {
  window.htlbid = window.htlbid || {}
  window.htlbid.cmd = window.htlbid.cmd || []
  window.htlbid.cmd.push(() => {
    callback()
  })
}

async function teadsRefresh() {
  if (typeof window.teads?.cleanup !== 'function') return
  try {
    await window.teads.cleanup()(function (d, p) {
      var js,
        s = d.getElementsByTagName('script')[0]
      js = d.createElement('script')
      js.className = 'teads'
      js.async = true
      js.type = 'text/javascript'
      js.src = '//a.teads.tv/page/' + p + '/tag'
      s.parentNode.insertBefore(js, s)
    })(window.document, process.env.TEADS_PAGE_ID)
  } catch (e) {
    console.error('Teads Refresh Error:', e)
  }
}

// Hook for handling HTL specific operations including setup and page views
export function useHashtagLabs({ keyValues, ssp, adFree, layout = 'universal' }) {
  const router = useRouter()
  const { data: status } = useStatus()

  const adKeyValues = useMemo(() => {
    if (isServer) return
    const adParams = { ...keyValues, is_testing: isTesting }

    const adtar = getQueryParam('adtar')

    adParams.adtar = adtar

    return adParams
  }, [JSON.stringify(keyValues)])

  const htlSetup = () => {
    try {
      withHTL(async () => {
        // clear previous targetting, establish layout, set fresh targetting
        if (ssp) {
          ssp.device.ifa = await getPublisherProvidedId()
          ssp.device.ip = status?.client?.ip
          ssp.device.geo = status?.client?.geo
          window.htlbid.setFirstPartyData(ssp)
        }
        // clear targetting for specific keys
        keys.forEach((key) => window.htlbid.clearTargeting(key))

        // set getPubProvidedId function globally for use in htl dashboard
        window.getPubProvidedId = getPublisherProvidedId
        window.htlbid.layout(layout)
        for (const [key, value] of Object.entries(adKeyValues)) {
          if (value) window.htlbid.setTargeting(key, value)
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const pageView = async () => {
    if (adFree || isServer) return
    try {
      withHTL(() => {
        window.htlbid.newPage()
      })
    } catch (e) {
      console.error(e)
    }
  }

  // set the "Publisher Provided ID (PPID)" for HTL, using unique ID from Segment
  useEffect(() => {
    if (isServer) return
    async function setPubId() {
      try {
        withHTL(async () => {
          const pubId = await getPublisherProvidedId()
          window.htlbid.pubads().setPublisherProvidedId(pubId)
        })
      } catch (e) {
        console.error('PPID Error:', e)
      }
    }
    setPubId()
  }, [])

  // setup HTL on initial page load and when route changes
  useEffect(() => {
    if (isServer) return
    htlSetup()
  }, [router.asPath])

  // notify a new page when route changes
  useRouteChangeComplete(async () => {
    if (isServer) return
    await teadsRefresh()
    await pageView()
  })
}

export async function getPublisherProvidedId() {
  const userId = storage.userId
  const isLoggedIn = userId ? true : false

  if (isLoggedIn) {
    return hashBarstoolUserId(userId)
  }
  return await getAnonymousID()
}
