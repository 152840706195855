import React from 'react'
import Link from 'next/link'

import { useUspVisibility } from 'hooks'

const CaliforniaNoticesLink = ({ style, forceVisible = false }) => {
  const { isVisible } = useUspVisibility({ forceVisible })

  if (!isVisible && !forceVisible) {
    return null
  }
  return (
    <Link href='/privacy-policy#ccpa' legacyBehavior>
      <a style={{ ...style, cursor: 'pointer' }}>California Notices</a>
    </Link>
  )
}

export default CaliforniaNoticesLink
