import { UnionResource } from 'api/resource'

class MediaApi extends UnionResource {
  constructor() {
    super('media')
  }

  async getUploadUrl(data) {
    const response = await this.post({ url: `${this.resource}/upload`, data })
    return response
  }

  async requestMultipartUpload(data) {
    const response = await this.post({ url: `${this.resource}/upload-multipart`, data })
    return response
  }

  async completeMultipartUpload(data) {
    const response = await this.post({ url: `${this.resource}/upload-multipart/complete`, data })
    return response
  }

  async getAppInfo() {
    const response = await this.get({ url: `${this.resource}/app-info` })
    return response
  }
}

export default MediaApi
