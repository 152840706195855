import { useEffect } from 'react'
import { format } from 'date-fns'
import { getStoryCategory, isAdBlockEnabled } from 'utils'
import { useLoggedInUser, useUserId } from 'hooks'
import { getAnonymousID, useWhoamiPage, identify } from 'lib/whoami'
import ga from 'lib/ga'
import pixels from 'lib/pixels'
import chartbeat from 'lib/chartbeat'

export const useAnalytics = () => {
  useEffect(() => {
    ga.initialize()
    chartbeat.loadScript()
    getAnonymousID().then()
  }, [])
}

export const useWhoamiIdentification = () => {
  const user = useLoggedInUser()

  useEffect(() => {
    async function runEffect(userId) {
      await getAnonymousID()
      identify({
        barstoolUserID: userId
      })
    }
    if (user.loggedIn && user.id) {
      runEffect(user.id)
    }
  }, [user?.id])
}

export const usePageView = (handler = () => {}, deps = []) => {
  const userId = useUserId()

  useAnalytics()

  useWhoamiPage()

  useEffect(() => {
    setTimeout(() => {
      const overrides = handler(ga) || {}

      // Grab current page url
      const url = new URL(location.href)

      // Apply additional query params from overrides
      for (const [key, value] of Object.entries(overrides.query ?? {})) {
        if (value === undefined) continue
        url.searchParams.set(key, value)
      }

      // Set user id if we have it
      ga.set({ userId })

      ga.customDimensions.setAccessLevel('Public')

      ga.customDimensions.setAdBlock(isAdBlockEnabled())

      // Send page view based on updated url
      ga.sendPageView(url.pathname + url.search, overrides.title ?? document.title)

      // Trigger chartbeat tracking, don't send query params
      if (overrides.chartbeat !== false) {
        chartbeat.pageView(url.pathname, overrides.title ?? document.title)
      }

      // Trigger pixel page view
      pixels.pageView()
    }, 1000)
    return () => {
      ga.customDimensions.clearDimensions()
    }
  }, deps)
}

export const useStoryPageView = (story) => {
  usePageView(() => {
    const dimensions = {
      author: story.author.name,
      pageType: 'story',
      date: format(new Date(story.date), 'M/d/yyyy h:mm a'),
      category: getStoryCategory(story),
      tags: story.tags.join('|'),
      postType: story.type,
      brandName: story.brand_name,
      nsfw: story.nsfw ? 'nsfw' : null,
      id: story.id
    }
    // Set custom dimensions
    ga.customDimensions.setStoryDimensions(dimensions)

    // Track in chartbeat
    chartbeat.storyPageView(story)

    // Return overrides
    return { title: story.title, chartbeat: false }
  }, [story.id])
}

export const useFeedPageView = (pagination, { pageType = 'Index' } = {}, deps = []) => {
  usePageView(() => {
    // Set GA page type
    ga.customDimensions.setPageType(pageType)

    return {
      query: {
        page: pagination.page > 1 ? pagination.page : undefined
      }
    }
  }, [pagination.page, pageType, ...deps])
}
