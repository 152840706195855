import { flow, runInAction, toJS } from 'mobx'

export const CommonStore = {
  // Must set in subclass
  api: null,

  // Common Properties
  items: [],
  current: null,
  isLoading: false,
  isCreating: false,
  isUpdating: false,

  hydrate(data) {
    if (!data) return
    runInAction(() => Object.assign(this, data))
  },

  reset: flow(function* () {
    this.items = []
    this.current = null
    this.isLoading = false
    yield
  }),

  find: flow(function* (params) {
    try {
      this.isLoading = true
      const items = yield this.api.find(params)
      this.items = items
      return items
    } finally {
      this.isLoading = false
    }
  }),

  findNext: flow(function* (params) {
    try {
      this.isLoading = true
      const items = yield this.api.find(params)
      this.items.push(...items)
      return toJS(this.items)
    } finally {
      this.isLoading = false
    }
  }),

  findById: flow(function* (id, params) {
    try {
      this.isLoading = true
      const current = yield this.api.findById(id, params)
      this.current = current
      return current
    } finally {
      this.isLoading = false
    }
  }),

  create: flow(function* (options) {
    try {
      this.isCreating = true
      const current = yield this.api.create(options)
      this.current = current
      return current
    } finally {
      this.isCreating = false
    }
  }),

  update: flow(function* (id, options) {
    try {
      this.isUpdating = true
      const current = yield this.api.update(id, options)
      this.current = current
      this._merge([this.current])
      return current
    } finally {
      this.isUpdating = false
    }
  }),

  updateMany: flow(function* (options) {
    try {
      this.isUpdating = true
      this._merge(yield this.api.updateMany(options))
      return toJS(this.items)
    } finally {
      this.isUpdating = false
    }
  }),

  _merge: flow(function* (updatedItems) {
    this.items = this.items.map((item) => {
      for (const updatedItem of updatedItems) {
        if (updatedItem.id === item.id) return updatedItem
      }
      return item
    })
    yield
  })
}

export default CommonStore
