import React, { useEffect } from 'react'
import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'
import NotFound from 'components/NotFound'
import { StoriesStore } from 'stores/stories'
import { usePageView, useStore } from 'hooks'
import { getStaticWrapper } from 'utils'

const NotFoundPage = () => {
  const storiesStore = useStore(StoriesStore)

  usePageView()

  useEffect(() => {
    storiesStore.findRandomStory()
  }, [])

  return (
    <SiteLayout>
      <Head title='Not Found | Barstool Sports' noIndexNoFollow />
      <div className='errorContainer'>
        <NotFound />
      </div>
      <style global jsx>
        {`
          .layout__main {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      </style>
      <style jsx>
        {`
          .errorContainer {
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .errorContainer h1 {
            font-size: 72px;
          }
          .errorContainer p {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
          }
        `}
      </style>
    </SiteLayout>
  )
}

export const getStaticProps = getStaticWrapper()

export default NotFoundPage
