import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { isMobile, THEME } from 'utils'
import { useGlobalInterval, useStore } from 'hooks'
import { AuthorsStore } from 'stores/authors'
import { BrandsStore } from 'stores/brands'
import { ConfigStore } from 'stores/config'
import { NavigationStore } from 'stores/navigation'
import { StoriesStore } from 'stores/stories'
import { siteHeaderEvents } from 'lib/ga'

import NavLink from 'components/NavLink'
import SubnavFeed from 'components/SubnavFeed'
import SubnavList from 'components/SubnavList'
import SubnavSingle from 'components/SubnavSingle'
import SubnavAvatars from 'components/SubnavAvatars'

let dropdownTimeout = null

const SiteNavigation = () => {
  const authorsStore = useStore(AuthorsStore)
  const brandsStore = useStore(BrandsStore)
  const configStore = useStore(ConfigStore)
  const navigationStore = useStore(NavigationStore)
  const storiesStore = useStore(StoriesStore)

  useGlobalInterval(
    'nav.data',
    () => {
      storiesStore.findNavStories()
      authorsStore.findNavAuthors()
      brandsStore.findNavBrands()
    },
    60 * 1000
  )

  const subnavOptions = {
    blogs: [
      {
        title: 'latest',
        href: '/blogs'
      },
      {
        title: 'my favorites',
        href: '/blogs/favorites'
      }
    ],
    vids: [
      {
        title: 'latest',
        href: '/videos'
      },
      {
        title: 'my favorites',
        href: '/videos/favorites'
      },
      {
        title: 'playlists',
        href: '/videos/playlists'
      }
    ],
    pods: [
      {
        title: 'latest',
        href: '/podcasts'
      },
      {
        title: 'my favorites',
        href: '/podcasts/favorites'
      }
    ]
  }

  const renderSubnav = (item) => {
    switch (item.title) {
      case 'Blogs':
        return <SubnavFeed items={storiesStore.navBlogs} feed='blogs' theme={THEME} options={subnavOptions.blogs} />
      case 'Vids':
        return <SubnavFeed items={storiesStore.navVideos} feed='videos' theme={THEME} options={subnavOptions.vids} />
      case 'Pods':
        return (
          <SubnavFeed items={storiesStore.navPodcasts} feed='podcasts' theme={THEME} options={subnavOptions.pods} />
        )
      case 'Pics':
        return <SubnavFeed items={storiesStore.navGalleries} feed='galleries' theme={THEME} stackedFeedAndButtons />
      case 'Bloggers':
        return <SubnavAvatars items={authorsStore.navAuthors.slice(0, 15)} feed='bloggers' theme={THEME} />
      case 'Shows':
        return <SubnavAvatars items={brandsStore.navBrands.slice(0, 10)} feed='shows' theme={THEME} />
      case 'Topics':
        return <SubnavList type={configStore.current.navigation.topics} list='topics' />
      case 'Store':
        return <SubnavList type={configStore.current.navigation.store} list='store' />
      case 'More':
        return <SubnavSingle items={configStore.current.navigation.more} dropdown='more' name='more' />
      default:
        return <></>
    }
  }

  const onNavItemEnter = (title) => {
    clearTimeout(dropdownTimeout)
    dropdownTimeout = setTimeout(() => navigationStore.open(title), navigationStore.currentDropdown ? 100 : 200)
  }

  const onNavItemLeave = () => {
    clearTimeout(dropdownTimeout)
    dropdownTimeout = setTimeout(() => navigationStore.close(), 50)
  }

  const onNavItemClick = () => {
    clearTimeout(dropdownTimeout)
    dropdownTimeout = null
  }

  // TODO: once gif -> mp4 conversion is implemented, remove the condition checking for navigationStore.currentDropdown === lowerCaseTitle
  // once we have gif conversion, it'll be safe to render the subnavs all the time and have their images preloaded
  const shouldRenderSubnav = useCallback(
    (item) => {
      const lowerCaseTitle = item.title.toLowerCase()
      return (!isMobile() || item.hasMobileDropdown) && navigationStore.currentDropdown === lowerCaseTitle
    },
    [navigationStore.currentDropdown]
  )
  return (
    <nav className='siteNavigation'>
      <ul itemScope itemType='http://www.schema.org/SiteNavigationElement' className='siteNavigation__nav'>
        {navigationStore.navigationItems.map((item, idx) => {
          const lowerCaseTitle = item.title.toLowerCase()
          if (item.hasDropdown) {
            return (
              <li
                itemProp='name'
                key={idx}
                className={`siteNavigation__dropdown siteNavigation__dropdown--${lowerCaseTitle} ${
                  navigationStore.currentDropdown === lowerCaseTitle && 'siteNavigation__dropdown--active'
                }`}
                onClick={() => onNavItemClick(item)}
                onMouseEnter={() => {
                  if (item.hasDropdown && !isMobile()) {
                    onNavItemEnter(lowerCaseTitle)
                  }
                }}
                onMouseLeave={onNavItemLeave}
              >
                <NavLink item={item} />
                <div style={{ display: shouldRenderSubnav(item) ? 'block' : 'none' }}>{renderSubnav(item)}</div>
              </li>
            )
          } else {
            return (
              <li
                key={idx}
                itemProp='name'
                onClick={() => {
                  siteHeaderEvents.navLink(item.title, false)
                }}
              >
                <NavLink item={item} />
              </li>
            )
          }
        })}
      </ul>
      <style jsx>{`
        .siteNavigation {
          display: block;
          padding: 0 5px;
        }
        .siteNavigation__nav {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          list-style: none;
          margin: 0;
          padding: 0;
          height: 45px;
          align-items: baseline;
        }
        .siteNavigation__nav li {
          height: 100%;
          bottom: 0;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .siteNavigation__nav {
            min-height: 72px;
          }
        }
        @media (min-width: 1136px) {
          .siteNavigation__nav {
            height: 72px;
            overflow-x: visible;
            align-items: center;
          }
          .siteNavigation__nav > li > a.featured {
            font-size: 1.5rem;
          }
        }
      `}</style>
    </nav>
  )
}

export default observer(SiteNavigation)
