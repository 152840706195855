const isProd = process.env.NEXT_PUBLIC_STAGE === 'production'

export function getCookie(inputKey = 'ajs_user_id') {
  if (typeof window !== 'undefined') {
    if (document) {
      const cookies = document.cookie.split('; ') // document.cookie is "; " separated for each key=value term
      for (let i = 0; i < cookies.length; i++) {
        const [key, value] = cookies[i].split('=') // each cookie term is key=value
        if (inputKey === key) {
          return value
        }
      }
      return null
    }
  }
}

export function setCookie(key = 'ajs_user_id', value = '', daysToExpire = 0) {
  // use wildcard domains instead of default www
  const domain = isProd ? '.barstoolsports.com' : '.barstoolsports.dev'
  if (typeof window !== 'undefined') {
    if (document) {
      const inputs = []
      inputs.push(`${key}=${value}`)
      if (daysToExpire) {
        const date = new Date()
        const daysToExpireInMs = daysToExpire * 24 * 60 * 60 * 1000
        const now = date.getTime()
        date.setTime(now + daysToExpireInMs)
        inputs.push(`Expires=${date.toUTCString()}`)
      }
      inputs.push(`Domain=${domain}`)
      inputs.push('Path=/')
      inputs.push(`SameSite=None`)
      inputs.push(`Secure`)

      document.cookie = inputs.join('; ') // document.cookie is "; " separated for each key=value term
    }
  }
}
