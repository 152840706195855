import videojs from 'video.js'

export function isMobileUserAgent() {
  return (
    videojs.browser.TOUCH_ENABLED ||
    videojs.browser.IS_ANDROID ||
    videojs.browser.IS_IPAD ||
    videojs.browser.IS_IPHONE ||
    videojs.browser.IS_IPOD
  )
}
