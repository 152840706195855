export const POST_TYPES = Object.freeze({
  standard_post: 'blog',
  barstool_original: 'video',
  podcast: 'podcast',
  gallery: 'gallery',
  video_podcast: 'video-podcast',
  live: 'live',
  fundraiser: 'donate'
})

/* story.type sent through the whoamiSDK ✌🏻 */
export const storyTypeCDPMap = {
  standard_post: 'Blog',
  barstool_original: 'Video',
  podcast: 'Podcast',
  gallery: 'Blog',
  video_podcast: 'Video',
  live: 'Live'
}

export const getStoryCategory = (story) => {
  if (!story || !story.category) return 'No Category'
  if (story.category.length) {
    return story.category[0].name
  } else if (Object.keys(story.category).length) {
    return story.category.name
  } else {
    return 'No category'
  }
}

export const parseStoryUrl = (story) => {
  // Construct new story url
  return `/${POST_TYPES[story.type]}/${story.id}/${story.slug}`
}

export const parseVideoDuration = (duration) => {
  switch (duration) {
    case 60 * 1000 > duration > 0:
      return '0-1 min'
    case 120 * 1000 > duration > 60 * 1000 + 1:
      return '1-2 min'
    default:
      return '2 min'
  }
}

export const getTitleExtras = (story) => {
  return story.brand_name || story.post_type_meta?.podcast?.show?.title
}

export function shouldFetchStoryVideoSource(story) {
  const videoMeta = story?.post_type_meta?.barstool_original || story?.post_type_meta

  if (!videoMeta || !videoMeta.id) {
    return false
  }
  return true
}
