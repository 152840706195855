import Script from 'next/script'
import { withLogger } from 'lib/logger'

const logger = withLogger('TwitterPixel')

const TWITTER_SRC = 'https://static.ads-twitter.com/uwt.js'

export function TwitterPixelScript() {
  function init() {
    !(function (e, s) {
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments)
          }),
        (s.version = '1.1'),
        (s.queue = []))
    })(window)
    window.twq('init', process.env.TWITTER_APP_ID)
    window.twq('init', process.env.TWITTER_PENN_ID)
    window.twq('track', 'PageView')
  }

  return <Script id='tw-pixel' onLoad={init} src={TWITTER_SRC} strategy='afterInteractive' />
}

export async function pageView() {
  try {
    window.twq('track', 'PageView')
  } catch (err) {
    logger.error(err)
  }
}
