import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import FacebookIcon from 'icons/Facebook'
import TwitterIcon from 'icons/Twitter'
import InstagramIcon from 'icons/Instagram'
import SmartLink from 'components/SmartLink'
import CCPALink from 'components/CCPALink'
import CaliforniaNoticesLink from 'components/CaliforniaNoticesLink'
import SnapchatIcon from 'icons/Snapchat'

import { siteHeaderEvents } from 'lib/ga'
import { THEME } from 'utils'
import Image from 'next/image'

const SubnavSingle = ({ items, dropdown, name }) => {
  if (!items || !items.length) return null
  const linkStyle = {
    display: 'block',
    color: '#222',
    fontSize: '14px',
    paddingTop: '8px',
    paddingBottom: '8px'
  }

  return (
    <>
      <div className='dropdownMenu'>
        <ul>
          {items &&
            items.map((item, idx) => {
              return (
                <li key={idx}>
                  <SmartLink
                    item={item}
                    style={linkStyle}
                    onClick={() => {
                      siteHeaderEvents.navLink(`${name.toUpperCase()} - ${item.title}`)
                    }}
                  >
                    {item.title}
                  </SmartLink>
                </li>
              )
            })}
        </ul>
        <div className='dropdownMenu__more' style={{ display: dropdown === 'more' ? 'block' : 'none' }}>
          <div className='socialLinks'>
            <a
              href='https://www.facebook.com/barstoolsports/'
              target='_blank'
              rel='noopener noreferrer'
              className='socialLink'
            >
              <FacebookIcon color='#222' width='auto' height='20px' />
            </a>
            <a
              href='https://www.twitter.com/barstoolsports/'
              target='_blank'
              rel='noopener noreferrer'
              className='socialLink'
            >
              <TwitterIcon color='#222' width='auto' height='20px' />
            </a>
            <a
              href='https://www.snapchat.com/add/barstoolsports'
              target='_blank'
              rel='noopener noreferrer'
              className='socialLink'
            >
              <SnapchatIcon color='#222' width='auto' height='20px' />
            </a>
            <a
              href='https://www.instagram.com/barstoolsports/'
              target='_blank'
              rel='noopener noreferrer'
              className='socialLink'
            >
              <InstagramIcon color='#222' width='auto' height='20px' />
            </a>
          </div>
          <div className='appDownloads'>
            <a
              className='appLink'
              href='https://itunes.apple.com/us/app/barstool-sports/id456805313'
              onClick={siteHeaderEvents.appDownloadApple}
              target='_blank'
              rel='noreferrer noopener'
            >
              <Image
                src={'https://chumley.barstoolsports.com/union/2023/11/03/download-app-store.31b8cc33.svg'}
                style={{ height: 'auto', width: '100%' }}
                alt='Download The Barstool App From The iTunes App Store'
                height={0}
                width={0}
                sizes='(max-width: 1400px) 20vw, 150px'
              />
            </a>
            <a
              className='appLink'
              href='https://play.google.com/store/apps/details?id=com.DesignMenace.BarstoolSports'
              onClick={siteHeaderEvents.appDownloadAndroid}
              target='_blank'
              rel='noreferrer noopener'
            >
              <Image
                src={'https://chumley.barstoolsports.com/union/2023/11/03/download-play-store.8e08c55c.svg'}
                style={{ height: 'auto', width: '100%' }}
                alt='Download The Barstool App From The Google Play Store'
                height={0}
                width={0}
                sizes='(max-width: 1400px) 20vw, 150px'
              />
            </a>
          </div>
          <ul className='legal'>
            <li>
              <Link href='/partnerships' prefetch={false} legacyBehavior>
                <a
                  onClick={() => {
                    siteHeaderEvents.marketing()
                  }}
                >
                  Advertising Inquiries
                </a>
              </Link>
            </li>
            <li>
              <Link href='/viceroy-signup' prefetch={false} legacyBehavior>
                <a>Viceroy Agreement</a>
              </Link>
            </li>
            <li>
              <Link href='/terms-of-use' prefetch={false} legacyBehavior>
                <a
                  onClick={() => {
                    siteHeaderEvents.terms()
                  }}
                >
                  Terms of Use
                </a>
              </Link>
            </li>
            <li>
              <Link href='/privacy-policy' prefetch={false} legacyBehavior>
                <a
                  onClick={() => {
                    siteHeaderEvents.privacy()
                  }}
                >
                  Privacy Policy
                </a>
              </Link>
            </li>
            <li>
              <Link href='/content-policy' prefetch={false} legacyBehavior>
                <a
                  onClick={() => {
                    siteHeaderEvents.contentPolicy()
                  }}
                >
                  Content Policy
                </a>
              </Link>
            </li>
            <li>
              <Link href='/cookie-policy' prefetch={false} legacyBehavior>
                <a>Cookie Policy</a>
              </Link>
            </li>
            <li>
              <Link href='/messaging-terms' prefetch={false} legacyBehavior>
                <a>Messaging Terms</a>
              </Link>
            </li>
            <li>
              <Link href='/jobs' prefetch={false} legacyBehavior>
                <a
                  onClick={() => {
                    siteHeaderEvents.careers()
                  }}
                >
                  Careers
                </a>
              </Link>
            </li>
            <li>
              <CCPALink
                style={{
                  display: 'block',
                  fontSize: '12px',
                  color: '#999',
                  paddingTop: '4px',
                  paddingBottom: '4px'
                }}
              />
            </li>
            <li>
              <CaliforniaNoticesLink
                style={{
                  display: 'block',
                  fontSize: '12px',
                  color: '#999',
                  paddingTop: '4px',
                  paddingBottom: '4px'
                }}
              />
            </li>
          </ul>
          <p className='copyright'>© {new Date().getFullYear()} Barstool Sports.</p>
        </div>
      </div>
      <style jsx>{`
        .dropdownMenu {
          width: 100%;
          position: absolute;
          z-index: 15;
          top: 100%;
          left: 0;
          background: #f1f1f1;
          padding: 8px 16px;
          border: 1px solid ${THEME.COLORS.LIGHTERGRAY};
        }
        .siteNavigation__dropdown--active .dropdownMenu {
          position: relative;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        .dropdownMenu__more {
          margin-top: 10px;
          padding: 8px 0;
          border-top: 1px solid ${THEME.COLORS.LIGHTERGRAY};
        }
        .socialLinks {
          padding: 8px 0;
        }
        .socialLink {
          margin-right: 15px;
        }
        .appDownloads {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 5px;
          grid-row-gap: 5px;
          max-width: 340px;
          padding: 8px 0;
        }
        .legal {
          padding: 8px 0;
        }
        .legal li a {
          display: block;
          font-size: 12px;
          color: #999;
          padding-top: 4px;
          padding-bottom: 4px;
        }
        .copyright {
          font-size: 0.7rem;
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .dropdownMenu {
            width: 20rem;
            right: auto;
            left: auto;
          }
        }
      `}</style>
    </>
  )
}

export default observer(SubnavSingle)
