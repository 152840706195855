import React from 'react'
import { THEME, darkenColor } from 'utils'

const GlobalStyles = () => (
  <style jsx global>{`
    * {
      box-sizing: border-box;
    }
    /* Resets */
    input,
    label,
    select,
    button,
    textarea {
      -webkit-appearance: none;
      margin: 0 0 12px;
      border: 0;
      padding: 0;
      display: block;
      vertical-align: middle;
      white-space: normal;
      background: none;
      line-height: 1;
      width: 100%;
    }
    input[type='checkbox'] {
      -webkit-appearance: checkbox;
      display: inline-block;
      width: inherit;
    }
    input[type='radio'] {
      -webkit-appearance: radio;
      display: inline-block;
      width: inherit;
    }
    body {
      margin: 0;
      font-family: ${THEME.FONTS.HELVETICA};
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-size: 16px;
      overflow-x: hidden;
    }
    body.drawer-open {
      overflow: hidden;
    }
    h1 {
      color: ${THEME.COLORS.TEXTCOLOR};
      font-size: 32px;
      font-weight: bold;
      letter-spacing: -0.1px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 20px;
      margin-top: 0;
      white-space: pre-line;
    }
    a {
      text-decoration: none;
    }
    .hidden {
      display: none;
    }
    .textCenter {
      text-align: center;
    }
    .container--accountInfoFlow {
      box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
      max-width: 26.5rem !important;
      padding: 1rem 0 !important;
      margin: 3rem auto !important;
      text-align: center;
      background: #ffffff;
      align-items: space-between;
    }
    .container--accountInfoFlow h1 {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0.75rem 1.125rem;
      line-height: 1.2;
      font-size: 1.8rem;
      letter-spacing: normal;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-weight: bold;
      font-family: ${THEME.FONTS.HELVETICA};
      text-transform: capitalize;
    }
    .container--accountInfoFlow .form__error,
    .container--accountInfoFlow .form__error--success {
      max-width: 16rem;
      margin: 1rem auto 1.5rem;
    }
    .commonForm {
      padding-bottom: 1rem;
    }
    .commonForm input,
    .commonForm select {
      max-width: 16.25rem;
      max-height: 40px;
      margin: auto;
      margin-bottom: 1rem;
      background: #f1f1f1;
      font-family: ${THEME.FONTS.HELVETICA};
      color: ${THEME.COLORS.TEXTCOLOR};
      line-height: normal;
      max-height: 40px;
      font-size: 1rem;
      border: 1px solid #cdcdcd;
      border-radius: 2px;
      outline: none !important;
      align-items: center;
    }
    .commonForm select {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .commonForm label {
      margin-top: 1rem;
    }
    .commonForm button {
      max-width: 16.25rem;
      margin: auto;
      padding: 0.9rem 2.5rem !important;
      letter-spacing: 0.09rem !important;
      font-size: 0.875rem;
    }
    .commonForm input.formControl {
      max-width: 100%;
    }
    #videoSubmissionPageFileInput {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .videoSubmissionButton {
      max-width: 100% !important;
      width: 100% !important;
    }
    .progress-bar {
      position: relative;
      background: #e5e5e5;
      border-radius: 1rem;
      font-size: 1rem;
      width: 100%;
      height: 0.5rem;
    }
    .progress-bar__progress {
      position: absolute;
      background: ${THEME.COLORS.SECONDARY};
      border-radius: 1rem;
      font-size: 1rem;
      height: 0.5rem;
      left: 0;
      top: 0;
    }
    .embed__fullWidth {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
    }
    .embed__fullWidth iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    .form-global-field-populated {
      background: white !important;
    }
    .form-global-field-input-error {
      background: white !important;
      border: 1px solid ${THEME.COLORS.ERROR} !important;
      box-sizing: border-box;
      border-radius: 2px;
    }
    .form-global-input-field {
      background: transparent;
      box-sizing: border-box;
      border-radius: 2px;
      outline: none;
      border: none;
      padding: 5px 16px 10px;
      width: 100%;
      resize: none;
      font-weight: 400;
      margin: 0;
      font-family: 'Helvetica Neue', Helvetica, BlinkMacSystemFont, 'Avenir Next', Avenir, -apple-system, sans-serif;
    }
    .formMessage {
      font-family: ${THEME.FONTS.HELVETICA};
      position: relative;
      text-align: center;
      margin: 1rem auto 1.5rem;
      padding: 0.875em;
      background: #f1f1f1;
      border: 1px solid ${THEME.COLORS.LIGHTERGRAY};
    }
    .formMessage p {
      margin-bottom: 0;
    }
    .formMessage__dismiss {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
    }
    .formMessage__dismiss svg {
      height: 0.6rem;
    }
    .formMessage--success {
      background: #fff;
      border-color: #5aa700;
      color: #5aa700;
    }
    .formMessage--success svg {
      fill: #5aa700;
    }
    .formMessage--error {
      background: #fff;
      border-color: ${THEME.COLORS.ERROR};
      color: ${THEME.COLORS.ERROR};
    }
    .formMessage--error svg {
      fill: ${THEME.COLORS.ERROR};
    }
    .formMessage--small {
      padding-top: 1rem;
      font-size: 0.75rem;
    }

    .formSection .formGroup {
      border: 2px solid #666;
      box-sizing: border-box;
    }
    .formGroup {
      display: block;
      margin-bottom: 1rem;
    }
    .formGroup label {
      display: inline-block;
      font-family: ${THEME.FONTS.HELVETICA};
      font-weight: 700;
      font-size: 0.625rem;
      padding: 10px 16px 0;
      color: ${THEME.COLORS.TEXTCOLOR};
      margin-bottom: 0.375rem;
    }
    .formGroup input {
      padding: 5px 16px 10px;
      margin: 0;
      border: none !important;
      background: transparent;
      font-size: 0.875rem;
      display: block;
      width: 100%;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-weight: 400;
      font-family: ${THEME.FONTS.HELVETICA};
    }
    .formGroup select {
      position: relative;
      z-index: 1;
      max-width: 100%;
      width: 100%;
      padding: 5px 16px 10px;
      margin: 0 !important;
      font-size: 1rem;
      background: transparent;
      display: inline-block;
      font-family: system-ui;
      border: 0;
      outline: none;
    }
    .formGroup input:focus,
    .formGroup textarea:focus,
    .formGroup select:focus {
      outline: none;
    }
    .finePrint {
      color: ${THEME.COLORS.GRAY};
      font-size: 10px;
      letter-spacing: 0.2px;
      line-height: 1rem;
    }
    .finePrint a,
    .finePrint a:hover {
      color: #367dc4 !important;
    }
    input,
    textarea,
    select {
      background-color: ${THEME.COLORS.LIGHTESTGRAY};
      font-size: 14px;
      padding: 13.5px 12px;
    }
    textarea {
      border: 1px solid ${THEME.COLORS.LIGHTGRAY};
      min-height: 85px;
    }
    select {
      height: 44px;
    }
    label {
      color: ${THEME.COLORS.TEXTCOLOR};
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
    }
    .form__error {
      background-color: #f8ece9;
      color: #d0341f;
      border: 1px solid #d0341f;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 20px;
      margin: 0 auto 12px;
      padding: 16px;
    }
    .loadingIcon {
      margin: auto;
    }
    .form__error--success {
      background-color: #eff8e9;
      color: #4c8c01;
      border-color: #5aa700;
      margin: 0 auto 12px;
    }
    .field__error {
      color: #d0341f;
      font-size: 10px;
      letter-spacing: 0.2px;
      line-height: 12px;
      margin: -8px 0 12px;
    }
    button[type='submit'],
    input[type='submit'],
    .ais-InfiniteHits-loadMore,
    .btn {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: ${THEME.FONTS.HELVETICA};
      color: #fff;
      background: ${THEME.COLORS.PRIMARY};
      letter-spacing: 0.4px;
      line-height: 20px;
      padding: 12px 40px;
      text-align: center;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
    }
    .btn--sm {
      padding: 8px 30px;
    }
    .ais-InfiniteHits-loadMore--disabled {
      display: none;
    }
    .hover-scale {
      will-change: transform;
      transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
      transform: scale(100%);
    }
    .hover-scale:disabled:hover {
      transform: scale(100%);
    }
    .hover-scale:hover {
      transform: scale(102%);
    }
    .btn:disabled {
      cursor: not-allowed;
      background-color: #e8e8e8;
    }
    .btn:focus,
    .btn:hover,
    .btn--primary:focus,
    .btn--primary:hover {
      background-color: ${darkenColor(THEME.COLORS.PRIMARY, 5)};
    }
    .btn--secondary:hover,
    .btn--secondary:focus {
      background-color: transparent;
    }
    .btn--primary {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      background: ${THEME.COLORS.PRIMARY};
      letter-spacing: 0.4px;
      line-height: 20px;
      padding: 12px 40px;
      text-align: center;
      cursor: pointer;
    }
    .btn--secondary {
      border: 2px solid ${THEME.COLORS.SECONDARY};
      background-color: transparent;
      color: ${THEME.COLORS.SECONDARY};
      display: flex;
    }
    .btn--link {
      border: none;
      color: ${THEME.COLORS.TEXTCOLOR};
      background: none;
    }
    .btn--link:hover,
    .btn--link:focus {
      background: none;
    }
    .btn {
      display: inline-block;
      outline: none;
    }
    button[type='submit']:disabled {
      background: #e8e8e8;
      cursor: not-allowed;
    }
    hr {
      border: 0;
      border-top: 1px solid ${THEME.COLORS.LIGHTGRAY};
    }
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
    }
    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
    .col {
      flex: 1;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
      .col {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .row--noGutters {
      margin-left: 0;
      margin-right: 0;
    }
    .row--noGutters .col {
      padding-left: 0;
      padding-right: 0;
    }
    .row--scroll {
      flex-wrap: nowrap !important;
      overflow-x: auto;
      padding-top: 16px;
    }
    .col--main {
      flex-basis: 100%;
      min-width: 100%;
    }
    .col--sidebar {
      flex-basis: 100%;
      min-width: 100%;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      .col--main {
        min-width: 55%;
        flex-basis: 55%;
      }
      .col--sidebar {
        min-width: 45%;
        flex-basis: 45%;
      }
    }
    @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
      .col--main {
        min-width: 65%;
        flex-basis: 65%;
      }
      .col--sidebar {
        min-width: 35%;
        flex-basis: 35%;
      }
    }
    .container--form {
      max-width: 355px;
      padding-bottom: 100px;
    }
    /* react-select */
    .react-select__indicator-separator {
      display: none;
    }
    /* story content styles */
    .story__content p {
      font-size: 18px;
      line-height: 28px;
    }
    .story__content p a,
    .story__content p a:hover {
      color: #367dc4;
    }
    .story__content img,
    .story__content object,
    .story__content video,
    .story__content .oembed__wrapper,
    .story__content div.twitter-video {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto 15px auto;
      text-align: center;
    }
    .story__content div.hq-image {
      width: fit-content;
      margin: 0 auto;
    }
    .story__content .bs-image-hidden {
      display: none;
    }
    .story__content span.image-attribution a,
    .story__content span.image-attribution {
      color: #757575;
      font-size: 12px;
      font-style: italic;
      letter-spacing: 0.1px;
      line-height: 14px;
      text-decoration: none;
    }
    .story__content span.image-attribution {
      display: none;
    }
    .story__content video ~ span.image-attribution {
      display: block;
      margin-top: -10px;
      margin-bottom: 15px;
    }
    .story__content img ~ span.image-attribution {
      display: block;
      margin-top: -10px;
      margin-bottom: 15px;
    }
    .story__content blockquote:not(.twitter-video):not(.caffeine-embed) {
      display: block;
      width: 100%;
      margin: 8px 0 25px;
      padding: 16px 16px 16px 24px;
      border-left: 3px solid #77889a;
      font-style: italic;
      background: #f0f4f8;
      text-align: left;
    }
    .story__content blockquote:not(.twitter-video) p {
      font-style: italic;
      font-size: 16px;
      line-height: 1.4;
    }
    .story__content .videoPlayer {
      margin-bottom: 25px;
    }
    .podcastPlayer iframe {
      width: 100%;
    }
    iframe {
      border: none;
    }
    /* Additonal Video Player */
    .videoPlayer,
    .bs-videoPlayer {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .bs-videoPlayer__container video-js,
    .videoPlayer__container,
    .bs-videoPlayer__container {
      width: 100%;
      position: relative;
      height: 0;
      padding-top: 56.25%;
      overflow: hidden;
    }
    .bs-videoPlayer__container > iframe,
    .bs-videoPlayerContainer > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      .featuredVideo .videoPlayerLink__image {
        margin-bottom: -3px;
      }
    }

    /* job description styles */
    .job__description div,
    .job__description ul {
      line-height: 1.5;
    }
    .job__description ul {
      list-style: disc outside;
      padding-left: 0.625rem;
      float: none;
    }
    .job__description li {
      display: list-item;
      font-size: inherit;
      margin-left: 1.25rem;
      margin-bottom: 0.2rem;
    }
    /* Progress Bar */
    #nprogress {
      pointer-events: none;
    }
    #nprogress .bar {
      background: ${THEME.COLORS.PRIMARY};
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px ${THEME.COLORS.PRIMARY}, 0 0 5px ${THEME.COLORS.PRIMARY};
      opacity: 1;
      -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
      transform: rotate(3deg) translate(0px, -4px);
    }
    .ad--leaderboard,
    .ad--fixedBottom {
      text-align: center;
    }
    .htl-ad {
      z-index: 2;
    }
    .htl-ad[name='htlunit-in-article'] {
      background: #fff;
    }
    .siteNavigation__link {
      font-family: ${THEME.FONTS.BEBAS};
      font-size: 17.6px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.4px;
      line-height: 30px;
      color: ${THEME.COLORS.TEXTCOLOR};
      text-decoration: none;
      padding: 0 6.4px;
      white-space: nowrap;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }
    .siteNavigation__dropdown a:before {
      background-repeat: no-repeat;
      position: absolute;
      left: 50%;
      right: auto;
      transform: translate(-50%);
    }
    .siteNavigation__activeLink {
      color: ${THEME.COLORS.PRIMARY};
    }
    .siteNavigation__link--primary {
      font-size: 21.6px;
    }
    .siteNavigation__link--store::before {
      content: '';
      background-size: 1rem 0.5rem;
      background-image: url('/static/images/hat.png');
      width: 1rem;
      height: 0.5rem;
      top: 4px;
    }
    .siteNavigation__link:hover {
      color: ${THEME.COLORS.PRIMARY};
    }
    .siteNavigation__dropdown--active .dropdownMenu {
      display: block;
    }
    .siteNavigation__dropdown--active .siteNavigation__link {
      color: ${THEME.COLORS.PRIMARY};
    }
    .siteSubNavigation ul li a {
      display: block;
      color: ${THEME.COLORS.GRAY};
      font-size: 12px;
      letter-spacing: 0.1px;
      text-decoration: none;
      margin-right: 22px;
      white-space: nowrap;
    }
    .siteFooter__listItem a,
    .siteFooter__listItem a:visited {
      color: ${THEME.COLORS.TEXTCOLOR};
    }
    .sharingLink--sms {
      background-color: #33e223;
    }
    /* Instantsearch */
    .ais-SearchBox-form {
      display: flex;
      align-items: center;
      position: relative;
    }
    .ais-SearchBox-form .ais-SearchBox-reset {
      padding: 0;
      width: 0.75rem;
    }
    .ais-SearchBox-submit {
      display: none;
    }
    .ais-SearchBox-reset {
      display: none;
      position: absolute;
      right: 1rem;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
    }
    .ais-SearchBox-reset svg {
      display: none;
    }
    .ais-SearchBox-input {
      border: 1px solid ${THEME.COLORS.LIGHTGRAY};
    }
    .searchDropdown .ais-SearchBox-input {
      margin: 0;
    }
    .ais-SearchBox-input:valid ~ .ais-SearchBox-reset {
      display: block;
    }
    .ais-SearchBox-root {
      margin: 0;
    }
    .ais-InfiniteHits-list,
    .ais-Hits-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .ais-RefinementList-list {
      margin: 0;
      padding: 0.5rem 0;
    }
    .ais-RefinementList-item {
      display: flex;
      align-items: center;
      padding: 0.25rem 0.5rem;
      color: ${THEME.COLORS.TEXTCOLOR};
      transition: 300ms;
      line-height: 1;
      border-radius: 2px;
    }
    .ais-RefinementList-item + .ais-RefinementList-item {
      margin-top: 0.25rem;
    }
    .ais-RefinementList-item label {
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
      margin: 0;
      font-size: 15px;
      font-weight: normal;
    }
    .ais-RefinementList-item label .ais-RefinementList-labelText {
      flex-grow: 1;
      text-transform: capitalize;
    }
    .ais-RefinementList-item .ais-RefinementList-checkbox {
      display: none;
    }
    .ais-RefinementList-item .ais-RefinementList-count {
      background-color: #f1f1f1;
      padding: 0.125rem 0.5rem;
      border-radius: 0.125rem;
      font-size: 0.875rem;
      transition: 300ms;
    }
    .ais-RefinementList-item:hover {
      background-color: #f1f1f1;
      color: ${THEME.COLORS.TEXTCOLOR};
    }
    .ais-RefinementList-item--selected,
    .ais-RefinementList-item--selected:hover {
      background-color: ${THEME.COLORS.PRIMARY};
    }
    .ais-RefinementList-item--selected label {
      color: #fff;
    }
    .ais-RefinementList-item--selected label:before {
      content: url('/static/images/dismiss.svg');
      margin-right: 0.5rem;
    }
    .ais-RefinementList-item:hover .ais-RefinementList-count,
    .ais-RefinementList-item--selected .ais-RefinementList-count {
      background-color: #fff;
    }
    .ais-RefinementList-item--selected .ais-RefinementList-count {
      color: ${THEME.COLORS.TEXTCOLOR};
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      .siteNavigation__link {
        padding: 0 0.8rem;
      }
      .siteNavigation__link--primary {
        font-size: 1.5rem;
      }
      .siteNavigation__link--store:before {
        top: 16px;
      }
    }
    @media (min-width: ${THEME.BREAKPOINTS.LG + 1}px) {
      .siteSubNavigation ul li a {
        margin-right: 40px;
      }
    }
    .dropdownMenu {
      display: none;
    }
    .layout__overlay--active {
      display: block !important;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      .siteNavigation__dropdown--store .col--section {
        min-width: 15% !important;
        flex-basis: 15% !important;
      }
    }
    .trendingStories .simpleStoryCard {
      width: 120px;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      .trendingStories .simpleStoryCard {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .trendingStories .simpleStoryCard__imageContainer {
      display: none;
    }
    .trendingStories .simpleStoryCard__imageContainer--visible-mobile {
      display: block;
    }
    @media (min-width: ${THEME.BREAKPOINTS.LG + 1}px) {
      .trendingStories .simpleStoryCard__imageContainer {
        display: initial;
      }
    }
    .player-wrapper {
      position: relative;
      padding-top: 100%;
    }

    .player-wrapper .bs-react-player {
      position: absolute;
      top: 0;
      left: 0;
    }

    .bs-react-player .react-player__preview {
      background-size: contain !important;
      background-repeat: no-repeat !important;
    }

    /**
     * StoryCardIndicator styles
     */
    .bs-storyCardIndicator .svgIcon {
      height: 1.15rem;
      width: auto;
      fill: white;
      vertical-align: middle;
    }
    .bs-storyCardIndicator .iconPlay {
      position: relative;
      left: 2px;
    }
    .bs-storyCardIndicator .iconPlaylist {
      height: 1.3rem;
      position: relative;
      left: -5px;
    }
    .simpleStoryCard .bs-storyCardIndicator {
      bottom: 15px;
    }

    /**
     * Donately Overrides 
    */
    form.donately-donation-form {
      padding: 15px !important;
    }

    /**
     * Tooltip Styles
     */

    /* Base styles for the element that has a tooltip */
    [data-tooltip],
    .tooltip {
      position: relative;
      cursor: pointer;
    }

    /* Base styles for the entire tooltip */
    [data-tooltip]:before,
    [data-tooltip]:after,
    .tooltip:before,
    .tooltip:after {
      position: absolute;
      visibility: hidden;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      opacity: 0;
      -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      pointer-events: none;
    }

    /* Show the entire tooltip on hover and focus */
    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after,
    [data-tooltip]:focus:before,
    [data-tooltip]:focus:after,
    .tooltip:hover:before,
    .tooltip:hover:after,
    .tooltip:focus:before,
    .tooltip:focus:after {
      visibility: visible;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
      opacity: 1;
    }

    /* Base styles for the tooltip's directional arrow */
    .tooltip:before,
    [data-tooltip]:before {
      z-index: 1001;
      border: 6px solid transparent;
      background: transparent;
      content: '';
    }

    /* Base styles for the tooltip's content area */
    .tooltip:after,
    [data-tooltip]:after {
      z-index: 1000;
      padding: 8px;
      width: 160px;
      background-color: #000;
      background-color: hsla(0, 0%, 20%, 0.9);
      color: #fff;
      content: attr(data-tooltip);
      font-size: 14px;
      line-height: 1.2;
    }

    /* Directions */

    /* Top (default) */
    [data-tooltip]:before,
    [data-tooltip]:after,
    .tooltip:before,
    .tooltip:after,
    .tooltip-top:before,
    .tooltip-top:after {
      bottom: 100%;
      left: 50%;
    }

    [data-tooltip]:before,
    .tooltip:before,
    .tooltip-top:before {
      margin-left: -6px;
      margin-bottom: -12px;
      border-top-color: #000;
      border-top-color: hsla(0, 0%, 20%, 0.9);
    }

    /* Horizontally align top/bottom tooltips */
    [data-tooltip]:after,
    .tooltip:after,
    .tooltip-top:after {
      margin-left: -80px;
    }

    [data-tooltip]:hover:before,
    [data-tooltip]:hover:after,
    [data-tooltip]:focus:before,
    [data-tooltip]:focus:after,
    .tooltip:hover:before,
    .tooltip:hover:after,
    .tooltip:focus:before,
    .tooltip:focus:after,
    .tooltip-top:hover:before,
    .tooltip-top:hover:after,
    .tooltip-top:focus:before,
    .tooltip-top:focus:after {
      -webkit-transform: translateY(-12px);
      -moz-transform: translateY(-12px);
      transform: translateY(-12px);
    }

    /* Left */
    .tooltip-left:before,
    .tooltip-left:after {
      right: 100%;
      bottom: 50%;
      left: auto;
    }

    .tooltip-left:before {
      margin-left: 0;
      margin-right: -12px;
      margin-bottom: 0;
      border-top-color: transparent;
      border-left-color: #000;
      border-left-color: hsla(0, 0%, 20%, 0.9);
    }

    .tooltip-left:hover:before,
    .tooltip-left:hover:after,
    .tooltip-left:focus:before,
    .tooltip-left:focus:after {
      -webkit-transform: translateX(-12px);
      -moz-transform: translateX(-12px);
      transform: translateX(-12px);
    }

    /* Bottom */
    .tooltip-bottom:before,
    .tooltip-bottom:after {
      top: 100%;
      bottom: auto;
      left: 50%;
    }

    .tooltip-bottom:before {
      margin-top: -12px;
      margin-bottom: 0;
      border-top-color: transparent;
      border-bottom-color: #000;
      border-bottom-color: hsla(0, 0%, 20%, 0.9);
    }

    .tooltip-bottom:hover:before,
    .tooltip-bottom:hover:after,
    .tooltip-bottom:focus:before,
    .tooltip-bottom:focus:after {
      -webkit-transform: translateY(12px);
      -moz-transform: translateY(12px);
      transform: translateY(12px);
    }

    /* Right */
    .tooltip-right:before,
    .tooltip-right:after {
      bottom: 50%;
      left: 100%;
    }

    .tooltip-right:before {
      margin-bottom: 0;
      margin-left: -12px;
      border-top-color: transparent;
      border-right-color: #000;
      border-right-color: hsla(0, 0%, 20%, 0.9);
    }

    .tooltip-right:hover:before,
    .tooltip-right:hover:after,
    .tooltip-right:focus:before,
    .tooltip-right:focus:after {
      -webkit-transform: translateX(12px);
      -moz-transform: translateX(12px);
      transform: translateX(12px);
    }

    /* Move directional arrows down a bit for left/right tooltips */
    .tooltip-left:before,
    .tooltip-right:before {
      top: 3px;
    }

    /* Vertically center tooltip content for left/right tooltips */
    .tooltip-left:after,
    .tooltip-right:after {
      margin-left: 0;
      margin-bottom: -16px;
    }

    .tooltip-rounded::after {
      border-radius: 8px;
    }

    .tooltip-content-small::after {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0;
    }

    .liveVideos__control svg {
      height: 18px;
      width: auto;
      position: relative;
    }
    .liveVideos__control--prev svg {
      transform: rotate(180deg);
      left: -1px;
    }
    .liveVideos__control--next svg {
      right: -1px;
    }
    .liveVideos .indicator {
      position: absolute;
      top: 10px;
      right: 10px;
      width: auto;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      background: ${THEME.COLORS.PRIMARY};
      color: white;
      font-family: ${THEME.FONTS.HELVETICA};
      font-size: 16px;
      letter-spacing: 0.11px;
      line-height: 19px;
      white-space: nowrap;
    }
    .liveVideos .indicator .svgIcon {
      fill: white;
      height: 20px;
      width: auto;
      margin-right: 5px;
    }
    .liveVideos .controls {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      padding: 10px 16px;
    }
    .liveVideos .play .svgIcon {
      height: 20px;
      width: auto;
      fill: white;
    }
    @media (min-width: 37.5rem) {
      .liveVideos .play .svgIcon {
        height: 24px;
      }
    }
    .liveVideos .viewers {
      color: white;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.09px;
    }
    @media (min-width: 37.5rem) {
      .liveVideos .viewers {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .liveVideos__itemOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .liveVideos__itemOverlay--pre {
      background: rgba(0, 0, 0, 0.5);
    }
    .liveVideos__itemOverlay--pre .indicator {
      left: 50%;
      top: 50%;
      bottom: auto;
      right: auto;
      transform: translate(-50%, -50%);
      padding: 8px 18px;
      font-family: ${THEME.FONTS.HELVETICA};
      font-size: 16px;
      letter-spacing: 0.12px;
      line-height: 20px;
    }
    @media (min-width: 37.5rem) {
      .liveVideos--1 .liveVideos__itemOverlay--pre .indicator {
        font-size: 20px;
        line-height: 24px;
      }
      .liveVideos--1 .liveVideos__itemOverlay--pre .indicator .svgIcon {
        height: 26px;
      }
    }
    .liveVideo__label svg {
      margin-right: 8px;
    }
    .staticPage {
      margin: 1rem auto 2rem;
      box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
      background: #fff;
      border-top: none;
      display: block;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-size: 100%;
      letter-spacing: normal;
    }

    .staticPage__content {
      padding: 1rem 2rem;
      display: block;
    }

    .staticPage__header {
      padding: 0;
    }

    .staticPage__content p {
      margin-bottom: 1.5rem;
      line-height: 1.5;
      letter-spacing: 0.1px;
      font-size: 100%;
    }

    .staticPage__content h1 {
      font-size: 1.875rem;
      padding: 1rem 0 0;
      margin: 0;
      line-height: 2;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-weight: 600;
      display: block;
    }

    .staticPage__header h1 {
      line-height: 1.2;
      color: ${THEME.COLORS.TEXTCOLOR};
      font-weight: 600;
      display: block;
      font-size: 1.875rem;
      margin: 0;
      padding: 1rem 1.5rem 0;
    }

    .staticPage__content h2 {
      font-size: 1.125em;
      display: block;
      margin: 0 auto 0.5em;
      line-height: 120%;
      text-transform: uppercase;
      font-family: ${THEME.FONTS.HELVETICA};
      vertical-align: baseline;
      font-weight: 400;
      letter-spacing: 0.2px;
    }

    .staticPage__content h3 {
      font-family: ${THEME.FONTS.HELVETICA};
      font-weight: 600;
      margin: 0 auto 0.5em;
      line-height: 133%;
      vertical-align: baseline;
      box-sizing: border-box;
      font-size: 1em;
      display: block;
    }

    .staticPage__content ol {
      display: block;
      list-style-position: outside;
      margin: 0.5rem 0;
      padding-left: 40px;
      list-style-type: decimal;
      font-size: 100%;
      line-height: 1.5;
      vertical-align: baseline;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      color: ${THEME.COLORS.TEXTCOLOR};
      letter-spacing: normal;
    }

    .staticPage__content li {
      line-height: 1.5;
      letter-spacing: 0.1px;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
      display: list-item;
      text-align: -webkit-match-parent;
    }

    .staticPage a,
    .staticPage a:hover {
      color: #367dc4;
      text-decoration: none;
      cursor: pointer;
    }

    .ob-widget-header {
      font-family: ${THEME.FONTS.BEBAS} !important;
      text-transform: uppercase;
    }
    .bs-articleAd {
      text-align: center;
      margin-bottom: 10px;
    }

    .sportsBookForm .field__error {
      color: #c62232;
      font-family: ${THEME.FONTS.IBM};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }

    /* Sportsbook Form */
    .sportsBook__formField {
      box-sizing: border-box;
      border: 1px solid #b8b7b6;
      border-radius: 2px;
      background-color: #ffffff;
      color: #222;
      font-family: ${THEME.FONTS.IBM};
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.5px;
      line-height: 21px;
      text-transform: uppercase;
    }

    input.sportsBook__formField {
      text-transform: initial !important;
    }

    select.sportsBook__formField {
      cursor: pointer;
      padding-bottom: 0;
      padding-top: 0;
    }

    select.sportsBook__formField option {
      box-sizing: border-box;
      border: 1px solid #b8b7b6;
      border-radius: 2px;
      background-color: #ffffff;
      color: #222;
      font-family: ${THEME.FONTS.IBM};
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.5px;
      line-height: 21px;
      text-transform: uppercase;
    }

    /**
     * not properly working for some reason
     * should make the default placeholder disabled option a soft gray color
     */
    select.sportsBook__formField option:disabled {
      color: #828181;
    }

    /** 
     * not properly showing up for some reason 
     * should be a downward chevron at the end of the select input
     * */
    select.sportsBook__formField::after {
      content: '>';
      font: 17px 'Helvetica Neue';
      color: #333;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      right: 11px;
      top: 18px;
      padding: 0 0 2px;
      border-bottom: 1px solid #999;
      position: relative;
      pointer-events: none;
    }

    .sportsBook__formField::placeholder {
      color: #828181;
    }

    button.sportsBook__submitButton,
    button[type='submit'].sportsBook__submitButton {
      border-radius: 2px;
      background-color: #1555d0;
      color: #ffffff;
      font-family: ${THEME.FONTS.IBM};
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
    }

    button.sportsBook__secondaryButton,
    button[type='submit'].sportsBook__secondaryButton {
      color: #c62232;
      background: #fff;
      text-transform: uppercase;
    }

    button[type='submit'].sportsBook__submitButton--disabled {
      background-color: #e7e8e8;
      color: #b8b7b6;
      border-radius: 2px;
      font-family: ${THEME.FONTS.IBM};
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }

    /* GDPR */
    .qc-cmp-button,
    .qc-cmp-button.qc-cmp-secondary-button:hover {
      background-color: #c62232 !important;
      border-color: #c62232 !important;
    }
    .qc-cmp-button:hover,
    .qc-cmp-button.qc-cmp-secondary-button {
      background-color: transparent !important;
      border-color: #c62232 !important;
    }
    .qc-cmp-alt-action,
    .qc-cmp-link {
      color: #c62232 !important;
    }
    .qc-cmp-button,
    .qc-cmp-button.qc-cmp-secondary-button:hover {
      color: #ffffff !important;
    }
    .qc-cmp-button:hover,
    .qc-cmp-button.qc-cmp-secondary-button {
      color: #000000 !important;
    }
    .qc-cmp-small-toggle.qc-cmp-toggle-on,
    .qc-cmp-toggle.qc-cmp-toggle-on {
      background-color: ${THEME.COLORS.SECONDARY} !important;
      border-color: ${THEME.COLORS.SECONDARY} !important;
    }
    .qc-cmp-small-toggle,
    .qc-cmp-toggle {
      background-color: #c62232 !important;
      border-color: #c62232 !important;
    }
    a.qc-cmp-persistent-link {
      display: none !important;
      bottom: 60px !important;
    }

    /* Slick Slider */
    .slick-list,
    .slick-slider,
    .slick-track {
      position: relative;
      display: block;
    }
    .slick-loading .slick-slide,
    .slick-loading .slick-track {
      visibility: hidden;
    }
    .slick-slider {
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    .productCarousel .slick-list {
      padding-top: 16px;
    }
    .slick-list:focus {
      outline: 0;
    }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand;
    }
    .slick-slider .slick-list,
    .slick-slider .slick-track {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .slick-track {
      top: 0;
      left: 0;
    }
    .slick-track:after,
    .slick-track:before {
      display: table;
      content: '';
    }
    .slick-track:after {
      clear: both;
    }
    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
    }
    .slick-slide img {
      display: block;
    }
    .slick-slide.slick-loading img {
      display: none;
    }
    .slick-slide.dragging img {
      pointer-events: none;
    }
    .slick-initialized .slick-slide {
      display: block;
    }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
      display: none;
    }
    .slick-dots,
    .slick-next,
    .slick-prev {
      position: absolute;
      display: block;
      padding: 0;
    }
    .slick-next,
    .slick-prev {
      font-size: 0;
      line-height: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: 0;
      background: 0 0;
    }
    .slick-next:focus,
    .slick-next:hover,
    .slick-prev:focus,
    .slick-prev:hover {
      color: transparent;
      outline: 0;
      background: 0 0;
    }
    .slick-prev {
      left: -25px;
    }
    .slick-prev:before {
      content: none;
    }
    .slick-next {
      right: -25px;
    }
    .slick-dotted.slick-slider {
      margin-bottom: 30px;
    }
    .slick-dots {
      bottom: -25px;
      width: 100%;
      margin: 0;
      list-style: none;
      text-align: center;
    }
    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: 0;
      background: 0 0;
    }
    .slick-dots li button:focus,
    .slick-dots li button:hover {
      outline: 0;
    }

    /* Video.js */
    .vjs-poster {
      background-size: cover;
    }
    .video-js .vjs-picture-in-picture-control {
      display: none;
    }

    .videoPlayer__container.is-android .vjs-control-bar {
      display: flex !important;
    }

    /* temporary styles for sticky live-stream */
    .stickyVideoPlayer .video-js .vjs-big-play-button {
      display: none;
    }

    .live__comments [data-spot-im-module-default-area='pitc'] {
      display: none !important;
    }

    /* Fundraiser Global Styles */
    .barstoolFund .row {
      flex-wrap: wrap;
    }
    .barstoolFund .col {
      padding-left: 30px;
      padding-right: 30px;
    }
    .barstoolFund .row--buttons {
      justify-content: center;
      margin-left: -8px;
      margin-right: -8px;
    }
    .barstoolFund .col--left,
    .barstoolFund .col--right {
      min-width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .barstoolFund .col--button {
      min-width: 90%;
      max-width: 90%;
      flex-basis: 90%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .barstoolFund .col--button .btn {
      width: 100%;
    }
    .barstoolFund .row--buttons .btn--outline {
      margin-top: 20px;
    }
    @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
      .barstoolFund .row {
        flex-wrap: nowrap;
      }
      .barstoolFund .row--buttons {
        justify-content: flex-start;
      }
      .barstoolFund .row--buttons .btn--outline {
        margin-top: 0;
      }
      .barstoolFund .col--left {
        min-width: 45%;
        max-width: 45%;
        flex-basis: 45%;
      }
      .barstoolFund .col--right {
        min-width: 55%;
        max-width: 55%;
        flex-basis: 55%;
      }
      .barstoolFund .col--button {
        max-width: 50%;
        min-width: 50%;
        flex-basis: 50%;
      }
    }
    .barstoolFund .btn {
      padding: 12px 19.5px;
      letter-spacing: 1px;
    }
    .barstoolFund .btn--primary,
    .barstoolFund .btn--primary:hover,
    .barstoolFund .btn--primary:focus,
    .barstoolFund .btn--primary:active {
      background: #ba1930;
      border: 1px solid #ba1930;
    }
    .barstoolFund .btn--outline,
    .barstoolFund .btn--outline:hover,
    .barstoolFund .btn--outline:focus,
    .barstoolFund .btn--outline:active {
      background: #fff;
      border: 1px solid #ba1930;
      color: #ba1930;
    }
    .barstoolFund .panel {
      padding: 20px 0;
    }
    @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
      .barstoolFund .panel {
        padding: 60px;
      }
    }
    .barstoolFund .panel + .panel {
      border-top: 1px solid #979797;
    }
    .barstoolFund .panel .row {
      align-items: flex-start;
    }
    .barstoolFund .panel__image {
      width: 100%;
      height: auto;
    }
    .barstoolFund .title {
      font-size: 38px;
      line-height: 48px;
      color: ${THEME.COLORS.SECONDARY};
      margin: 20px 0 0;
    }
    .barstoolFund .subtitle {
      font-style: italic;
      font-size: 14px;
      color: #8a8e97;
    }
    .barstoolFund .title,
    .barstoolFund .subtitle {
      text-align: center;
    }
    @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
      .barstoolFund .title {
        margin-top: 0;
      }
      .barstoolFund .title,
      .barstoolFund .subtitle {
        text-align: left;
      }
    }
    @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
      .barstoolFund .title {
        font-size: 48px;
        line-height: 58px;
      }
    }
    .barstoolFund .description p {
      font-size: 14px;
      color: ${THEME.COLORS.SECONDARY};
      margin-bottom: 20px;
    }
    .barstoolFund .description p a {
      color: ${THEME.COLORS.SECONDARY};
      font-weight: 700;
    }
    .stickyVideoPlayer .vjs-menu-button {
      display: none;
    }

    .emailPage__form .btn,
    .emailPage__unsubscribe .btn {
      width: auto;
      padding: 12px, 20px, 12px, 20px;
      background: #c62232;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      text-transform: none;
    }

    .searchProducts {
      padding: 10px;
      background: #fff;
      border-bottom: 1px solid #d8d8d8;
    }
    .searchProducts__title {
      margin: 0 0 16px 0;
      padding: 0 10px;
    }
    .searchProducts .ais-Hits-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
    }
    .searchProducts .ais-Hits-item {
      min-width: 50%;
      flex-basis: 50%;
      padding: 0 8px;
      margin-bottom: 10px;
    }
    .searchProducts--feed .ais-Hits-list {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-top: 8px;
    }
    .searchProducts--feed .ais-Hits-item {
      min-width: 40%;
      flex-basis: 40%;
    }
    @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
      .searchProducts--feed .ais-Hits-list {
        overflow-x: unset;
      }
      .searchProducts .ais-Hits-item,
      .searchProducts--feed .ais-Hits-item {
        min-width: 25%;
        flex-basis: 25%;
        margin-bottom: 0;
      }
    }

    .ugc-form select {
      color: #000;
    }
    .ugc-form select:focus,
    .ugc-form select:focus-visible {
      border: none;
      outline: 0;
    }
    .ugc-form .file-upload-label {
      border: 2px solid #666;
    }
    .ugc-form .label-errored {
      border: 1px solid #eb3e3e;
      margin-bottom: 4px;
    }
    .ugc-form .browse-cta {
      color: #eb3e3e;
      padding: 8px 16px;
      border-radius: 24px;
      line-height: 1;
      font-weight: 700;
      background: transparent;
      border: 1px solid #eb3e3e;
    }
    .ugc-form .checkbox {
      margin: 0 0 4px !important;
    }

    /** Taboola Ad **/
    .trc_rbox_header_span,
    .tbl-feed-header-text {
      font-family: ${THEME.FONTS.BEBAS} !important;
    }
    .video-js {
      & * {
        outline: none;
      }
      & .vjs-big-play-button {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 2em;
        height: 2em;
        line-height: 2em;
        border: none;
        border-radius: 50%;
        font-size: 4.5em;
        background-color: rgba(0, 0, 0, 0.45);
        color: #fff;
      }
      &:hover .vjs-big-play-button,
      & .vjs-big-play-button:focus {
        background: ${THEME.COLORS.PRIMARY};
      }
      & .vjs-vtt-thumbnail-display {
        position: absolute;
        transition: transform 0.1s, opacity 0.2s;
        bottom: 85%;
        pointer-events: none;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
      }
    }

    /** DK Disclaimer **/
    #dk-disclaimer-desktop {
      display: none;
    }
    @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
      #dk-disclaimer-desktop {
        display: block;
      }
      #dk-disclaimer-mobile {
        display: none;
      }
    }

    /** Merch Care Package Form **/
    .merch-preferences-other .field-container {
      border: none;
      background-color: transparent;
      outline: none;
      margin: 0;
      padding: 0;
    }
    .merch-preferences-other .field-input {
      border: none;
      background-color: transparent;
      outline: none;
      margin: 0;
      padding: 10px 0 4px;
      border-bottom: 1px solid #d8d8d8;
    }

    /** Viral Video Form **/
    #viral-video-form > .checkbox label {
      margin: 0;
    }
    #viral-video-form .checkbox {
      margin: 0 0 16px;
    }
  `}</style>
)

export default GlobalStyles
