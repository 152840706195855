import decode from 'jwt-decode'
import { v4 as uuid } from 'uuid'
import { isServer } from 'utils'

const StorageKeys = Object.freeze({
  authToken: 'BS_AUTH_TOKEN',
  clientId: 'BS_CLIENT_ID',
  user: 'BS_USER',
  viralVotes: 'BS_VIRAL_VOTES',
  legacyAuthToken: 'user',
  whosBettorTournamentAuth: 'WBT_AUTH'
})

class Storage {
  constructor() {
    this._generateClientId()
  }

  get clientId() {
    return this._get(StorageKeys.clientId)
  }

  get authToken() {
    return this._get(StorageKeys.authToken)
  }

  set authToken(newValue) {
    this._set(StorageKeys.authToken, newValue)
  }

  get viralVotes() {
    return this._getJSON(StorageKeys.viralVotes) || {}
  }

  set viralVotes(newValue) {
    this._setJSON(StorageKeys.viralVotes, newValue)
  }

  get userId() {
    const authToken = this._get(StorageKeys.authToken)
    if (!authToken) return null
    try {
      const decodedToken = decode(authToken)
      return decodedToken.data.id
    } catch (error) {
      // clear the storage as they are logged out if the token is invalid
      this.authToken = null
      return null
    }
  }

  get user() {
    return this._getJSON(StorageKeys.user)
  }

  set user(userDetails) {
    this._setJSON(StorageKeys.user, userDetails)
  }

  get loggedIn() {
    return !!this.authToken
  }

  // auth for the Who's Bettor Tournament
  get wbtAuth() {
    return this._getJSON(StorageKeys.whosBettorTournamentAuth)
  }

  set wbtAuth(newAuth) {
    if (!newAuth.id || !newAuth.password) return
    this._setJSON(StorageKeys.whosBettorTournamentAuth, newAuth)
  }

  _generateClientId() {
    if (this._get(StorageKeys.clientId)) {
      return
    }
    this._set(StorageKeys.clientId, uuid())
  }

  _getJSON(key) {
    try {
      const value = this._get(key)
      return JSON.parse(value)
    } catch (err) {
      return null
    }
  }

  _setJSON(key, value) {
    try {
      const json = JSON.stringify(value)
      return this._set(key, json)
    } catch (err) {
      return false
    }
  }

  _get(key) {
    if (isServer) {
      return null
    }
    return window.localStorage.getItem(key)
  }

  _set(key, newValue) {
    if (isServer) {
      return false
    }
    if (newValue) {
      return window.localStorage.setItem(key, newValue)
    } else {
      return window.localStorage.removeItem(key)
    }
  }
}

export default new Storage()
