import classNames from 'classnames'
import { THEME } from 'utils'

export function AdSkeleton({ slotName = '', background = '#fff', image = false, text = false, className = '' }) {
  return (
    <>
      <div
        className={classNames(
          'ad_skeleton',
          'flex items-center justify-center z-0 absolute top-0 left-0 w-full h-full',
          className
        )}
      >
        {image && (
          <img
            src='/images/stool.png'
            alt=''
            className={classNames({
              'inline-block h-full py-1 md:py-2': slotName === 'leaderboard-top' || slotName === 'leaderboard-in-feed'
            })}
          />
        )}
        {text && <h2 className={classNames('absolute m-0 uppercase bottom-3 left-3 text-[18px]')}>Advertisement</h2>}
      </div>
      <style jsx>{`
        .ad_skeleton {
          background-color: ${background};
        }
        .ad_skeleton h2 {
          line-height: 18px;
          letter-spacing: 0.9px;
          font-family: ${THEME.FONTS.BEBAS};
          color: #8f8f8f;
        }
      `}</style>
    </>
  )
}
