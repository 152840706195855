import { useRef } from 'react'
import { enableStaticRendering } from 'mobx-react-lite'
import { isServer } from 'utils'
import isEqual from 'lodash/isEqual'

enableStaticRendering(isServer)

const _storeCache = {}

/**
 * Return a store inside a react hooks context
 *
 * @param {Object} Store
 * @param {Object} initialData
 * @return {Store}
 */
export const useStore = (Store, data) => {
  const initialData = useRef(null)

  if (!Store.cacheKey) {
    throw new Error(`Store (${Store.name}) is missing a cacheKey`)
  }

  const store = _storeCache[Store.cacheKey] || Store()
  _storeCache[Store.cacheKey] = store

  // If we dont have any data to hydrate just return the store
  if (!data) {
    return store
  }

  // If we're on the client and data is equal to initial data then don't hydrate again
  if (isServer === false && isEqual(initialData.current, data)) {
    return store
  }

  // Data is different, we must hydrate with new data
  store.hydrate(data)

  // Track new initial data
  initialData.current = data

  return store
}

/**
 * Return a store outside a react hooks context
 *
 * @param {Object} Store
 * @param {Object} initialData
 * @return {Store}
 */
export const withStore = (Store) => {
  if (!Store.cacheKey) {
    throw new Error(`Store (${Store.name}) is missing a cacheKey`)
  }

  const store = _storeCache[Store.cacheKey] || Store()
  _storeCache[Store.cacheKey] = store

  return store
}

/**
 * Reset all stored stores
 */
export const resetStoreCache = () => {
  for (const key of Object.keys(_storeCache)) {
    _storeCache[key] = undefined
  }
}
