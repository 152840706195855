import React from 'react'
import { THEME } from 'utils'

export default function TwitterIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg
      viewBox='0 0 34.47 33'
      className='svgIcon socialIcon iconTwitter'
      style={{ width, height, fill: color }}
      aria-labelledby='twitter-icon'
    >
      <title id='twitter-icon'>Twitter Icon</title>
      <path d='M34.47,5.81a13.94,13.94,0,0,1-4.06,1.11A7.11,7.11,0,0,0,33.52,3,14.23,14.23,0,0,1,29,4.73a7.08,7.08,0,0,0-12,6.45A20.07,20.07,0,0,1,2.41,3.79,7.08,7.08,0,0,0,4.6,13.22a7.07,7.07,0,0,1-3.2-.88v.09a7.07,7.07,0,0,0,5.67,6.93,7.08,7.08,0,0,1-1.86.25,7.26,7.26,0,0,1-1.33-.13,7.09,7.09,0,0,0,6.6,4.91,14.18,14.18,0,0,1-8.78,3A14,14,0,0,1,0,27.32,20,20,0,0,0,10.84,30.5C23.85,30.5,31,19.72,31,10.38c0-.31,0-.61,0-.92a14.23,14.23,0,0,0,3.53-3.65Z' />
    </svg>
  )
}
