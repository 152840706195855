import { useState, useEffect, useRef } from 'react'
import { isServer } from 'utils'

/**
 *
 * @param {Function} callback
 */
export function usePageVisibility(callback = () => {}) {
  const savedCallback = useRef()
  const [isVisible, setIsVisible] = useState(getIsDocumentVisible())
  const onVisibilityChange = (args) => {
    if (getIsDocumentVisible()) {
      setIsVisible(true)
      savedCallback.current(args)
    } else {
      setIsVisible(false)
    }
  }

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the event listener.
  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()

    window.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      window.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })

  return isVisible
}

function getBrowserVisibilityProp() {
  if (!isServer && typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }
}

function getIsDocumentVisible() {
  if (isServer) return false
  return !document[getBrowserDocumentHiddenProp()]
}
