import { useState, useEffect, useCallback } from 'react'

// hook to achieve a debounce effect. The callback function will be memoized and
// set in a timeout. That timeout will be cleared and reset only if one of the deps changes
export const useDebounce = (callback, interval, deps) => {
  const [lastTimeoutId, setLastTimeoutId] = useState(null)

  const memoizedCallback = useCallback(callback, deps)

  useEffect(() => {
    clearTimeout(lastTimeoutId)
    setLastTimeoutId(setTimeout(memoizedCallback, interval))
    return () => {
      clearTimeout(lastTimeoutId)
    }
  }, deps)
}
