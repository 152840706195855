import { UnionResource } from 'api/resource'

class UserApi extends UnionResource {
  constructor() {
    super('user')
  }
  async update(id, data) {
    const result = await this.put({ url: `/${this.resource}/${id}`, data })
    return result.data
  }
  async updatePassword(data) {
    const result = await this.put({ url: `/${this.resource}/profile/password`, data })
    return result
  }
}

export default UserApi
