import { UnionResource } from 'api/resource'

class CommentsApi extends UnionResource {
  constructor() {
    super('comments')
  }
  async findByStoryId(storyId) {
    const result = await this.get({ url: `/stories/id/${storyId}/comments` })
    return result
  }
  async create(storyId, data) {
    const result = await this.post({ url: `/stories/id/${storyId}/comments/add`, data })
    return result
  }
  async vote(id, vote) {
    const result = await this.post({ url: `/comment/${id}/vote/${vote}` })
    return result
  }
}

export default CommentsApi
