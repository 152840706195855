import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

export function useFind(service, params, config) {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => service.find(params)
  return useSWR([`/${service.resource}`, cacheKey], fetcher, config)
}

export function useFindImmutable(service, params, config) {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => service.find(params)
  return useSWRImmutable([`/${service.resource}`, cacheKey], fetcher, config)
}

export function useFindById(service, id, params, config) {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => service.findById(id, params ?? {})
  return useSWR([`/${service.resource}/${id}`, cacheKey], fetcher, config)
}
