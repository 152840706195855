import { observable, flow } from 'mobx'
import { brandsApi } from 'api'
import { CommonStore } from 'stores/common'

export const BrandsStore = () => {
  const findBySlug = flow(function* (slug) {
    const response = yield this.api.findBySlug(slug)
    this.current = response
    return response
  })

  const findNavBrands = flow(function* (params) {
    const response = yield this.api.find({ is_featured: true, ...params })
    const sortedResponse = response.sort(
      (a, b) => b.sites.barstoolsports.featured_sort - a.sites.barstoolsports.featured_sort
    )
    this.navBrands = sortedResponse
    return sortedResponse
  })

  const readWithVideo = flow(function* (id) {
    const params = {
      populate: 'sizzle.video(id,type,duration,primary_source,thumbnail)'
    }
    try {
      this.isLoading = true
      const current = yield this.api.findById(id, params)
      this.current = current
      return current
    } finally {
      this.isLoading = false
    }
  })

  return observable({
    ...CommonStore,
    api: brandsApi,
    navBrands: [],
    findBySlug,
    findNavBrands,
    readWithVideo
  })
}

BrandsStore.cacheKey = 'BrandsStore'

export default BrandsStore
