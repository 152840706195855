import { useMemo } from 'react'
import { isServer } from 'utils'

export const urlKey = '__bs_external'

export const providers = {
  criteo: 'criteo',
  iframely: 'iframely',
  minutemedia: 'minutemedia',
  nativo: 'nativo',
  spotimComments: 'spotim_comments',
  spotimAds: 'spotim_ads',
  greenhouse: 'greenhouse',
  hashtagLabs: 'hashtag-labs'
}

export const activeProviders = isServer ? null : _buildActiveProviders()

export function useExternalProvider(name) {
  const active = useMemo(() => {
    return activeProviders?.has(name) !== false
  }, [activeProviders])

  return [active, activeProviders]
}

export function withExternalProvider(name) {
  const active = activeProviders?.has(name) !== false
  return [active, activeProviders]
}

function _buildActiveProviders() {
  const url = new URL(window.location.href)
  const providerString = url?.searchParams.get(urlKey) ?? null
  if (providerString === null) {
    return null
  }
  if (providerString[0] === '-') {
    const excludeProviders = new Set(providerString.split(',').map((str) => str.replace(/^-/g, '')))
    const allProviders = Object.values(providers)
    return new Set(allProviders.filter((p) => excludeProviders.has(p) === false))
  } else {
    return new Set(providerString.split(','))
  }
}
