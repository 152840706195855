import { useState, useEffect } from 'react'

export const useInnerHeight = () => {
  const [innerHeight, setInnerHeight] = useState(null)
  useEffect(() => {
    const updateInnerHeight = () => {
      setInnerHeight(window.innerHeight)
    }
    updateInnerHeight()
    window.addEventListener('resize', updateInnerHeight)
    return () => {
      window.removeEventListener('resize', updateInnerHeight)
    }
  }, [])
  return innerHeight
}
