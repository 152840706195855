import AuthApi from 'api/auth'
import AuthorsApi from 'api/authors'
import BrandsApi from 'api/brands'
import CommentsApi from 'api/comments'
import ConfigApi from 'api/config'
import ContentLayoutsApi from 'api/content-layouts'
import AthletesApi from 'api/athletes'
import TheDozenScoresApi from 'api/dozen-scores'
import TheDozenScheduleApi from 'api/dozen-schedule'
import ApplicantsApi from 'api/applicants'
import EventsApi from 'api/events'
import FeedSubscriptionsApi from 'api/feedSubscriptions'
import FormsApi from 'api/forms'
import FundsApi from 'api/funds'
import JobsApi from 'api/jobs'
import KlaviyoApi from 'api/klaviyo'
import LeaguesApi from 'api/leagues'
import MediaApi from 'api/media'
import PlaylistsApi from 'api/playlists'
import PodcastEpisodesApi from './podcast-episodes'
import StatusApi from 'api/status'
import StoreApi from 'api/store'
import StoriesApi from 'api/stories'
import TeamsApi from 'api/teams'
import UserApi from 'api/user'
import ViralApi from 'api/viral'
import SportsbookLeaderboardResource from './sportsbook-leaderboard'
import GoogleSheetsApi from './google-sheets'
import UGCStoreApi from './ugc'
import InfluencersApi from './influencers'

const authApi = new AuthApi()
const authorsApi = new AuthorsApi()
const brandsApi = new BrandsApi()
const commentsApi = new CommentsApi()
const configApi = new ConfigApi()
const contentLayoutsApi = new ContentLayoutsApi()
const athletesApi = new AthletesApi()
const applicantsApi = new ApplicantsApi()
const theDozenScoresApi = new TheDozenScoresApi()
const theDozenScheduleApi = new TheDozenScheduleApi()
const eventsApi = new EventsApi()
const feedSubscriptionsApi = new FeedSubscriptionsApi()
const formsApi = new FormsApi()
const fundsApi = new FundsApi()
const googleSheetsApi = new GoogleSheetsApi()
const jobsApi = new JobsApi()
const klaviyoApi = new KlaviyoApi()
const leaguesApi = new LeaguesApi()
const mediaApi = new MediaApi()
const playlistsApi = new PlaylistsApi()
const podcastEpisodesApi = new PodcastEpisodesApi()
const statusApi = new StatusApi()
const storeApi = new StoreApi()
const storiesApi = new StoriesApi()
const teamsApi = new TeamsApi()
const userApi = new UserApi()
const viralApi = new ViralApi()
const sportsbookLeaderboardApi = new SportsbookLeaderboardResource()
const ugcStoreApi = new UGCStoreApi()
const influencersApi = new InfluencersApi()

export {
  athletesApi,
  applicantsApi,
  authApi,
  authorsApi,
  brandsApi,
  commentsApi,
  configApi,
  contentLayoutsApi,
  theDozenScoresApi,
  theDozenScheduleApi,
  eventsApi,
  feedSubscriptionsApi,
  formsApi,
  fundsApi,
  googleSheetsApi,
  jobsApi,
  klaviyoApi,
  leaguesApi,
  mediaApi,
  playlistsApi,
  podcastEpisodesApi,
  statusApi,
  storeApi,
  storiesApi,
  teamsApi,
  userApi,
  viralApi,
  sportsbookLeaderboardApi,
  ugcStoreApi,
  influencersApi
}
