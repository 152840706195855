import { applyFastlyParams } from './image'

export const mapSearchHitToStory = ({ hit }) => {
  return {
    id: hit.objectID,
    brand_id: null,
    comment_count: hit.comment_count,
    title: hit.title,
    type: hit.type,
    slug: hit.url.split('/').pop(),
    thumbnail: {
      desktop: applyFastlyParams(hit.image_lrg, { canvas: '4:3', 'bg-color': 'FFFFFF' })
    },
    author: {
      name: hit.authors[0],
      avatar: null
    },
    date: new Date(hit.publish_date * 1000),
    is_search_result: true
  }
}

export const mapSearchHitToProduct = ({ hit, query, location = 'search-results-page' }) => {
  return {
    id: hit.objectID,
    images: [
      {
        originalSrc: hit.image
      }
    ],
    title: hit.title,
    price: hit.price,
    handle: `${hit.handle}?utm_source=barstoolsports&utm_medium=${location}&utm_term=${query}`,
    is_search_result: true
  }
}
