import { useEffect } from 'react'

export const useConfirmPageChange = (confirmMessage = 'Are you sure you want to leave this page?', active = true) => {
  useEffect(() => {
    const confirmPageChange = (e) => {
      e.preventDefault()
      e.returnValue = confirmMessage
      return confirmMessage
    }
    if (active) window.addEventListener('beforeunload', confirmPageChange)
    return () => {
      window.removeEventListener('beforeunload', confirmPageChange)
    }
  }, [confirmMessage, active])
}
