import { useEffect, useState } from 'react'
import { usePageUrl } from 'hooks/page-url'
import { useTaboolaScripts } from 'utils/taboola-scripts'
import { withTaboola } from 'utils/taboola'
import useWindowWidth from 'hooks/window-width'
import { THEME, isMobile } from 'utils'

export function useTaboola() {
  const url = usePageUrl()
  const [pageRenders, setPageRenders] = useState(0)
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [railsRendered, setRailsRendered] = useState(false)
  const windowWidth = useWindowWidth()
  const taboolaPage = ['/', '/blog/[id]/[slug]'].includes(url.pathname)
  const { setup_home, setup_article, ur_home, lr_home, ur_article, lr_article, feed, belowArticle, fallback } =
    useTaboolaScripts(url.path)

  useEffect(() => {
    if (taboolaPage) {
      setPageRenders(pageRenders + 1)
      setAdsLoaded(false)
    }
  }, [url.id])

  function checkFallbackDiv(attempt) {
    const fallbackDiv = document.getElementById('taboola-stream-1x1-thumbnails_fallback')
    if (fallbackDiv) {
      withTaboola((taboola) => {
        taboola.push(fallback)
        taboola.push({ flush: true })
      })
    } else {
      setTimeout(() => {
        attempt++
        if (attempt < 10) {
          checkFallbackDiv(attempt)
        } else {
          withTaboola((taboola) => {
            taboola.push({ flush: true })
          })
        }
      }, 500)
    }
  }

  useEffect(() => {
    if (!adsLoaded && taboolaPage) {
      const isWeb = window?.innerWidth >= THEME.BREAKPOINTS.SM && !isMobile()
      withTaboola((taboola) => {
        if (pageRenders > 0) {
          taboola.push({ notify: 'newPageLoad' })
        }
        if (url.id) {
          taboola.push(setup_article)
          if (isWeb) {
            taboola.push(ur_article)
            taboola.push(lr_article)
            setRailsRendered(true)
          }
          taboola.push(belowArticle)
          taboola.push({ flush: true })
        } else {
          taboola.push(setup_home)
          taboola.push(feed)
          if (isWeb) {
            taboola.push(ur_home)
            taboola.push(lr_home)
            setRailsRendered(true)
          }
          checkFallbackDiv(0)
        }
      })
      setAdsLoaded(true)
    }
  }, [pageRenders])

  useEffect(() => {
    if (windowWidth >= THEME.BREAKPOINTS.SM && !railsRendered && taboolaPage) {
      withTaboola((taboola) => {
        if (url.id) {
          taboola.push(ur_article)
          taboola.push(lr_article)
        } else {
          taboola.push(ur_home)
          taboola.push(lr_home)
        }
        setRailsRendered(true)
      })
    }
  }, [windowWidth])
}
