import { useStore } from 'hooks'
import { NotificationStore } from 'stores/notification'

export const useToast = () => {
  const notificationStore = useStore(NotificationStore)

  const showNotification = (type, message, duration) => {
    notificationStore.addNotification({ type, message, duration })
  }

  const showSuccessNotification = (message, duration = 3000) => {
    showNotification('success', message, duration)
  }

  const showInfoNotification = (message, duration = 3000) => {
    showNotification('info', message, duration)
  }

  const showWarningNotification = (message, duration = 3000) => {
    showNotification('warning', message, duration)
  }

  const showErrorNotification = (message, duration = 3000) => {
    showNotification('error', message, duration)
  }

  return { showSuccessNotification, showInfoNotification, showWarningNotification, showErrorNotification }
}
