import { useRef, useState, useEffect } from 'react'
import { useStore } from 'hooks'
import { observer } from 'mobx-react-lite'
import { getVerityKeyValues } from 'utils'
import ConfigStore from 'stores/config'
import VideoJsPlayer from 'components/VideoPlayer'
import type { PlayerOptions, MuxVideoData, AdsRequestParams, IMASettings, Story, StoryVideoSource } from 'api/types'
import { getAnonymousID } from 'lib/whoami'
import { useStatus } from 'hooks/data/status'
import { getPublicaResponse, isServer } from 'utils'
import { getQueryParam } from 'utils/query'
import { createVideoAdTagUrl } from 'utils/video-player'
import { getPublisherProvidedId } from 'hooks/hashtag-labs'

const MUX_DATA_KEY = 'cvk1v6obaq000bvruglo2mrqb'

interface StoryVideoPlayerProps {
  story: Story
  videoSource: StoryVideoSource
  playNextVideo?: () => void
  autoplay?: boolean
  live?: boolean
  hideChapters?: boolean
  stickyPlayer?: boolean
  startTime?: number
  inArticle?: boolean
  adStory?: Story
}

type ConfigStore = {
  current?: {
    videos: {
      ima: {
        baseUrl: string
        params: Record<any, any>
      }
    }
  }
}

function StoryVideoPlayer(props: StoryVideoPlayerProps) {
  const {
    story,
    videoSource,
    playNextVideo,
    hideChapters = false,
    stickyPlayer = false,
    startTime = 0,
    inArticle = false,
    adStory
  } = props
  const [metadata, setMetadata] = useState<Record<string, string>>({})
  const adIndex = useRef(0)
  const configStore = useStore(ConfigStore, {}) as ConfigStore

  const { data: status } = useStatus()
  const autoplay = props?.autoplay ?? props.live ?? props.stickyPlayer ?? false

  const options: PlayerOptions = {
    src: videoSource.sources,
    poster: videoSource.poster,
    autoplay
  }

  useEffect(() => {
    async function fetchMetadata() {
      const viewer_user_id = await getAnonymousID()

      const _metadata = {
        viewer_user_id
      }
      setMetadata(_metadata)
    }

    fetchMetadata().catch(console.error)
  }, [])

  const muxData: MuxVideoData = {
    env_key: process.env.MUX_DATA_KEY || MUX_DATA_KEY,
    video_id: String(story.id),
    video_variant_id: story?.post_type_meta?.barstool_original?.id && String(story.post_type_meta.barstool_original.id),
    video_title: story.title,
    video_series: story?.brand_name || story?.author?.name,
    video_stream_type: story.type === 'live' ? 'live' : 'on-demand',
    viewer_user_id: metadata?.viewer_user_id
  }

  const adsRequestParams: AdsRequestParams = {
    contentTitle: story.title,
    contentKeywords: getVerityKeyValues(story).verity_keywords,
    pageUrl: story?.urls?.barstoolsports
  }

  async function generateAdTagUrl() {
    try {
      const imaConfig = configStore?.current?.videos?.ima
      if (isServer) return
      const ppid = await getPublisherProvidedId()
      const adPosition =
        adIndex.current === 0
          ? 'preroll'
          : adIndex.current === videoSource?.cue_points?.length - 1
          ? 'postroll'
          : 'midroll'
      adIndex.current = adPosition === 'postroll' ? 0 : adIndex.current + 1

      const ip = status?.client?.ip || ''
      const publicaParams = await getPublicaResponse(videoSource, story, adPosition, ip)
      const adtar = getQueryParam('adtar')

      const url = await createVideoAdTagUrl({
        ip,
        ppid,
        story,
        videoSource,
        inArticle,
        muted: autoplay || stickyPlayer,
        playback: [autoplay, stickyPlayer, props.live, inArticle].includes(true) ? 'auto' : 'click',
        params: imaConfig?.params,
        adBaseUrl: imaConfig?.baseUrl,
        publicaParams: publicaParams || '',
        adStory,
        ...(adtar ? { adTarget: adtar } : {})
      })

      return {
        ppid,
        adTagUrl: url
      } as IMASettings
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <VideoJsPlayer
      startTime={startTime}
      videoSource={videoSource}
      options={options}
      muxData={muxData}
      hideChapters={hideChapters}
      stickyPlayer={stickyPlayer}
      playNextVideo={playNextVideo}
      live={props.live}
      adsRequestParams={adsRequestParams}
      onSetupIMA={generateAdTagUrl}
    />
  )
}

export default observer(StoryVideoPlayer)
