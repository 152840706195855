import { useState, useEffect } from 'react'

/**
 * useWindowWidth hook
 * Returns the width of the window.
 * @returns {number} The width of the window.
 */

function useWindowWidth() {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)

      const handleResize = () => {
        setWidth(window.innerWidth)
      }

      window.addEventListener('resize', handleResize)

      window.addEventListener('orientationchange', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('orientationchange', handleResize)
      }
    }
  }, [])

  return width
}

export default useWindowWidth
