export const useTaboolaScripts = (url) => {
  const setup_home = {
    homepage: 'auto',
    url: url
  }

  const setup_article = {
    article: 'auto',
    url: url
  }

  const ur_home = {
    container: 'taboola-stream-5x1-thumbnails',
    mode: 'thumbnails-stream-home-5x1',
    placement: 'Stream 5x1 Thumbnails',
    target_type: 'mix'
  }

  const lr_home = {
    container: 'taboola-right-rail-home-thumbnails',
    mode: 'thumbnails-rr-home',
    placement: 'Right Rail Home Thumbnails',
    target_type: 'mix'
  }

  const ur_article = {
    container: 'taboola-right-rail-thumbnails',
    mode: 'thumbnails-rr',
    placement: 'Right Rail Thumbnails',
    target_type: 'mix'
  }

  const lr_article = {
    container: 'taboola-lower-rail-thumbnails',
    mode: 'thumbnails-lr',
    placement: 'Lower Rail Thumbnails',
    target_type: 'mix'
  }

  const feed = {
    mode: 'thumbnails-stream-home-1x1',
    container: 'taboola-stream-1x1-thumbnails',
    placement: 'Stream 1x1 Thumbnails - b',
    target_type: 'mix'
  }

  const belowArticle = {
    mode: 'alternating-thumbnails-a',
    container: 'taboola-below-article-thumbnails',
    placement: 'Below Article Thumbnails',
    target_type: 'mix'
  }

  const fallback = {
    mode: 'thumbnails-stream-home-1x1',
    container: 'taboola-stream-1x1-thumbnails_fallback',
    placement: 'Stream 1x1 Thumbnails - a',
    target_type: 'mix'
  }

  return {
    setup_home,
    setup_article,
    ur_home,
    lr_home,
    ur_article,
    lr_article,
    feed,
    belowArticle,
    fallback
  }
}
