import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import unescape from 'lodash/unescape'
import { POST_TYPES } from 'utils'

export const truncateString = (string, characterCount) =>
  string.length > characterCount ? string.slice(0, characterCount) + '...' : string

export const truncateHtmlString = (string, characterCount) =>
  truncateString(decodeHtmlString(string.replace(/<[^>]+>/g, '')), characterCount)

export const decodeHtmlString = (string) => unescape(string)

export const capitalizeAndDashCase = (string) => string.split('_').map(capitalize).join('-')

export const slugToText = (string) => startCase(string.toLowerCase())

export const storyLink = (story) => {
  let href = `/${POST_TYPES[story.type]}/[id]/[slug]`
  let as = `/${POST_TYPES[story.type]}/${story.id}/${story.slug}`
  return { href, as, prefetch: false }
}

export const authorLink = (author) => {
  const slug = author.author_url.split('/').pop()
  return {
    href: '/bio/[id]/[slug]',
    as: `/bio/${author.id}/${slug}`,
    prefetch: false
  }
}

export const playlistLink = (playlist) => {
  let href = `/playlist/[id]/[slug]`
  let as = `/playlist/${playlist.id}/${playlist.slug}`
  return { href, as, prefetch: false }
}

export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export function stripHtml(str) {
  if (!str) return ''
  return str.replace(/(<([^>]+)>|&nbsp;)/gi, '')
}
