import React from 'react'

export default function PhoneIcon({ width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 11 21' className='svgIcon iconPhone' style={{ width, height }}>
      <defs>
        <filter x='-0.2%' y='-2.0%' width='100.3%' height='104.0%' filterUnits='objectBoundingBox' id='filter-1'>
          <feOffset dx='0' dy='1' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.909803922   0 0 0 0 0.909803922   0 0 0 0 0.909803922  0 0 0 1 0'
            type='matrix'
            in='shadowOffsetOuter1'
            result='shadowMatrixOuter1'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-1240.000000, -23.000000)' fill='#000000' fillRule='nonzero'>
          <g>
            <g filter='url(#filter-1)'>
              <g transform='translate(1180.000000, 21.000000)'>
                <g transform='translate(53.000000, 0.000000)'>
                  <g transform='translate(7.000000, 2.000000)'>
                    <path d='M9.71,16 L1.25,16 L1.25,2 L9.71,2 L9.71,16 Z M4.70885714,18.4464286 C4.70885714,18.0248571 5.04742857,17.6828571 5.46514286,17.6828571 C5.883,17.6828571 6.22142857,18.0248571 6.22142857,18.4464286 C6.22142857,18.8678571 5.883,19.2104286 5.46514286,19.2104286 C5.04742857,19.2098571 4.70885714,18.8678571 4.70885714,18.4464286 L4.70885714,18.4464286 Z M10.811,1.63619972 L10.811,1.6325185 C10.8087143,1.23208364 10.4682857,0.908 10.048,0.908 C10.045,0.908 10.0425714,0.908272683 10.0398571,0.908272683 L8.326,0.908 L3.326,0.908 L0.842,0.908 C0.422285714,0.908272683 0.0821428571,1.23235632 0.0798571429,1.6325185 L0.0797142857,19.1743466 C0.0805714286,19.1819817 0.0797142857,19.1894805 0.0797142857,19.1973883 C0.0797142857,19.589779 0.425285714,19.908 0.851714286,19.908 C0.858,19.908 0.864142857,19.907182 0.870428571,19.9070456 L10.0584286,19.908 C10.4732857,19.908 10.8108571,19.5899154 10.8108571,19.1973883 C10.811,19.1896169 10.81,19.1819817 10.8097143,19.174483 L10.811,1.63619972 Z' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
