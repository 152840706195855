import React, { useEffect, useState } from 'react'
import { useStore } from 'hooks'
import { THEME } from 'utils'
import Star from 'icons/Star'
import CloseIcon from 'icons/Close'
import Spacer from 'components/ui/Spacer'
import UserStore from 'stores/user'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import useAppInfo from 'hooks/app-info'

function SmartBanner({ deviceType }) {
  const userStore = useStore(UserStore)
  const { data, isLoading } = useAppInfo()
  const [displayBanner, setDisplayBanner] = useState(true)

  const router = useRouter()
  const pathname = router.asPath.split('?')[0]
  const universalLinkURL = `${process.env.UNION_API}/app-link${pathname || ''}`

  useEffect(() => {
    const isApp = window.self !== window.top
    setDisplayBanner(!isApp)
  }, [])

  return (
    !userStore.closedBanner &&
    displayBanner &&
    !isLoading && (
      <>
        <div className='smart-banner'>
          <button
            style={{ width: '10px', height: '10px', margin: '0' }}
            onClick={() => {
              userStore.closeBanner()
            }}
          >
            <CloseIcon width='10px' height='10px' color='#999999' />
          </button>
          <Spacer size='15px' />
          <img alt='smart-banner-logo' src='/static/images/app-logo.png' />
          <div>
            <h2>Barstool Sports</h2>
            <div className='starWrapper'>
              {Array(data?.rating)
                .fill()
                .map((index) => (
                  <Star key={index} fill='#999999' />
                ))}
              <p>{data?.reviewCount}K+</p>
            </div>
            <h3>#VivaLaStool</h3>
          </div>
          <a href={universalLinkURL}>OPEN</a>
        </div>
        <style jsx>{`
          .smart-banner {
            background: linear-gradient(180deg, #253a54 0%, #152a33 100%);
            display: flex;
            align-items: center;
            padding: 8px 16px;
            color: #fff;
          }
          .smart-banner > img {
            width: 64px;
            height: 64px;
            border: 1px solid rgba(216, 216, 216, 0.25);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
            border-radius: 12px;
            margin-right: 10px;
          }
          .smart-banner h2 {
            font-size: 14px;
            margin: 0;
            font-weight: 500;
          }
          .smart-banner h3 {
            font-size: 11px;
            color: #d8d8d8;
            margin: 0;
            font-weight: 500;
          }
          .smart-banner a {
            margin: 0;
            margin-left: auto;
            background: #eb3e3e;
            color: #fff;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
            border-radius: 999px;
            padding: 7px 13px;
            font-weight: 700;
            font-size: 15px;
            line-height: 17px;
          }
          .starWrapper {
            display: flex;
            margin: 3px 0 5px;
            align-items: center;
          }
          .starWrapper p {
            font-size: 11px;
            font-weight: 500;
            color: #999999;
            margin: 0 0 0 2px;
            line-height: 1;
            height: 10px;
          }
          @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
            .smart-banner {
              display: none;
            }
          }
        `}</style>
      </>
    )
  )
}

export default observer(SmartBanner)
