import { UGCResource, UnionResource } from 'api/resource'

class UGCStoreApi extends UGCResource {
  constructor() {
    super('images')
  }

  async postSubmission(data) {
    const result = await this.post({ url: `/${this.resource}`, data })
    return result
  }
}

export default UGCStoreApi
