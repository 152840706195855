import React from 'react'
import { THEME } from 'utils'

export default function ExternalLinkIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 20 20' fill='none' style={{ width, height }}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.08639 4.99986H1.36296V18.6361H14.9925V10.9089H16.3555V18.6361V19.9998H14.9925H1.36296H0V18.6361V4.99986V3.63623H1.36296H9.08639V4.99986Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.847 7.75466C17.0045 7.91219 17.2737 7.80062 17.2737 7.57784L17.2737 2.97724C17.2737 2.83914 17.1618 2.72718 17.0238 2.72718L12.4254 2.72718C12.2027 2.72718 12.0912 2.99653 12.2487 3.15406L13.7286 4.63472C13.8262 4.73237 13.8262 4.8907 13.7286 4.98836L6.20659 12.5141C6.10899 12.6117 6.10899 12.7701 6.20659 12.8677L7.13814 13.7997C7.23574 13.8974 7.394 13.8974 7.4916 13.7997L15.0136 6.274C15.1112 6.17634 15.2695 6.17634 15.3671 6.274L16.847 7.75466Z'
        fill={color}
      />
    </svg>
  )
}
