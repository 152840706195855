import { useEffect, useMemo, useState } from 'react'
import { AuthStore } from 'stores/auth'
import StatusStore from 'stores/status'
import UserStore from 'stores/user'
import { isServer } from 'utils'
import { useStore } from './store'

export const useUserId = () => {
  const authStore = useStore(AuthStore)
  const [id, setId] = useState(isServer ? null : authStore.userId)
  useEffect(() => {
    setId(authStore.userId)
  }, [authStore.loggedIn])
  return id
}

export const useLoggedInUser = () => {
  const authStore = useStore(AuthStore)
  const userStore = useStore(UserStore)
  const userDetails = userStore.user ?? {}

  const user = useMemo(
    () => ({
      loggedIn: authStore.loggedIn,
      id: authStore?.userId ?? userDetails.id,
      email: userDetails.email,
      name: userDetails.full_name,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name
    }),
    [authStore.loggedIn, userDetails.id]
  )
  return user
}
