import { useState, useEffect } from 'react'

import { useInterval } from 'hooks'

export const useUspVisibility = (options) => {
  const { forceVisible } = options

  const [isVisible, setIsVisible] = useState(forceVisible || false)
  const [uspApiReady, setUspApiReady] = useState(false)

  useInterval(() => {
    if (window && window.__uspapi && typeof window.__uspapi === 'function') {
      setUspApiReady(true)
    }
  }, 1000)

  useEffect(() => {
    if (window && uspApiReady) {
      if (typeof window.__uspapi === 'function') {
        window.__uspapi('uspPing', 1, function (obj, status) {
          if (status && obj.mode.includes('USP') && obj.jurisdiction.includes(obj.location.toUpperCase())) {
            setIsVisible(true)
          }
        })
      }
    }
  }, [uspApiReady])

  return { isVisible }
}

export default useUspVisibility
