const debugLevels = ['error', 'warn', 'info', 'debug']

let currentDebugLevel = debugLevels.indexOf(process.env.DEBUG_LEVEL)

export class Logger {
  constructor(prefix) {
    this.prefix = prefix
  }
  log(...args) {
    if (currentDebugLevel < debugLevels.indexOf('debug')) return
    this._print('log', ...args)
  }
  info(...args) {
    if (currentDebugLevel < debugLevels.indexOf('info')) return
    this._print('info', ...args)
  }
  warn(...args) {
    if (currentDebugLevel < debugLevels.indexOf('warn')) return
    this._print('warn', ...args)
  }
  error(...args) {
    if (currentDebugLevel < debugLevels.indexOf('error')) return
    this._print('error', ...args)
  }
  _print(type, ...args) {
    console[type](`[${this.prefix}] `, ...args)
  }
}

export const setGlobalLogging = ({ bssdebug } = {}) => {
  if (bssdebug) {
    currentDebugLevel = debugLevels.length
  }
}

export const withLogger = (prefix = 'Barstool') => {
  return new Logger(prefix)
}

export default Logger
