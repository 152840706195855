import React from 'react'
import { THEME } from 'utils'

export default function FacebookIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg
      viewBox='0 0 15 33'
      className='svgIcon socialIcon iconFacebook'
      style={{ width, height, fill: color }}
      aria-labelledby='facebook-icon'
    >
      <title id='facebook-icon'>Facebook Icon</title>
      <path d='M10.31,7.13H15V1.5H10.31A6.57,6.57,0,0,0,3.75,8.06v2.81H0V16.5H3.75v15H9.38v-15h4.69L15,10.88H9.38V8.06A1,1,0,0,1,10.31,7.13Z' />
    </svg>
  )
}
