import { UnionResource } from 'api/resource'

class StoreApi extends UnionResource {
  constructor() {
    super('shopify-storefront')
  }
  async graphql(text) {
    const result = await this.find({
      query: Buffer.from(text).toString('base64')
    })
    return result
  }
}

export default StoreApi
