import { observable } from 'mobx'
import { contentLayoutsApi } from 'api'
import { CommonStore } from 'stores/common'

export const ContentLayoutsStore = () => {
  return observable({
    ...CommonStore,
    api: contentLayoutsApi
  })
}

ContentLayoutsStore.cacheKey = 'ContentLayoutsStore'

export default ContentLayoutsStore
