export const states = Object.freeze({
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
})

export const statesAsSelectOptions = Object.freeze([
  { id: 'AL', value: 'AL', label: 'Alabama' },
  { id: 'AK', value: 'AK', label: 'Alaska' },
  { id: 'AZ', value: 'AZ', label: 'Arizona' },
  { id: 'AR', value: 'AR', label: 'Arkansas' },
  { id: 'CA', value: 'CA', label: 'California' },
  { id: 'CO', value: 'CO', label: 'Colorado' },
  { id: 'CT', value: 'CT', label: 'Connecticut' },
  { id: 'DE', value: 'DE', label: 'Delaware' },
  { id: 'DC', value: 'DC', label: 'District Of Columbia' },
  { id: 'FL', value: 'FL', label: 'Florida' },
  { id: 'GA', value: 'GA', label: 'Georgia' },
  { id: 'HI', value: 'HI', label: 'Hawaii' },
  { id: 'ID', value: 'ID', label: 'Idaho' },
  { id: 'IL', value: 'IL', label: 'Illinois' },
  { id: 'IN', value: 'IN', label: 'Indiana' },
  { id: 'IA', value: 'IA', label: 'Iowa' },
  { id: 'KS', value: 'KS', label: 'Kansas' },
  { id: 'KY', value: 'KY', label: 'Kentucky' },
  { id: 'LA', value: 'LA', label: 'Louisiana' },
  { id: 'ME', value: 'ME', label: 'Maine' },
  { id: 'MD', value: 'MD', label: 'Maryland' },
  { id: 'MA', value: 'MA', label: 'Massachusetts' },
  { id: 'MI', value: 'MI', label: 'Michigan' },
  { id: 'MN', value: 'MN', label: 'Minnesota' },
  { id: 'MS', value: 'MS', label: 'Mississippi' },
  { id: 'MO', value: 'MO', label: 'Missouri' },
  { id: 'MT', value: 'MT', label: 'Montana' },
  { id: 'NE', value: 'NE', label: 'Nebraska' },
  { id: 'NV', value: 'NV', label: 'Nevada' },
  { id: 'NH', value: 'NH', label: 'New Hampshire' },
  { id: 'NJ', value: 'NJ', label: 'New Jersey' },
  { id: 'NM', value: 'NM', label: 'New Mexico' },
  { id: 'NY', value: 'NY', label: 'New York' },
  { id: 'NC', value: 'NC', label: 'North Carolina' },
  { id: 'ND', value: 'ND', label: 'North Dakota' },
  { id: 'OH', value: 'OH', label: 'Ohio' },
  { id: 'OK', value: 'OK', label: 'Oklahoma' },
  { id: 'OR', value: 'OR', label: 'Oregon' },
  { id: 'PA', value: 'PA', label: 'Pennsylvania' },
  { id: 'RI', value: 'RI', label: 'Rhode Island' },
  { id: 'SC', value: 'SC', label: 'South Carolina' },
  { id: 'SD', value: 'SD', label: 'South Dakota' },
  { id: 'TN', value: 'TN', label: 'Tennessee' },
  { id: 'TX', value: 'TX', label: 'Texas' },
  { id: 'UT', value: 'UT', label: 'Utah' },
  { id: 'VT', value: 'VT', label: 'Vermont' },
  { id: 'VA', value: 'VA', label: 'Virginia' },
  { id: 'WA', value: 'WA', label: 'Washington' },
  { id: 'WV', value: 'WV', label: 'West Virginia' },
  { id: 'WI', value: 'WI', label: 'Wisconsin' },
  { id: 'WY', value: 'WY', label: 'Wyoming' }
])
