import React from 'react'
import Countdown from 'react-countdown'

const TimeDependent = ({ date, renderBefore, renderAfter }) => {
  return (
    <Countdown
      date={date}
      renderer={({ completed, ...restProps }) => {
        if (completed) {
          if (renderAfter) {
            return renderAfter(restProps)
          }
          return null
        }
        if (renderBefore) {
          return renderBefore(restProps)
        }
        return null
      }}
    />
  )
}

export default TimeDependent
