export const formatWithCommas = (number, decimals = 0) => {
  if (typeof number === 'string') {
    return number
  }
  const value = decimals ? parseFloat(number).toFixed(decimals) : parseInt(number)
  if (toLocaleStringSupportsOptions()) {
    return value.toLocaleString('en-us')
  }
  return value.toLocaleString()
}

export const addSuffix = (number) => {
  // Handle the teens
  if (number >= 10 && number <= 20) {
    return formatWithCommas(number) + 'th'
  }

  switch (number % 10) {
    case 1:
      return formatWithCommas(number) + 'st'
    case 2:
      return formatWithCommas(number) + 'nd'
    case 3:
      return formatWithCommas(number) + 'rd'
    default:
      return formatWithCommas(number) + 'th'
  }
}

export const abbreviateNumber = (number) => {
  const SI_SUFFIXES = ['', 'K', 'M', 'G', 'T', 'P', 'E']
  // what tier? (determines SI prefix)
  const tier = (Math.log10(number) / 3) | 0
  // if zero, we don't need a suffix
  if (tier === 0) return number.toString()
  // get suffix and determine scale
  const suffix = SI_SUFFIXES[tier]
  const scale = Math.pow(10, tier * 3)
  // scale the number
  const scaled = number / scale
  // format number and add prefix as suffix
  return scaled.toFixed(1).replace('.0', '') + suffix
}

export const formatPrizeData = (prizePayouts) => {
  return prizePayouts.map(({ ranks, prize }) => {
    const label = ranks.length === 2 ? `${addSuffix(ranks[0])} - ${addSuffix(ranks[1])}` : addSuffix(ranks[0])
    return {
      label,
      values: [prize]
    }
  })
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Checking_for_support_for_locales_and_options_arguments
function toLocaleStringSupportsOptions() {
  return !!(typeof Intl == 'object' && Intl && typeof Intl.NumberFormat == 'function')
}
