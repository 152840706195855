import { observable, flow } from 'mobx'
import { userApi } from 'api'
import { CommonStore } from 'stores/common'
import storage from 'lib/storage'

const updateUserStorage = (userInfo) => {
  storage.user = {
    id: userInfo.id,
    email: userInfo.email,
    full_name: userInfo.full_name,
    first_name: userInfo.first_name,
    last_name: userInfo.last_name
  }
}

export const UserStore = () => {
  const update = flow(function* (id, data) {
    try {
      this.isUpdating = true
      const current = yield this.api.update(id, data)
      this.current = current
      updateUserStorage(current)
      return current
    } finally {
      this.isUpdating = false
    }
  })

  const findById = flow(function* (id, params) {
    try {
      this.isLoading = true
      const current = yield this.api.findById(id, params)
      this.current = current
      updateUserStorage(current)
      return current
    } finally {
      this.isLoading = false
    }
  })

  const updatePassword = flow(function* (data) {
    const response = yield this.api.updatePassword(data)
    return response
  })

  const getAccessLevel = function () {
    if (!this.current) return 0
    if (this.current.membership && ['active', 'canceled'].includes(this.current.membership.status)) {
      return this.current.membership.access_level
    } else {
      return 25
    }
  }

  const closeBanner = function () {
    this.closedBanner = true
  }

  return observable({
    ...CommonStore,
    api: userApi,
    findById,
    update,
    updatePassword,
    getAccessLevel,
    closeBanner,
    get user() {
      return this.current || storage.user
    }
  })
}

UserStore.cacheKey = 'UserStore'

export default UserStore
