import React from 'react'

import { useUspVisibility } from 'hooks'

const CCPALink = ({ style, forceVisible = false, children }) => {
  const { isVisible } = useUspVisibility({ forceVisible })

  const handleUspReprompt = () => {
    window?.__uspapi?.('displayUspUi')
  }

  if (!isVisible && !forceVisible) {
    return null
  }
  return (
    <a onClick={handleUspReprompt} style={{ ...style, cursor: 'pointer' }}>
      {children || 'Do Not Sell My Info'}
    </a>
  )
}

export default CCPALink
