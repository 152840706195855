import React from 'react'
import { THEME } from 'utils'

export default function MyFeedIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg className='svgIcon iconMyFeed' viewBox='0 0 22 22' style={{ width, height }}>
      <title>My Feed</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <polygon
          stroke={color}
          strokeWidth='2'
          points='11 16.4376941 6.05572809 19 7 13.572949 3 9.72949017 8.52786404 8.9376941 11 4 13.472136 8.9376941 19 9.72949017 15 13.572949 15.9442719 19'
        />
        <g transform='translate(12.000000, 2.000000)'>
          <circle fill='#C62232' fillRule='evenodd' cx='4.05' cy='3.8' r='3.8'></circle>
          <text fontSize='5.7' fontWeight='bold' fill='#FFFFFF'>
            <tspan x='3.22487793' y='6'>
              !
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}
