export function mapMediaDocToVideojsObject(media) {
  return {
    duration: media.type === 'video/live' ? -1 : parseInt(media.duration * 1000),
    sources: [media.primary_source, ...(media.alternate_sources ?? [])],
    poster_sources: [{ src: media.thumbnail }],
    poster: media.thumbnail,
    cue_points: media.cue_points || [],
    text_tracks: media.subtitles || [],
    advertising: media.advertising
  }
}

export const isVideoSource = (src) => {
  const extension = src?.includes('.') ? src.split?.('.').pop() : ''
  if (!extension) return false
  return /(flv|mp4|m3u8|3gp|ts|mov|avi|wmv)$/i.test(extension)
}

export const isImageSource = (src) => {
  const extension = src?.includes('.') ? src.split?.('.').pop() : ''
  if (!extension) return false
  return /(jpg|gif|png|jpeg)$/i.test(extension)
}

export const mimeTypeToExtension = Object.freeze({
  'video/mp4': '.mp4',
  'video/x-m4v': '.m4v',
  'video/quicktime': '.mov',
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/gif': '.gif'
})
