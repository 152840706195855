import CommonStore from './common'
import { observable, flow } from 'mobx'
import { statusApi } from 'api'

const StatusStore = () => {
  const find = flow(function* (params) {
    try {
      this.isLoading = true
      this.current = yield this.api.find(params)
    } finally {
      this.isLoading = false
    }
    return this.current
  })

  return observable({
    ...CommonStore,
    api: statusApi,
    find
  })
}

StatusStore.cacheKey = 'StatusStore'
export default StatusStore
