import React from 'react'
import { observer } from 'mobx-react-lite'
import SmartLink from 'components/SmartLink'
import { track } from 'lib/whoami'
import { ConfigStore } from 'stores/config'
import { useStore } from 'hooks'
import { siteHeaderEvents } from 'lib/ga'
import { THEME } from 'utils'

const SiteSubNavigation = () => {
  const configStore = useStore(ConfigStore)
  if (!configStore.current?.trending?.length) return null

  const handleSiteSubNavigationLinkCDPEvent = (item) => {
    if (!item) return
    track('ContentClicked', {
      content: {
        src: `https://barstoolsports.com${item?.href}`,
        type: 'Link',
        componentName: 'SiteSubNavigation'
      }
    })
  }

  return (
    <nav className='siteSubNavigation'>
      <div className='container'>
        <ul>
          <li className='title'>Trending Topics</li>
          {configStore.current.trending.map((item, idx) => (
            <li key={idx}>
              <SmartLink
                item={item}
                onClick={() => {
                  siteHeaderEvents.navLink(`Trending - ${item.label}`, item.external)
                  handleSiteSubNavigationLinkCDPEvent(item)
                }}
              >
                {item.label}
              </SmartLink>
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        .siteSubNavigation {
          background: #fff;
          border-bottom: 1px solid ${THEME.COLORS.LIGHTGRAY};
        }
        .siteSubNavigation ul {
          border-top: 1px solid ${THEME.COLORS.LIGHTGRAY};
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          flex-flow: row nowrap;
          overflow-x: auto;
          list-style: none;
          padding: 15px 0;
          margin: 0;
        }
        .siteSubNavigation ul li.title {
          color: ${THEME.COLORS.GRAY};
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          white-space: nowrap;
          letter-spacing: 0.6px;
          margin-right: 22px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .siteSubNavigation ul {
            justify-content: center;
          }
          .siteSubNavigation ul li.title {
            margin-right: 30px;
          }
          .siteSubNavigation ul li a {
            margin-right: 40px;
          }
        }
      `}</style>
    </nav>
  )
}

export default observer(SiteSubNavigation)
