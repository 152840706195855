import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react-lite'
import { useStore, useInterval } from 'hooks'
import { sendEvent } from 'lib/ga'
import { THEME, getVideoSecondsSinceStart, parseStoryUrl } from 'utils'
// import { useStickyPlayer } from 'hooks/stickyPlayer'
import ExternalLinkIcon from 'icons/ExternalLink'
import CloseIcon from 'icons/Close'
import StoryVideoPlayer from 'components/StoryVideoPlayer'
import { StickyVideosStore } from 'stores/sticky-videos'
import { NavigationStore } from 'stores/navigation'

const StickyVideoPlayer = observer(({ pathname }) => {
  const stickyVideosStore = useStore(StickyVideosStore)
  const navigationStore = useStore(NavigationStore)
  const router = useRouter()
  // remove temporarily for sticky live stream
  // const shouldRender = useStickyPlayer({
  //   story: toJS(stickyVideosStore.story),
  //   pathname: router.pathname,
  //   asPath: router.asPath
  // })
  const shouldRender = true
  useEffect(() => {
    async function findStickyVideo() {
      await stickyVideosStore.findStickyVideo()
    }

    findStickyVideo()
  }, [pathname])
  useInterval(() => stickyVideosStore.findStickyVideo(), 30 * 1000)
  const handleClose = () => {
    sendEvent('Brightcove Player - Sticky', 'Close Player', {
      label: `${stickyVideosStore.current.id} | ${stickyVideosStore.current.name}`
    })
    stickyVideosStore.dismiss()
  }
  const handleLink = () => {
    sendEvent('Brightcove Player - Sticky', 'View Story', {
      label: `${stickyVideosStore.current.id} | ${stickyVideosStore.current.name}`
    })
    let path = parseStoryUrl(stickyVideosStore.story)
    if (stickyVideosStore.story.start_date) {
      path += `?t=${getVideoSecondsSinceStart(stickyVideosStore.story.start_date)}`
    }
    router.push(path)
  }

  if (!stickyVideosStore.current || !shouldRender || !stickyVideosStore.story) {
    return null
  }
  // Check if story is scheduled_story content layout
  if (stickyVideosStore.story.start_date) {
    // Check that start_time has passed and duration is longer than time elapsed
    if (
      getVideoSecondsSinceStart(stickyVideosStore.story.start_date) > stickyVideosStore.current.duration / 1000 ||
      getVideoSecondsSinceStart(stickyVideosStore.story.start_date) < 0
    ) {
      return null
    }
  }
  return (
    <div key={stickyVideosStore.story.id}>
      <div className='stickyVideoPlayer' onClick={() => navigationStore.setStickyVideoInteraction(true)}>
        <a className='stickyVideoPlayer__icon stickyVideoPlayer__icon--close' onClick={handleClose}>
          <CloseIcon color='#fff' width='auto' height='15px' />
        </a>
        <a className='stickyVideoPlayer__icon stickyVideoPlayer__icon--link' onClick={handleLink}>
          <ExternalLinkIcon color='#fff' width='auto' height='15px' />
        </a>
        <StoryVideoPlayer
          story={stickyVideosStore.story}
          videoSource={stickyVideosStore.current}
          hideChapters
          stickyPlayer
          live
          // startTime={getVideoSecondsSinceStart(stickyVideosStore.story.start_date)}
        />
      </div>
      <style jsx>{`
        .stickyVideoPlayer {
          position: fixed;
          bottom: 55px;
          right: 10px;
          width: 200px;
        }
        .stickyVideoPlayer__icon {
          position: absolute;
          top: 10px;
          z-index: 10;
          cursor: pointer;
          filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
        }
        .stickyVideoPlayer__icon--close {
          left: 10px;
        }
        .stickyVideoPlayer__icon--link {
          right: 10px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .stickyVideoPlayer {
            bottom: 30px;
            right: 30px;
            width: 300px;
          }
          .stickyVideoPlayer__icon--close {
            right: 10px;
            left: auto;
          }
          .stickyVideoPlayer__icon--link {
            left: 10px;
            right: auto;
          }
        }
      `}</style>
    </div>
  )
})

export default StickyVideoPlayer
