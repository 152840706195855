import { observable, flow } from 'mobx'
import { contentLayoutsApi } from 'api'
import { CommonStore } from 'stores/common'
import { StoriesStore } from 'stores/stories'
import { withStore } from 'hooks'
import { CONTENT_LAYOUTS, CONTENT_LAYOUT_SECTIONS } from 'constants/contentLayouts'

export const StickyVideosStore = () => {
  const storiesStore = withStore(StoriesStore)
  const findStickyVideo = flow(function* () {
    if (this.dismissed) return
    let story = storiesStore.liveItems.filter((item) => item?.post_type_meta?.live?.sticky_player)[0]
    if (!story) {
      const response = yield this.api.find({ key: CONTENT_LAYOUTS.STICKY_PLAYER })
      if (response.length) {
        story = response[0].sections
          .find((section) => {
            return section.key === CONTENT_LAYOUT_SECTIONS.STICKY_VIDEO
          })
          .slots.find((slot) => {
            return slot.position === 'video'
          }).data
      }
    }
    this.story = story
    const videoSource = yield this.findStickyVideoSource(story?.id)
    return videoSource
  })
  const findStickyVideoSource = flow(function* (id) {
    if (!id) return null
    const response = yield this.api.get({
      url: `/stories/${id}/video-source`,
      params: { parse_manifest: true }
    })
    this.current = response
    return response
  })
  const dismiss = flow(function* () {
    this.current = null
    this.story = null
    this.dismissed = true
    yield
  })
  return observable({
    ...CommonStore,
    api: contentLayoutsApi,
    story: null,
    dismissed: false,
    findStickyVideo,
    findStickyVideoSource,
    dismiss
  })
}

StickyVideosStore.cacheKey = 'StickyVideosStore'

export default StickyVideosStore
