import React from 'react'
import { THEME, convertToDuration } from 'utils'

const VideoChapters = ({ videoSource, onClick, currentTime = 0, duration }) => {
  const chapters = videoSource.cue_points.filter((cp) => cp.type === 'CHAPTER')
  if (!chapters.length) return null
  for (let i = 0; i < chapters.length; i++) {
    chapters[i].endTime = chapters[i + 1] ? chapters[i + 1].time : duration
  }
  return (
    <>
      <div className='videoChapters'>
        <div className='videoChapters__header'>
          <h2 className='videoChapters__title'>Jump to Segment:</h2>
        </div>
        <div className='videoChapters__body'>
          {chapters.map((chapter) => {
            return (
              <div
                key={chapter.id}
                className={`videoChapter ${
                  currentTime &&
                  Math.floor(currentTime) >= Math.floor(chapter.time) &&
                  Math.floor(currentTime) < Math.floor(chapter.endTime)
                    ? 'videoChapter--active'
                    : ''
                }`}
                onClick={async () => await onClick?.(chapter)}
              >
                <span className='videoChapter__timestamp'>{convertToDuration(chapter.time * 1000)}</span>
                {chapter.name}
              </div>
            )
          })}
        </div>
      </div>
      <style jsx>{`
        .videoChapters__header {
          border-bottom: 1px solid #d8d8d8;
        }
        .videoChapters__title,
        .videoChapter {
          font-family: ${THEME.FONTS.HELVETICA};
          color: #999999;
          padding: 10px 15px;
        }
        .videoChapters__title {
          display: inline-block;
          margin: 0 0 -1px 0;
          font-size: 11px;
          line-height: 13px;
          font-weight: 700;
          text-transform: uppercase;
          border-bottom: 1px solid ${THEME.COLORS.PRIMARY};
        }
        .videoChapter {
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;
        }
        .videoChapter--active {
          font-weight: 700;
          color: #000;
        }
        .videoChapter__timestamp {
          display: inline-block;
          margin-right: 8px;
        }
        .videoChapter + .videoChapter {
          border-top: 1px solid #d8d8d8;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .videoChapters {
            margin-top: 20px;
          }
          .videoChapters__title,
          .videoChapter {
            padding-left: 0;
            padding-right: 0;
          }
        }
      `}</style>
    </>
  )
}

export default VideoChapters
