import Router from 'next/router'

export const httpCache = ({ res }, { swr = 300, maxAge = 60, sie = 86400, isPrivate = false } = {}) => {
  if (isPrivate) {
    res.setHeader('Cache-Control', 'private, no-cache')
  } else if (swr) {
    res.setHeader(
      'Cache-Control',
      `public, max-age=0, s-maxage=${maxAge}, stale-while-revalidate=${swr}, stale-if-error=${sie}`
    )
  } else {
    res.setHeader('Cache-Control', `public, max-age=0, s-maxage=${maxAge}, stale-if-error=${sie}`)
  }
}

export const httpError = (statusCode, message) => {
  const error = new Error(message)
  error.status = statusCode
  error.statusCode = statusCode
  throw error
}

export const httpRedirect = (location, { res, statusCode = 301 } = {}) => {
  if (res) {
    httpCache({ res })
    res.writeHead(statusCode, { location })
    return res.end()
  }
  Router.replace(location)
}

export const storyCanonicalRedirect = (story, basePath, params) => {
  const currentPath = `/${basePath}/${params.id}/${params.slug}`
  const canonicalPath = new URL(story.urls.barstoolsports).pathname
  if (currentPath !== canonicalPath && canonicalPath.includes('/gold/') === false) {
    return {
      redirect: canonicalPath
    }
  }
}
