import tinycolor from 'tinycolor2'
import localFont from 'next/font/local'

const COLORS = {
  PRIMARY: '#EB3E3E',
  SECONDARY: '#141E30',
  CRIMSON: '#C62232',
  YELLOW: '#F0C030',
  GOLD: '#E8A207',
  GRAY: '#757575',
  NAVY: '#141E30',
  LIGHTGRAY: '#D8D8D8',
  LIGHTERGRAY: '#E3E3E3',
  LIGHTESTGRAY: '#F9F9F9',
  BACKGROUND_GRAY: '#F5F5F5',
  DISABLED: '#999999',
  TEXTCOLOR: '#222222',
  LINKCOLOR: '#377DC4',
  SUCCESS: '#7ED321',
  ERROR: '#EB3E3E',
  HIGHLIGHT_BLUE: '#0185FF'
}

const BREAKPOINTS = {
  XS: 600,
  SM: 768,
  MD: 960,
  LG: 1024,
  XL: 1200
}

const BEBAS = localFont({ src: '../../public/static/fonts/bebasneue_bold.woff', style: 'normal', weight: '400' })
const COOPER_HEWITT = localFont({
  src: '../../public/static/fonts/CooperHewitt-Bold.woff',
  style: 'normal',
  weight: '400'
})
const IBM = localFont({
  src: '../../public/static/fonts/IBMPlexSansCondensed-Regular.ttf',
  style: 'normal',
  weight: '400'
})
const IBM_BOLD = localFont({
  src: '../../public/static/fonts/IBMPlexSansCondensed-Bold.ttf',
  style: 'normal',
  weight: '700'
})

const FONTS = {
  BEBAS: BEBAS.style.fontFamily,
  COOPER_HEWITT: COOPER_HEWITT.style.fontFamily,
  IBM: IBM.style.fontFamily,
  IBM_BOLD: IBM_BOLD.style.fontFamily,
  HELVETICA: 'Helvetica Neue, Helvetica, -apple-system, Segoe UI, Arial, sans-serif',
  ORBITRON: 'Orbitron, Verdana, -apple-system, Segoe UI, Arial, sans-serif'
}

const BOX_SHADOW = {
  DEFAULT: '0 0 3px 0 rgba(51, 51, 51, 0.18)'
}

export const THEME = {
  COLORS,
  BREAKPOINTS,
  FONTS,
  BOX_SHADOW
}

export const lightenColor = (hex, percent) => tinycolor(hex).lighten(percent)
export const darkenColor = (hex, percent) => tinycolor(hex).darken(percent)
