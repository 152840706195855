import { isServer } from './'

export function withTaboola(callback) {
  if (isServer) return

  try {
    window._taboola = window._taboola || []
    return callback?.(window._taboola)
  } catch (e) {
    console.error('withTaboola():', e.message ?? e)
  }
}
