import { ConfigStore } from 'stores/config'
import { ContentLayoutsStore } from 'stores/content-layouts'
import { StoriesStore } from 'stores/stories'
import { CONTENT_LAYOUTS } from 'constants/contentLayouts'
import { httpCache } from 'utils/http'

export const getGlobalProps = async () => {
  const [config, liveItems, contentLayouts] = await Promise.all([
    ConfigStore().findById('barstool-web'),
    StoriesStore().findLive(),
    ContentLayoutsStore().find({ key: CONTENT_LAYOUTS.BARSTOOL_HOMEPAGE })
  ])

  return {
    config,
    liveItems,
    contentLayouts
  }
}

export const getStaticWrapper = (handler) => {
  return async (context) => {
    try {
      // Call handler and globalProps in parallel
      const [result, globalProps] = await Promise.all([handler?.(context) ?? {}, getGlobalProps()])

      // Parse props object, can be sent in 2 ways
      const props = result.props || result

      // Parse revalidate
      const revalidate = result.revalidate || 60

      // Parse not found
      const notFound = result.notFound || undefined
      if (notFound === true) {
        return {
          notFound: true
        }
      }

      // Parse redirect
      const redirect = result.redirect || undefined
      if (redirect && typeof redirect === 'string') {
        return {
          redirect: {
            destination: redirect,
            permanent: false
          }
        }
      }

      // Assign global props under global key
      Object.assign(props, { global: globalProps })

      return { props, revalidate }
    } catch (err) {
      if (err.status === 404) {
        return {
          notFound: true
        }
      }
      throw err
    }
  }
}

export const getServerSideWrapper = (handler, options) => {
  return async (context) => {
    try {
      // Call handler and globalProps in parallel
      const [result, globalProps] = await Promise.all([handler?.(context) ?? {}, getGlobalProps()])

      // Parse props object, can be sent in 2 ways
      const props = result.props || result

      // Assign global props under global key
      Object.assign(props, { global: globalProps })

      // Apply caching headers
      httpCache(context, options)

      return { props }
    } catch (error) {
      // Re-fetch global props
      const globalProps = await getGlobalProps()

      // Parse status code
      const statusCode = error.status || error.statusCode || 500

      // Apply cache headers on 404s
      if (statusCode === 404) {
        httpCache(context)
      }

      // Set response status code
      context.res.statusCode = statusCode

      return {
        props: {
          error: {
            status: statusCode,
            message: error.message
          },
          global: globalProps
        }
      }
    }
  }
}
