import { mediaApi } from 'api'
import useSWR from 'swr'

const APP_INFO_KEY = '/media/app-info'

function useAppInfo() {
  const { data, error } = useSWR([APP_INFO_KEY], () => mediaApi.getAppInfo())

  return {
    data,
    error,
    isLoading: !(data || error)
  }
}

export default useAppInfo
