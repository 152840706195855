import { observable, flow } from 'mobx'

const DEFAULT_REDIRECT_PATH = '/'
const NAVIGATION_ITEMS = [
  { title: 'Blogs', href: '/blogs', primary: true, hasDropdown: true },
  { title: 'Vids', href: '/videos', primary: true, hasDropdown: true },
  { title: 'Pods', href: '/podcasts', primary: true, hasDropdown: true },
  { title: 'Pics', href: '/galleries', primary: true, hasDropdown: true },
  { title: 'Bloggers', href: '/bloggers', hasDropdown: true },
  { title: 'Shows', href: '/shows', hasDropdown: true },
  { title: 'Topics', href: '#topics', hasDropdown: true, hasMobileDropdown: true },
  { title: 'Store', href: 'https://store.barstoolsports.com', hasDropdown: true, external: true },
  { title: 'More', href: '#more', hasDropdown: true, hasMobileDropdown: true }
]

export const NavigationStore = () => {
  const updatePostAuthRedirect = flow(function* (path = DEFAULT_REDIRECT_PATH) {
    this.postAuthRedirect = path
    yield
  })
  const updatePostAuthCallback = flow(function* (callback) {
    this.postAuthCallback = callback
    yield
  })
  const open = flow(function* (item) {
    this.currentDropdown = item
    if (document.querySelector('.layout__overlay'))
      document.querySelector('.layout__overlay').classList.add('layout__overlay--active')
    yield
  })
  const close = flow(function* () {
    this.currentDropdown = null
    if (document.querySelector('.layout__overlay'))
      document.querySelector('.layout__overlay').classList.remove('layout__overlay--active')
    yield
  })
  const setStickyVideoInteraction = flow(function* (value = true) {
    this.stickyVideoInteraction = value
    yield
  })

  return observable({
    postAuthRedirect: DEFAULT_REDIRECT_PATH,
    postAuthCallback: null,
    updatePostAuthRedirect,
    updatePostAuthCallback,
    navigationItems: NAVIGATION_ITEMS,
    open,
    close,
    currentDropdown: null,
    setStickyVideoInteraction,
    stickyVideoInteraction: false
  })
}

NavigationStore.cacheKey = 'NavigationStore'

export default NavigationStore
