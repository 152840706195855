import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import { THEME } from 'utils'
import { siteHeaderEvents } from 'lib/ga'
import capitalize from 'lodash/capitalize'
import { track } from 'lib/whoami'

const SubnavAvatars = ({ items, feed }) => {
  const handleAvatarCDPEvent = (item) => {
    if (!item) return
    track('ContentClicked', {
      content: {
        src: `https://barstoolsports.com${item.author_url}`,
        type: 'Link',
        componentName: 'SubnavAvatars'
      }
    })
  }
  const handleSubnavAvatarsLinkCDPEvent = (feed) => {
    if (!feed) return
    siteHeaderEvents.navLink(`${feed.toUpperCase()} - All`)
    track('ContentClicked', {
      content: {
        src: `https://barstoolsports.com/${feed}`,
        type: 'Link',
        componentName: `SubnavAvatars See All ${capitalize(feed)} Link`
      }
    })
  }
  return (
    <>
      <div className='dropdownMenu'>
        <div className='container'>
          <div className='dropdown__avatarContainer'>
            {items &&
              items.map((item) => {
                const base = item.author_url ? 'bio' : 'shows'
                const link = { href: `/${base}/[id]/[slug]`, as: `/${base}/${item.id}/${item.slug}` }
                const analyticsName = `${feed.toUpperCase()} - ${item.name}`
                return (
                  <Link key={item.id} prefetch={false} {...link} legacyBehavior>
                    <div
                      className='dropdown__cardWrapper'
                      onClick={() => {
                        siteHeaderEvents.navLink(analyticsName)
                        handleAvatarCDPEvent(item)
                      }}
                    >
                      <div className='dropdown__avatarCard'>
                        <div
                          className='dropdown__avatarImage'
                          style={{ backgroundImage: `url(${item.avatar || item.logo})` }}
                        />
                        <span itemProp='name' className='dropdown__avatarTitle'>
                          {item.name}
                        </span>
                      </div>
                    </div>
                  </Link>
                )
              })}
          </div>
          <div className='dropdown__more'>
            <Link href={`/${feed}`} prefetch={false} legacyBehavior>
              <a
                className='btn btn--secondary'
                onClick={() => {
                  handleSubnavAvatarsLinkCDPEvent(feed)
                }}
              >
                See all {feed}
              </a>
            </Link>
          </div>
        </div>
      </div>
      <style jsx>{`
        .dropdownMenu {
          width: 100%;
          position: absolute;
          z-index: 15;
          top: 100%;
          left: 0;
          background: #f1f1f1;
          padding: 30px;
          border-top: 1px solid ${THEME.COLORS.LIGHTERGRAY};
          border-bottom: 1px solid ${THEME.COLORS.LIGHTERGRAY};
          text-align: center;
        }
        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: auto;
        }
        .dropdown__avatarContainer {
          justify-content: space-between;
          margin: auto;
        }
        .dropdown__avatarCard {
          display: flex;
          align-items: center;
          justify-content: left;
          cursor: pointer;
          padding: 0.5rem 1rem 0.5rem 0.5rem;
          margin-bottom: 0.5rem;
          height: auto;
          text-align: left;
        }
        .dropdown__avatarImage {
          width: 2.5rem;
          height: 2.5rem;
          background-color: white;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
          flex-shrink: 0;
        }
        .dropdown__avatarTitle {
          margin-left: 1rem;
          color: ${THEME.COLORS.TEXTCOLOR};
          text-transform: capitalize;
          font-size: 0.9rem;
        }
        .dropdown__cardWrapper {
          display: inline-block;
          width: 50%;
        }
        .dropdown__more {
          margin: 2rem 0 0 0;
        }
        .dropdown__seeAllButton {
          width: 100%;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .dropdown__cardWrapper {
            width: 20%;
          }
          .dropdown__avatarContainer {
            text-align: left;
            width: 80%;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .dropdown__seeAllButton {
            width: 30%;
          }
        }
      `}</style>
    </>
  )
}

export default observer(SubnavAvatars)
