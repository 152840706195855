const BLOGS_FEED_NAV_OPTIONS = [
  {
    label: 'All Blogs',
    href: '/blogs',
    current: false
  },
  {
    label: 'My Favorites',
    href: '/blogs/favorites',
    current: true
  }
]

const VIDEOS_FEED_NAV_OPTIONS = [
  {
    label: 'All Videos',
    href: '/videos',
    current: true
  },
  {
    label: 'My Favorites',
    href: '/videos/favorites',
    current: false
  },
  {
    label: 'Playlists',
    href: '/videos/playlists',
    current: false
  }
]

const PODCASTS_FEED_NAV_OPTIONS = [
  {
    label: 'All Episodes',
    href: '/podcasts',
    current: true
  },
  {
    label: 'My Favorites',
    href: '/podcasts/favorites',
    current: false
  }
]

export const getFeedNavOptions = (feedNavOptions) => (activeRoute) => {
  return feedNavOptions.map((option) => ({
    ...option,
    current: option.href === activeRoute
  }))
}

export const getBrandFeedOptions = (brand, activeRoute) => {
  const { id, slug, content_types } = brand
  const baseHref = '/shows/[id]/[slug]'
  const baseRoute = `/shows/${id}/${slug}`
  const opts = [
    {
      label: 'Main',
      href: baseHref,
      as: baseRoute,
      current: activeRoute === baseRoute
    }
  ]
  if (content_types?.barstool_original) {
    opts.push({
      label: 'Videos',
      href: `${baseHref}/videos`,
      as: `${baseRoute}/videos`,
      current: activeRoute === `${baseRoute}/videos`
    })
  }
  if (content_types?.podcast) {
    opts.push({
      label: slug === 'barstool-radio' ? 'Audio' : 'Podcasts',
      href: `${baseHref}/podcasts`,
      as: `${baseRoute}/podcasts`,
      current: activeRoute === `${baseRoute}/podcasts`
    })
  }
  if (slug === 'the-dozen-trivia-competition') {
    opts.push({
      label: 'Rankings',
      href: `${baseRoute}/rankings`,
      as: `${baseRoute}/rankings`,
      current: activeRoute === `${baseRoute}/rankings`
    })
  }
  if (slug === 'the-dozen-trivia-competition') {
    opts.push({
      label: 'Schedule',
      href: `${baseRoute}/schedule`,
      as: `${baseRoute}/schedule`,
      current: activeRoute === `${baseRoute}/schedule`
    })
  }
  if (slug === 'the-dozen-trivia-competition') {
    opts.push({
      label: 'Merch',
      href: `${baseRoute}/merch`,
      as: `${baseRoute}/merch`,
      current: activeRoute === `${baseRoute}/merch`
    })
  }
  opts.push({
    label: 'About',
    href: `${baseHref}/about`,
    as: `${baseRoute}/about`,
    current: activeRoute === `${baseRoute}/about`
  })
  if (slug === 'call-her-daddy') {
    opts.push(
      {
        label: 'Ask a Question',
        href: `${baseHref}/ask`,
        as: `${baseRoute}/ask`,
        current: activeRoute === `${baseRoute}/ask`
      },
      {
        label: 'Be a Guest',
        href: `${baseHref}/submit-featured-story`,
        as: `${baseRoute}/submit-featured-story`,
        current: activeRoute === `${baseRoute}/submit-featured-story`
      }
    )
  }
  if (slug === 'chicks-at-barstool') {
    opts.push({
      label: 'Submit',
      href: `${baseHref}/group-therapy`,
      as: `${baseRoute}/group-therapy`,
      current: activeRoute === `${baseRoute}/group-therapy`
    })
  }
  if (slug === 'pardon-my-take') {
    opts.push({
      label: 'Pardon My Cheesesteak – Order Now',
      href: 'https://order.pardonmycheesesteak.com/',
      as: 'https://order.pardonmycheesesteak.com/',
      current: false
    })
  }
  return opts
}

export const getBlogsFeedNavOptions = getFeedNavOptions(BLOGS_FEED_NAV_OPTIONS)
export const getVideosFeedNavOptions = getFeedNavOptions(VIDEOS_FEED_NAV_OPTIONS)
export const getPodcastsFeedNavOptions = getFeedNavOptions(PODCASTS_FEED_NAV_OPTIONS)
