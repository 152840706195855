import { formatSocialHandle } from './strings'

export const formatAthlete = (data) => {
  return {
    email: data.email,
    birthday: data.birthday,
    first_name: data.first_name,
    last_name: data.last_name,
    phone_number: data.phone,
    gender: data.gender,
    address: {
      address1: data.street,
      // address2: '',
      city: data.city,
      state: data.state,
      zip: data.zip
    },
    social: {
      twitter_handle: formatSocialHandle(data.twitter_handle),
      twitter_followers: Number(data.twitter_follower_count),
      instagram_handle: formatSocialHandle(data.instagram_handle),
      instagram_followers: Number(data.instagram_follower_count),
      tiktok_handle: formatSocialHandle(data.tiktok_handle),
      tiktok_followers: Number(data.tiktok_follower_count)
    },
    measurements: {
      shirt_size: data.merch_size_top,
      pants_size: data.merch_size_bottom
    },
    athlete: {
      school_email: data.school_email,
      graduation_date: data.graduation_date,
      school_name: data.school,
      school_division: data.division,
      school_sports: data.sports,
      school_uniform_image: data.image_school_uniform,
      instagram_bio_image: data.image_instagram_bio,
      twitter_bio_image: data.image_twitter_bio,
      tiktok_bio_image: data.image_tiktok_bio
    }
  }
}

export const GENDER_OPTIONS = [
  { id: 'Female', value: 'Female', label: 'Female' },
  { id: 'Male', value: 'Male', label: 'Male' },
  { id: 'Other', value: 'Other', label: 'Other' }
]
