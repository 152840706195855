import {
  isFuture,
  isPast,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  format,
  differenceInDays,
  differenceInSeconds,
  isDate,
  parseISO,
  addHours,
  getHours,
  getMinutes,
  getSeconds
} from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { DateTime } from 'luxon'
import { zeroPad } from 'react-countdown'

import isString from 'lodash/isString'
import isFinite from 'lodash/isFinite'

export const isLive = (date) => {
  return isPast(new Date(date))
}

export const startsIn = (date) => {
  const upcoming = isFuture(new Date(date))
  return upcoming ? formatDistanceToNow(new Date(date)) : 'Live'
}

export const formatDate = (date, formatString = 'M/d h:mm a') => {
  try {
    let parsedDate
    if (isDate(date)) parsedDate = date
    else parsedDate = parseISO(date)
    return format(parsedDate, formatString)
  } catch (err) {
    return 'Invalid Date'
  }
}

export const formatDateToTimeZone = (date, formatString = 'PPPPpppp zzz', timeZone = 'America/New_York') => {
  try {
    let parsedDate
    if (isDate(date)) parsedDate = date
    else parsedDate = parseISO(date)

    return formatInTimeZone(parsedDate, timeZone, formatString)
  } catch (err) {
    return 'Invalid Date'
  }
}

export const countdown = (hours, minutes, seconds) => {
  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`
}

export const countdownAsObject = ({ days, hours, minutes, seconds }, date) => {
  if (days > 0) {
    return {
      label: 'Starts',
      value: formatDate(date)
    }
  }
  return {
    label: 'Starts in',
    value: countdown(hours, minutes, seconds)
  }
}

export const formatCountdown = ({ days, hours, minutes, seconds }, date) => {
  if (days > 0) {
    return `Starts ${formatDate(date)}`
  }
  const base = countdown(hours, minutes, seconds)
  return `Starts in ${base}`
}

export const wordsOrDate = (date) => {
  if (differenceInDays(new Date(), new Date(date)) > 5) {
    return formatDate(date, 'M/d/yy h:mm a')
  }
  return `${formatDistanceToNowStrict(new Date(date))} ago`
}

export const convertToDuration = (milliseconds) => {
  if (milliseconds === 0) return '00:00'
  if (!milliseconds || milliseconds < 1000) return null
  const normalizeTime = (time) => (time.length === 1 ? `0${time}` : time)

  const date = new Date(milliseconds)
  const timezoneDiff = date.getTimezoneOffset() / 60
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff)

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)))
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)))
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)))

  const hoursOutput = hours !== '00' ? `${hours}:` : ''

  return `${hoursOutput}${minutes}:${seconds}`
}

export const getVideoSecondsSinceStart = (startTime) => {
  if (!startTime) return 0
  return differenceInSeconds(new Date(), new Date(startTime))
}

export function isDateString(str) {
  return isString(str) && isFinite(new Date(str).getTime())
}

export function getAge(date) {
  if (!date || !isDateString(date)) return 0
  const dateString = new Date(date).toISOString()
  const yearDiff = DateTime.fromISO(dateString).diffNow('years').years * -1
  return Number(Math.floor(yearDiff))
}

export function getDateTime(dt) {
  try {
    switch (true) {
      case dt instanceof DateTime:
        return dt
      case dt instanceof Date:
        return DateTime.fromJSDate(dt)
      case typeof dt === 'string':
        return DateTime.fromISO(dt)
      default:
        return null
    }
  } catch (e) {
    console.log('ERROR getDateTime')
    console.error(e)
    return null
  }
}

export function timeIntervalBetween({
  targetDateTime,
  currentDateTime,
  format = ['days', 'hours', 'minutes', 'seconds']
}) {
  return targetDateTime.diff(currentDateTime, format).toObject()
}

export function timeIntervalFromNow(targetDate) {
  let targetDateTime = getDateTime(targetDate)

  return timeIntervalBetween({ targetDateTime, currentDateTime: DateTime.now() })
}

export function formatInterval(countdownInterval) {
  const { days, hours, minutes, seconds } = countdownInterval

  const daysString = `${days < 10 ? '0' : ''}${days}`
  const hoursString = `${hours < 10 ? '0' : ''}${hours}`
  const minutesString = `${minutes < 10 ? '0' : ''}${minutes}`
  const secondsFixed = Number(seconds.toFixed(0))
  const secondsString = `${secondsFixed < 10 ? '0' : ''}${secondsFixed}`

  return {
    days: daysString,
    hours: hoursString,
    minutes: minutesString,
    seconds: secondsString,
    full: `${daysString}:${hoursString}:${minutesString}:${secondsString}`
  }
}
