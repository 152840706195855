import React from 'react'

export function Spacer({ size = '10px', display = 'block' }) {
  return (
    <>
      <div className='spacer'></div>
      <style jsx>{`
        .spacer {
          width: ${size};
          height: ${size};
          flex-shrink: 0;
          display: ${display};
        }
      `}</style>
    </>
  )
}

export default Spacer
