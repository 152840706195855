import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { isMobile } from 'utils'
import { useStore } from 'hooks'
import { NavigationStore } from 'stores/navigation'
import { siteHeaderEvents } from 'lib/ga'
import { track } from 'lib/whoami'

const NavLink = ({ item, isActive, onClick }) => {
  const router = useRouter()
  const navigationStore = useStore(NavigationStore)
  const lowerCaseTitle = item.title.toLowerCase()
  const currentPath = router.pathname

  let active = currentPath.includes(item.href)
  if (typeof isActive === 'function') {
    active = isActive(item)
  } else if (typeof isActive === 'boolean') {
    active = isActive
  }

  const classNames = [
    'siteNavigation__link',
    `siteNavigation__link--${lowerCaseTitle}`,
    item.primary ? 'siteNavigation__link--primary' : '',
    active ? 'siteNavigation__activeLink' : ''
  ]

  const handleNavCDPEvent = (item) => {
    if (!item) return
    track('ContentClicked', {
      content: {
        src: `https://barstoolsports.com${item?.href}`,
        type: 'Link',
        componentName: 'NavLink'
      }
    })
  }

  // Override the default behavior for the dropdown nav links on mobile
  if (item.hasMobileDropdown && isMobile()) {
    // 1) No mouseEnter handler
    // 2) Toggle open status via onClick handler
    return (
      <a
        className={classNames.join(' ').trim()}
        onMouseEnter={undefined}
        onClick={() => {
          siteHeaderEvents.navLink(item.title, false)
          navigationStore.currentDropdown === lowerCaseTitle
            ? navigationStore.close()
            : navigationStore.open(lowerCaseTitle)
          onClick?.()
          handleNavCDPEvent(item)
        }}
      >
        {item.title === 'More' ? '+' : item.title}
      </a>
    )
  }

  if (item.external) {
    return (
      <a
        itemProp='url'
        href={item.href}
        target='_blank'
        rel='noopener noreferrer'
        className={classNames.join(' ').trim()}
        onClick={() => {
          siteHeaderEvents.navLink(item.title, true)
          handleNavCDPEvent(item)
        }}
      >
        {item.title}
      </a>
    )
  }

  return (
    <Link href={item.href} prefetch={false} legacyBehavior>
      <a
        itemProp='url'
        className={classNames.join(' ').trim()}
        onClick={() => {
          siteHeaderEvents.navLink(item.title)
          handleNavCDPEvent(item)
        }}
      >
        {item.title === 'More' ? '+' : item.title}
      </a>
    </Link>
  )
}

export default NavLink
