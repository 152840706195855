import { UnionResource } from 'api/resource'

class FormsApi extends UnionResource {
  constructor() {
    super('forms')
  }

  async postSubmission(id, submission) {
    const submissionResult = await this.post({ url: `/${this.resource}/${id}/submissions`, data: submission })
    return submissionResult
  }
}

export default FormsApi
