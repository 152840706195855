import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { setGlobalLogging } from 'lib/logger'
import { BugsnagProvider } from 'lib/bugsnag'
import { loadPixels } from 'lib/pixels'
import { sendEvent } from 'lib/ga'
import { usePageProgress, useInterval, useStore, useWhoamiIdentification } from 'hooks'
import { useTaboola } from 'hooks/taboola'
import { isServer } from 'utils'
import { ConfigStore } from 'stores/config'
import { ContentLayoutsStore } from 'stores/content-layouts'
import { NavigationStore } from 'stores/navigation'
import { StoriesStore } from 'stores/stories'

import { ErrorProvider } from './_error-provider'
import StickyVideoPlayer from 'components/StickyVideoPlayer'

import { LinkedInScript } from 'lib/pixels/linkedin'
import { FacebookPixelScript } from 'lib/pixels/facebook'
import { TwitterPixelScript } from 'lib/pixels/twitter'

import 'video.js/dist/video-js.css'
import 'videojs-ima/dist/videojs.ima.css'
import '/src/globals.css'
import '@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css'

// Fix for older versions of Safari
import ResizeObserver from 'resize-observer-polyfill'

if (isServer === false) {
  window.ResizeObserver = window.ResizeObserver || ResizeObserver
}

export function reportWebVitals(metric) {
  const { id, name, label, value } = metric
  sendEvent(label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric', name, {
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    label: id,
    nonInteraction: true // avoids affecting bounce rate.
  })
}

const BarstoolApp = ({ Component, pageProps }) => {
  useStore(ConfigStore, { current: pageProps.global.config })
  useStore(ContentLayoutsStore, { items: pageProps.global.contentLayouts })
  useTaboola()
  const storiesStore = useStore(StoriesStore, { liveItems: pageProps.global.liveItems })
  const navigationStore = useStore(NavigationStore)
  const router = useRouter()

  setGlobalLogging(router.query)

  useWhoamiIdentification()

  useInterval(() => storiesStore.findLive(), 30 * 1000)

  usePageProgress({
    routeWillChange: () => {
      navigationStore.close()
    }
  })

  useEffect(() => {
    loadPixels()
  }, [])

  return (
    <BugsnagProvider>
      <ErrorProvider error={pageProps.error}>
        <Component {...pageProps} />
        <StickyVideoPlayer pathname={router.pathname} />
        <LinkedInScript />
        <FacebookPixelScript />
        <TwitterPixelScript />
      </ErrorProvider>
    </BugsnagProvider>
  )
}

export default BarstoolApp
