import { useState, useEffect } from 'react'

/**
 * Paginates based on numerical page number
 *
 * @param {Array} initialItems
 * @param {Function} handler
 * @param {Object} options
 */
export const usePagination = (items, handler, { limit = 25 } = {}, deps = []) => {
  const [page, setPage] = useState(items.length ? 1 : 0)

  const [hasMore, setHasMore] = useState(true)

  const next = () => {
    const itemLength = items.length
    const op = handler({ limit, page: page + 1 }, items)
    op.then((newItems) => {
      setPage((prevPage) => prevPage + 1)
      setHasMore(newItems.length >= itemLength + limit)
    })
    return op
  }

  useEffect(() => {
    return () => {
      setPage(1)
      setHasMore(true)
    }
  }, deps)

  return {
    page,
    items,
    hasMore,
    next
  }
}

/**
 * Paginates based on date rather than page number
 *
 * @param {Array} initialItems
 * @param {Function} handler
 * @param {Object} options
 */
export const useDatePagination = (
  initialItems,
  handler,
  { dateKey = 'date', queryKey = 'before', ...options } = {},
  deps = []
) => {
  return usePagination(
    initialItems,
    (query, items) =>
      handler(
        {
          ...query,
          page: undefined,
          [queryKey]: items[items.length - 1]?.[dateKey]
        },
        items
      ),
    options,
    deps
  )
}

/**
 * Paginates store products based on page number (cursor)
 *
 * @param {Array} items
 * @param {String} cursor
 * @param {Function} handler
 */
export const useInfiniteCollectionPagination = (items, initialCursor, handler, initialHasMore = true) => {
  const [cursor, setCursor] = useState(initialCursor)
  const [hasMore, setHasMore] = useState(initialHasMore)

  const next = () => {
    const op = handler(cursor)
    op.then(({ nextCursor, hasNextPage }) => {
      setCursor(nextCursor)
      setHasMore(hasNextPage)
    })
    return op
  }

  return {
    cursor,
    items,
    hasMore,
    next
  }
}
