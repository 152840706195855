import { UnionResource } from 'api/resource'

class BrandsApi extends UnionResource {
  constructor() {
    super('brands')
  }
  async findBySlug(slug) {
    const [result] = await this.find({ slug })
    return result
  }
  request(options) {
    options.headers = Object.assign({}, options.headers, { 'X-Union-Version': '2020-07-28' })
    return super.request(options)
  }
}

export default BrandsApi
