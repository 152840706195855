import { observable } from 'mobx'
import { configApi } from 'api'
import { CommonStore } from 'stores/common'

export const ConfigStore = () => {
  return observable({
    ...CommonStore,
    api: configApi
  })
}

ConfigStore.cacheKey = 'ConfigStore'

export default ConfigStore
