import qs from 'querystring'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import { withLogger } from 'lib/logger'

const logger = withLogger('GA')

let _initialized = false

export const initialize = () => {
  if (_initialized) return
  _initialized = true

  ReactGA.initialize(process.env.GA_TRACKING_ID)
  ReactGA.set({ transport: process.env.GA_TRANSPORT })

  ReactGA4.initialize(process.env.GA4_TRACKING_ID)
  ReactGA4.set({ transport: process.env.GA_TRANSPORT })
}

export const set = (...args) => {
  return ReactGA.set(...args)
}

export const sendPageView = (page, title) => {
  const query = qs.parse(window.location.search.slice(1))
  const pageWithoutParams = page.split('?')[0]
  const options = {
    page: pageWithoutParams,
    location: `${window.location.origin}${window.location.pathname}${window.location.search}`
  }

  if (query.utm_source) options.campaignSource = query.utm_source
  if (query.utm_medium) options.campaignMedium = query.utm_medium
  if (query.utm_campaign) options.campaignName = query.utm_campaign
  if (query.utm_content) options.campaignContent = query.utm_content
  if (query.utm_term) options.campaignKeyword = query.utm_term

  ReactGA.set(options)
  ReactGA4.set(options)

  logger.log('pageView():', title)
  ReactGA.pageview(page, [], title)
  ReactGA4.send({ hitType: 'pageview', page: pageWithoutParams, title })
}

/** @function sendEvent
 * @param {string} category - Required. A top level category for these events.
 * @param {string} action - Required - A description of the behaviour.
 * @param {Object} options - Optional - additional options to be sent.
 * @param {string} options.label - Optional. More precise labelling of the related action.
 * @param {int} options.value - Optional. A means of recording a numerical value against an event.
 * @param {boolean} options.nonInteraction - Optional. If an event is not triggered by a user interaction, but instead by our code.
 * @param {string} options.transport - Optional. This specifies the transport mechanism with which hits will be sent.
 */
export const sendEvent = (
  category,
  action,
  { label, value, nonInteraction = false, transport = process.env.GA_TRANSPORT } = {}
) => {
  logger.log('event():', category, action)
  ReactGA.event({ category, action, label, value, nonInteraction, transport })
  ReactGA4.event({ category, action, label, value, nonInteraction, transport })
}

export const customDimensions = {
  setPageType: (type) => ReactGA.set({ dimension3: type }),
  setObVariant: (variantId) => ReactGA.set({ dimension12: variantId }),
  setAccessLevel: (accessLevel) => ReactGA.set({ dimension5: accessLevel }),
  setUserAccessLevel: (accessLevel) => ReactGA.set({ dimension10: accessLevel }),
  setAdBlock: (isEnabled) => ReactGA.set({ dimension9: isEnabled }),
  setABTest: (testIdentifier) => ReactGA.set({ dimension16: testIdentifier }),
  setStoryDimensions: (o) => {
    ReactGA.set({
      dimension1: o.author,
      dimension3: o.pageType,
      dimension4: o.date,
      dimension5: o.accessLevel,
      dimension6: o.category,
      dimension7: o.tags,
      dimension8: o.postType,
      dimension11: o.brandName,
      dimension13: o.nsfw,
      dimension17: o.id,
      contentGroup1: o.author
    })
    ReactGA4.set({
      dimension1: o.author,
      dimension3: o.pageType,
      dimension4: o.date,
      dimension5: o.accessLevel,
      dimension6: o.category,
      dimension7: o.tags,
      dimension8: o.postType,
      dimension11: o.brandName,
      dimension13: o.nsfw,
      dimension17: o.id,
      contentGroup1: o.author
    })
  },
  clearDimension: (dimension) => {
    ReactGA.set({
      [dimension]: null
    })
    ReactGA4.set({
      [dimension]: null
    })
  },
  clearDimensions: () => {
    ReactGA.set({
      dimension1: null,
      dimension2: null,
      dimension3: null,
      dimension4: null,
      dimension5: null,
      dimension6: null,
      dimension7: null,
      dimension8: null,
      dimension11: null,
      dimension13: null,
      dimension17: null,
      contentGroup1: null
    })
    ReactGA4.set({
      dimension1: null,
      dimension2: null,
      dimension3: null,
      dimension4: null,
      dimension5: null,
      dimension6: null,
      dimension7: null,
      dimension8: null,
      dimension11: null,
      dimension13: null,
      dimension17: null,
      contentGroup1: null
    })
  }
}

export const siteHeaderEvents = {
  logo: () => sendEvent('Header', 'Logo'),
  logoGreenie: () => sendEvent('Header', 'Logo Greenie'),
  appDownloadApple: () => sendEvent('Header', 'App Download Apple', { transport: 'beacon' }),
  appDownloadAndroid: () => sendEvent('Header', 'App Download Android', { transport: 'beacon' }),
  marketing: () => sendEvent('Header', 'Marketing'),
  terms: () => sendEvent('Header', 'Terms'),
  privacy: () => sendEvent('Header', 'Privacy'),
  contentPolicy: () => sendEvent('Header', 'Content Policy'),
  careers: () => sendEvent('Header', 'Careers'),
  navLink: (item) => sendEvent('Header', 'Nav Link', { label: item }),
  myFeed: () => sendEvent('Header', 'My Feed'),
  download: () => sendEvent('Header', 'App Download Icon'),
  account: () => sendEvent('Header', 'Account'),
  favorites: () => sendEvent('Header', 'Account - Favorites')
}

export const sidebarEvents = {
  featured: (item, external) =>
    sendEvent('Sidebar', 'Featured', { label: item, transport: external ? 'beacon' : undefined })
}

export const footerEvents = {
  download: () => sendEvent('Footer', 'Download App'),
  facebook: () => sendEvent('Footer', 'Facebook'),
  twitter: () => sendEvent('Footer', 'Twitter'),
  instagram: () => sendEvent('Footer', 'Instagram'),
  youtube: () => sendEvent('Footer', 'YouTube'),
  marketing: () => sendEvent('Footer', 'Marketing'),
  terms: () => sendEvent('Footer', 'Terms'),
  privacy: () => sendEvent('Footer', 'Privacy'),
  contentPolicy: () => sendEvent('Footer', 'Content Policy'),
  digitalSaleTerms: () => sendEvent('Footer', 'Digital Sale Terms'),
  link: (item) => sendEvent('Footer', 'Link', { label: item })
}

export const feedEvents = {
  featured: (url, rank, pinned = false) =>
    sendEvent('Featured', `Rank: ${rank} - ${url}`, { label: 'pinned', value: pinned }),
  trending: (url, rank) => sendEvent('Trending', `Rank: ${rank} - ${url}`)
}

export const storyDetailEvents = {
  shareButton: (location, medium) => sendEvent(location, 'Share', medium),
  clickTag: (tag) => sendEvent('Content', 'Tag Clicked', { label: tag }),
  videoStarted: () => sendEvent('Content', 'video started'),
  videoFinished: () => sendEvent('Content', 'video finished'),
  videoAdStarted: () => sendEvent('Content', 'video ad started'),
  videoAdFinished: () => sendEvent('Content', 'video ad finished'),
  videoAutoplayed: (id, name, count, session = '') => {
    const actionString = `${id} | ${name} | ${session}`
    sendEvent('Video Autoplay', actionString, { label: `${count}`, nonInteraction: true })
  }
}

export const videosPageEvents = {
  heroClicked: (videoUrl) =>
    sendEvent('Video Page Interactions', 'Hero | Featured', { label: videoUrl, nonInteraction: true }),
  showsSection: (label) =>
    sendEvent('Video Page Interactions', 'Static Section | Shows', { label, nonInteraction: true }),
  latestVideos: (label) =>
    sendEvent('Video Page Interactions', 'Static Section | Latest Videos', { label, nonInteraction: true }),
  playlist: (playlistName, label) =>
    sendEvent('Video Page Interactions', `Below Fold Playlists | ${playlistName}`, { label, nonInteraction: true })
}

export const showHeaderEvents = {
  twitter: (show) => sendEvent('Show Page Header', `${show} - Twitter`),
  instagram: (show) => sendEvent('Show Page Header', `${show} - Instagram`),
  podcast: (type) => sendEvent('Show Page Header', 'Subscribe', { label: type })
}

export const commentEvents = {
  scrollToComments: () => sendEvent('Comments', 'Comments Viewed')
}

export const playlistVideoSidebarEvents = {
  itemClicked: (url) => sendEvent('playlistvideoSidebar', 'item click', { label: url }),
  loadMore: () => sendEvent('playlistvideoSidebar', 'load more')
}

export const brandVideoSidebarEvents = {
  itemClicked: (url) => sendEvent('brandvideoSidebar', 'item click', { label: url }),
  loadMore: () => sendEvent('brandvideoSidebar', 'load more')
}

export const popularVideoSidebarEvents = {
  itemClicked: (url) => sendEvent('popularvideoSidebar', 'item click', { label: url }),
  loadMore: () => sendEvent('popularvideoSidebar', 'load more')
}

export const abTestingEvents = {
  placedInTest: (testName, variant) =>
    sendEvent('AB_Testing', `${testName}|${variant}`, 'Assessed', { nonInteraction: true }),
  viewedContent: (testName, variant) =>
    sendEvent('AB_Testing', `${testName}|${variant}`, 'viewedContent', { nonInteraction: true })
}

export const videoPlayerEvents = {
  // leaving "Brightcove Player" intentionally although we don't use brightcove anymore.
  play: ({ value, label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Media Play', { label, value, nonInteraction: false }),
  pause: ({ value, label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Media Pause', { label, value, nonInteraction: false }),
  load: ({ label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Video Load', { label, nonInteraction: true }),
  start: ({ label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Media Begin', { label, nonInteraction: false }),
  end: ({ label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Media Complete', { label, nonInteraction: true }),
  percentPlayed: ({ value, label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Percent played', { label, value, nonInteraction: true }),
  seekStart: ({ value, label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Seek start', { label, value, nonInteraction: true }),
  seekEnd: ({ value, label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Seek end', { label, value, nonInteraction: true }),
  enterFullscreen: ({ label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Fullscreen Entered', { label, nonInteraction: false }),
  exitFullscreen: ({ label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Fullscreen Exited', { label, nonInteraction: false }),
  volume: ({ value, label, playerName = 'Brightcove Player' }) =>
    sendEvent(playerName, 'Volume Change', { label, value, nonInteraction: false })
}

export default {
  initialize,
  set,
  sendPageView,
  customDimensions,
  siteHeaderEvents,
  sidebarEvents,
  footerEvents,
  feedEvents,
  storyDetailEvents,
  videosPageEvents,
  showHeaderEvents,
  commentEvents,
  playlistVideoSidebarEvents,
  brandVideoSidebarEvents,
  popularVideoSidebarEvents,
  abTestingEvents
}
