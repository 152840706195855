export const provincesAsSelectOptions = Object.freeze([
  { id: 'Alberta', label: 'Alberta', value: 'Alberta' },
  { id: 'British Columbia', label: 'British Columbia', value: 'British Columbia' },
  { id: 'Manitoba', label: 'Manitoba', value: 'Manitoba' },
  { id: 'New Brunswick', label: 'New Brunswick', value: 'New Brunswick' },
  { id: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
  { id: 'Northwest Territories', label: 'Northwest Territories', value: 'Northwest Territories' },
  { id: 'Nova Scotia', label: 'Nova Scotia', value: 'Nova Scotia' },
  { id: 'Nunavut', label: 'Nunavut', value: 'Nunavut' },
  { id: 'Ontario', label: 'Ontario', value: 'Ontario' },
  { id: 'Prince Edward Island', label: 'Prince Edward Island', value: 'Prince Edward Island' },
  { id: 'Quebec', label: 'Quebec', value: 'Quebec' },
  { id: 'Saskatchewan', label: 'Saskatchewan', value: 'Saskatchewan' },
  { id: 'Yukon', label: 'Yukon', value: 'Yukon' }
])
