import ExternalScriptLoader from 'lib/external-script-loader'

class AdWords extends ExternalScriptLoader {
  scriptUrl = 'https://www.googletagmanager.com/gtag/js?id=AW-973879621'
  options = { defer: true }

  init() {
    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      window.dataLayer.push(arguments)
    }
    window.gtag('js', new Date())
    window.gtag('config', 'AW-973879621')
    window.gtag('config', 'AW-772407196')
    // Site visit conversion for Making a Stoolie YouTube campaign test
    window.gtag('event', 'conversion', { send_to: 'AW-973879621/j8W6CN2w4PIBEMXysNAD' })
  }
}

export default new AdWords()
