import React from 'react'
import { THEME } from 'utils'

export default function SearchIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 18 18' className='svgIcon iconSearch' style={{ width, height }}>
      <title>Search</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill={color} fillRule='nonzero'>
          <g>
            <g>
              <path d='M13.4633363,12.0491228 L17.9142136,16.5 L16.5,17.9142136 L12.0491228,13.4633363 C10.7873239,14.4273642 9.21053507,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,9.21053507 14.4273642,10.7873239 13.4633363,12.0491228 Z M7.5,13 C10.5375661,13 13,10.5375661 13,7.5 C13,4.46243388 10.5375661,2 7.5,2 C4.46243388,2 2,4.46243388 2,7.5 C2,10.5375661 4.46243388,13 7.5,13 Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
