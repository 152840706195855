import React, { memo } from 'react'
import { applyFastlyParams } from 'utils'

import GifToVideo from 'components/GifToVideo'
import Image from 'next/image'

function Thumbnail({ src, alt, aspectRatio = '4:3,smart', ...props }) {
  return (
    <>
      {src.includes('.gif') ? (
        <GifToVideo src={applyFastlyParams(src, { crop: aspectRatio }, { overwriteCrop: false })} />
      ) : (
        <Image
          src={applyFastlyParams(src, { crop: aspectRatio, format: 'jpg' }, { overwriteCrop: false })}
          alt={alt}
          height={0}
          width={0}
          sizes='(max-width: 1400px) 20vw, 300px'
          style={{
            width: '100%',
            height: 'auto'
          }}
          {...props}
        />
      )}
    </>
  )
}

export default memo(Thumbnail)
