import React from 'react'
import { THEME } from 'utils'

export default function AvatarIcon({ color = THEME.COLORS.TEXTCOLOR, width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 19 19' className='svgIcon iconAvatar' style={{ width, height }}>
      <title>Sign Up</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g stroke={color} transform='translate(1.000000, 1.000000)' strokeWidth='2'>
          <g>
            <circle cx='8.5' cy='3.5' r='3.5'></circle>
            <path d='M17,17 C17,11.4771525 13.1944204,7 8.5,7 C3.80557963,7 0,11.4771525 0,17 L17,17 Z'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
