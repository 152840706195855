import React from 'react'
import { observer } from 'mobx-react-lite'
import Link from 'next/link'
import { StoriesStore } from 'stores/stories'
import { THEME, storyLink } from 'utils'
import { useStore } from 'hooks'
import { track } from 'lib/whoami'

const NotFound = ({ statusCode, errorMessage }) => {
  const storiesStore = useStore(StoriesStore)
  const story = storiesStore.randomStory
  const randomLink = story ? storyLink(story) : null

  const handleRandomLinkCDPEvent = (link) => {
    if (!link) return
    track('ContentClicked', {
      content: {
        src: `https://barstoolsports.com${link?.as}`,
        type: 'Link',
        componentName: 'NotFound'
      }
    })
  }

  return (
    <>
      <div className='ohNoCard'>
        <header className='ohNoCardContent'>
          <h1>
            {statusCode || 404}
            <span>{errorMessage || `This Page Doesn't Exist.`}</span>
          </h1>
          {randomLink && (
            <>
              <Link {...randomLink} legacyBehavior>
                <a
                  className='btn btnPrimary'
                  onClick={() => {
                    handleRandomLinkCDPEvent(randomLink)
                  }}
                >
                  Go to a random article
                </a>
              </Link>
            </>
          )}
        </header>
        <div className='ohNoCardImage'>
          <img src='/static/images/dave.jpg' alt='Page Not Found' />
        </div>
      </div>
      <style jsx>{`
        .btn {
          letter-spacing: 0.09rem;
          padding: 0.9rem 2.5rem;
        }
        .ohNoCard {
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          margin: 0 auto 0.5rem auto;
          background: #ffffff;
          overflow: hidden;
        }
        .ohNoCardImage {
          display: flex;
          align-self: flex-start;
          width: 100%;
          height: 15rem;
          overflow: hidden;
          text-align: center;
        }
        .ohNoCardImage > img {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          object-fit: cover;
        }
        .ohNoCardContent {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          padding: 1rem 1rem 2rem;
          text-align: center;
          color: ${THEME.COLORS.SECONDARY};
        }
        .ohNoCardContent p {
          font-size: 1.25em;
          margin-bottom: 0.5rem;
        }
        .ohNoCardContent h1 {
          font-family: ${THEME.FONTS.BEBAS};
          text-transform: uppercase;
          color: ${THEME.COLORS.SECONDARY};
          font-size: 3rem;
          line-height: 120%;
          margin-top: 0;
          margin-bottom: 0.25em;
        }
        .ohNoCardContent h1 span {
          font-family: ${THEME.FONTS.HELVETICA};
          display: block;
          font-size: 1.5rem;
          line-height: 1;
          text-transform: capitalize;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .ohNoCard {
            flex-flow: row;
            height: 23.5rem;
            margin: 12vh auto;
          }
          .ohNoCardContent {
            padding-bottom: 3rem;
          }
          .ohNoCardContent h1 {
            font-size: 6.5rem;
          }
          .ohNoCardImage {
            height: 100%;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .ohNoCardContent {
            width: 50%;
            height: 100%;
          }
          .ohNoCardImage {
            width: 50%;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .ohNoCard {
            height: 25rem;
            margin: 17vh auto;
          }
          .ohNoCardContent {
            width: 40%;
          }
          .ohNoCardContent h1 {
            font-size: 8rem;
          }
          .ohNoCardImage {
            width: 60%;
          }
        }
      `}</style>
    </>
  )
}

export default observer(NotFound)
