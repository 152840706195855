import React, { useMemo } from 'react'
import Link from 'next/link'

const safeDomains = new Set([
  'barstoolsports.com',
  'barstoolbets.com',
  'bsnft.io',
  'onebite.app',
  'roughnrowdybrawl.com',
  'twoyay.com'
])

const SmartLink = ({ item, children, style = {}, onClick = () => {} }) => {
  const isSafeDomain = useMemo(() => {
    try {
      const hostname = new URL(item.href).hostname.split('.').slice(-2).join('.')
      return safeDomains.has(hostname)
    } catch {
      return false
    }
  }, [item.href])

  if (!item.href && !item.slug) {
    console.log('BAD  SMARTLINK', { href: item.href, slug: item.slug, title: item.title, image: item.imageUrl })
    return <></>
  }

  if (item.external) {
    const props = isSafeDomain
      ? undefined
      : {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
    return (
      <a href={item.href} style={style} onClick={onClick} {...props}>
        {children}
      </a>
    )
  } else {
    return (
      <Link href={item.href || item.slug} as={item.slug} prefetch={false} legacyBehavior>
        <a style={style} onClick={onClick}>
          {children}
        </a>
      </Link>
    )
  }
}

export default SmartLink
